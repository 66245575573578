import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  ComplexButton,
  ComplexButtonProps,
} from '@hbf/dsl/brand/housingAnywhere';
import { ListAlt, Place } from '@hbf/icons/brand-regular';

import { useIntl } from 'ha/i18n';
import { useTrackEvent } from 'ha/modules/Analytics/helpers/TrackEvent';

import { getCityCanonical } from 'ha/pages/Cities/selectors';
import { updateMapView } from 'ha/pages/Search/actions';
import { getMapViewState } from 'ha/pages/Search/selectors';
import { MapViewState } from 'ha/pages/Search/types';

import listImage from './list-view.png';
import mapImage from './map-view.png';

export const SearchMapButtonToggle: React.FC<Partial<ComplexButtonProps>> = ({
  className,
}) => {
  const { T } = useIntl();

  const currentView = useSelector(getMapViewState);
  const cityCanonical = useSelector(getCityCanonical);

  const dispatch = useDispatch();
  const track = useTrackEvent();

  const handleMapViewChange = () => {
    const next =
      currentView === MapViewState.fullMap
        ? MapViewState.noMap
        : MapViewState.fullMap;

    track('Changed map view on search ', {
      mapType: next,
      listingcity: cityCanonical,
    });
    dispatch(updateMapView(next));
  };

  return (
    <ComplexButton
      src={currentView === MapViewState.noMap ? mapImage : listImage}
      startIcon={currentView === MapViewState.noMap ? <Place /> : <ListAlt />}
      onClick={handleMapViewChange}
      imageProps={{
        alt: 'Toggle Map Button Background',
      }}
      className={className}
    >
      {currentView === MapViewState.noMap
        ? T('search.page.corner_image.switch_to_map')
        : T('search.page.corner_image.switch_to_list')}
    </ComplexButton>
  );
};
