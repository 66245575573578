import React from 'react';

import { Link, Typography } from '@hbf/dsl/core';
import { CanonicalCollections } from 'ha/constants/SearchPageCollections';
import { useIntl } from 'ha/i18n';
import { CITY_STUDENT_ACCOMMODATION_SEO } from 'ha/pages/Search/constants';
import {
  getCountryCode,
  getCityCanonical,
  getAdvertiserName,
  getIsAdvertiserSearch,
  getLocation,
  getNumberOfListings,
  isSearchByPartner as getIsSearchByPartner,
} from 'ha/pages/Search/selectors';
import { useParams } from 'react-router-dom';
import { Language } from '@ha/intl';
import { useSelector } from 'react-redux';
import { getCollection } from '../../../Search/selectors/filterSelectors';

function useSearchKindCanonicalCollection() {
  const { T, lang } = useIntl();

  const city = useSelector(getCityCanonical);
  const canonicalCollection = useSelector(getCollection);
  const countryCode = useSelector(getCountryCode);

  const canonicalCollections = {
    [CanonicalCollections.ApartmentForRent]: T(
      'seo_search_kinds_apartment_for_rent',
    ),
    [CanonicalCollections.PrivateRoomForRent]: T(
      'seo_search_kinds_private_room_for_rent',
    ),
    [CanonicalCollections.SharedRoomForRent]: T(
      'seo_search_kinds_shared_room_for_rent',
    ),
    [CanonicalCollections.StudioForRent]: T('seo_search_kinds_studio_for_rent'),
    [CanonicalCollections.CheapAccommodation]: T(
      'seo_search_kinds_cheap_accommodation',
    ),
    [CanonicalCollections.FurnishedApartments]: T(
      'seo_search_kinds_furnished_apartments',
    ),
    [CanonicalCollections.StudentAccommodation]:
      CITY_STUDENT_ACCOMMODATION_SEO.includes(city) && lang === Language.en
        ? T('seo_search_page_title_students')
        : T('seo_search_kinds_student_accommodation'),
    [CanonicalCollections.LongTermRentals]: T(
      'seo_search_kinds_long_term_rentals',
    ),
    [CanonicalCollections.OneBedroomApartments]: T(
      'seo_search_kinds_one_bedroom_apartments',
    ),
    [CanonicalCollections.TwoBedroomApartments]: T(
      'seo_search_kinds_two_bedroom_apartments',
    ),
    [CanonicalCollections.StudentRoom]: T('seo_search_kinds_student_room'),
  };

  if (canonicalCollection === '') {
    if (countryCode === 'gb') return T('seo_search_kinds.uk');

    return T('seo_search_kinds');
  }

  return canonicalCollections[canonicalCollection];
}

const SearchInfoText = () => {
  const { T } = useIntl();

  const isAdvertiserSearch = useSelector(getIsAdvertiserSearch);
  const advertiserName = useSelector(getAdvertiserName);
  const total = useSelector(getNumberOfListings);
  const placeName = useSelector(getLocation);
  const isSearchByPartner = useSelector(getIsSearchByPartner);

  const kinds = useSearchKindCanonicalCollection();

  const params = { total, kinds, placeName };

  if (isSearchByPartner) {
    return T('%(total)d %(kinds)s for rent near %(placeName)s', params);
  }

  if (isAdvertiserSearch) {
    return T('search_advertiser_title', advertiserName, placeName);
  }

  return T('%(total)d %(kinds)s for rent in %(placeName)s', params);
};

export const SearchResultsInfo = () => {
  const { T, urlResolver } = useIntl();

  const { location } = useParams();
  const isAdvertiserSearch = useSelector(getIsAdvertiserSearch);
  const placeName = useSelector(getLocation);

  return (
    <div>
      <div>
        <Typography component="h1" variant="body/sm-regular">
          <SearchInfoText />
        </Typography>
      </div>

      {isAdvertiserSearch && (
        <div>
          <Link size="sm" href={urlResolver.absolutizePath(`/s/${location}`)}>
            {T('See all listings in %s', placeName)}
          </Link>
        </div>
      )}
    </div>
  );
};
