import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from 'tss-react/mui';
import matchSorter from 'match-sorter';
import { TextField, TextFieldProps } from '@hbf/dsl/legacy';
import { Country } from './types';
import { CountryCodeOption } from './CountryCodeOption';

interface Props {
  onCodeChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    values: Country,
  ) => void;
  countries: Country[];
  countryCode: string;
  selectPlaceholder: string;
}

const useStyles = makeStyles()({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  input: {
    textOverflow: 'clip',
  },
});

const filterOptions = (
  options: Country[],
  { inputValue }: { inputValue: string },
) =>
  matchSorter(options, inputValue, {
    keys: [
      { threshold: matchSorter.rankings.STARTS_WITH, key: 'name' },
      { threshold: matchSorter.rankings.STARTS_WITH, key: 'code' },
      'dialCode',
    ],
  });

const CountrySelect: React.FC<Props & TextFieldProps> = ({
  onCodeChange,
  countries,
  countryCode,
  selectPlaceholder,
  inputProps,
  placeholder,
}) => {
  const { classes } = useStyles();

  const findCountry = (code: string) => {
    return countries.find(ctry => ctry.code === code);
  };

  const getFormattedValue = (code: string) => {
    const country = findCountry(code);

    return country ? `${country.code}, ${country.dialCode}` : '';
  };

  const options = [...countries].sort((a, b) => a.code.localeCompare(b.code));

  return (
    <Autocomplete
      options={options}
      classes={{
        option: classes.option,
        input: classes.input,
      }}
      onChange={onCodeChange}
      filterOptions={filterOptions}
      autoHighlight
      defaultValue={findCountry(countryCode)}
      getOptionLabel={option => getFormattedValue(option.code || countryCode)}
      renderOption={(props, option) => (
        <li {...props}>
          <CountryCodeOption option={option} />
        </li>
      )}
      renderInput={params => (
        <TextField
          {...params}
          label={selectPlaceholder}
          placeholder={placeholder}
          variant="standard"
          fullWidth
          inputProps={{
            ...params.inputProps,
            ...inputProps,
            autoComplete: 'off',
          }}
        />
      )}
    />
  );
};

export { CountrySelect };
