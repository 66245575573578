import React from 'react';

import { makeStyles } from 'tss-react/mui';
import { Helmet } from 'react-helmet-async';

import { Text, units } from '@hbf/dsl/legacy';

import { SearchPageData } from '@ha/contentful';

interface Props {
  faq: SearchPageData['searchFaq'];
}

const useStyles = makeStyles()(() => ({
  header: {
    marginTop: units.rem(2),
  },
}));

export const CityInfo: React.FC<Props> = ({ faq }) => {
  const { classes } = useStyles();

  const structuredFAQData = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: faq.map(({ question, answer }) => ({
      '@type': 'Question',
      name: question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: answer,
      },
    })),
  };

  return (
    <div>
      {faq.map(({ question, answer }) => (
        <React.Fragment key={question}>
          <Text
            className={classes.header}
            variant="h6"
            // for seo purposes
            component="h2"
            gutterBottom
            fw="bold"
          >
            {question}
          </Text>
          <Text variant="body1" paragraph>
            {answer}
          </Text>
        </React.Fragment>
      ))}
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredFAQData)}
        </script>
      </Helmet>
    </div>
  );
};
