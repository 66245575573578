import React from 'react';
import { useSelector } from 'react-redux';
import { Theme } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import { useIntl } from 'ha/i18n';
import { useMediaQuery } from 'ha/helpers/MediaQuery';
import {
  Button,
  Grid,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography,
  units,
} from '@hbf/dsl/legacy';
import {
  SearchAlert,
  SearchAlertChannel,
  SearchAlertFrequency,
} from 'ha/services/bell';
import { DialogNext as Dialog } from 'ha/components/Dialog';
import { getUserPhone } from 'ha/modules/AuthLogic/selectors';
import { PhoneNumberProps, PhoneInput } from 'ha/ui/PhoneInput';
import { countries } from 'ha/constants/Countries';

const MAX_TITLE_LENGTH = 40;

const useStyles = makeStyles()((theme: Theme) => ({
  title: {
    display: 'inline',
    fontSize: units.rem(1.25),
  },
  content: {
    marginBottom: theme.spacing(2),
  },
  notificationCard: {
    background: theme.palette.grey[50],
    border: units.border(1, 'solid', theme.palette.grey[300]),
    color: theme.palette.grey[700],
  },
  titleField: {
    minWidth: '50%',
  },
  titleHelperText: {
    textAlign: 'right',
  },
}));

type ModalType = 'create' | 'update' | 'edit';

interface Props {
  modalType: ModalType;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (
    title: string,
    channel: SearchAlertChannel,
    frequency: SearchAlertFrequency,
    newUserPhone?: PhoneNumberProps,
  ) => void;
  defaultTitle?: string;
  alert?: SearchAlert;
}

export const SearchAlertDialog: React.FC<Props> = ({
  isOpen,
  onClose,
  onSubmit,
  alert,
  defaultTitle,
  modalType,
}) => {
  const { classes } = useStyles();
  const { T } = useIntl();
  const { md } = useMediaQuery();

  const hasAlert = Boolean(alert);
  const userPhone = useSelector(getUserPhone);

  const [newUserPhone, setUserPhone] = React.useState<
    PhoneNumberProps | undefined
  >();
  const [title, setTitle] = React.useState<string>('');
  const [channel, setChannel] = React.useState<SearchAlertChannel | ''>('');
  const [frequency, setFrequency] = React.useState<SearchAlertFrequency | ''>(
    '',
  );

  React.useEffect(() => {
    if (hasAlert) {
      setTitle(alert?.alias || '');
      setChannel(alert?.channel || '');
      setFrequency(alert?.frequency || '');
    } else if (!hasAlert && defaultTitle) {
      setTitle(defaultTitle);
    }
  }, [hasAlert, alert, defaultTitle]);

  const handleSaveAlert = () => {
    onSubmit(title, channel || 'email', frequency || 'daily', newUserPhone);
  };

  const msgType = {
    title: T('alert_popup.subtitle'),
    items: [
      {
        label: T('alert_popup.email_helpertext'),
        value: 'email',
      },
      {
        label: T('alert_popup.whatsapp_helpertext'),
        value: 'whatsapp',
      },
    ],
  };

  const msgFrequency = {
    title: T('search.alerts.modal.choose_when.label'),
    items: [
      {
        label: T('search.alerts.modal.choose_when.daily_label'),
        value: 'daily',
      },
      {
        label: T('search.alerts.modal.choose_when.instant_label'),
        value: 'instant',
      },
    ],
  };

  const onAlertTitleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setTitle(e.target.value);
    },
    [],
  );

  const isCTADisabled =
    !title ||
    !frequency ||
    !channel ||
    // if whatsapp chosen, but user doesn't have phone and hasn't new phone provided
    (channel === 'whatsapp' && !userPhone && !newUserPhone?.phone);

  const getAlertTitle = React.useCallback(() => {
    switch (modalType) {
      case 'update':
        return T('search_alert_update_button');
      case 'create':
        return T('search.alerts.modal.create_title');
      default:
        return T('account.alerts.modal.edit_title');
    }
  }, [modalType]);

  const getCTALabel = React.useCallback(() => {
    switch (modalType) {
      case 'update':
        return T('search_alert_update_button');
      case 'create':
        return T('search_alert_confirmation_button');
      default:
        return T('cta.save');
    }
  }, [modalType]);

  return (
    <Dialog
      show={isOpen}
      maxWidth="sm"
      fullScreen={!md}
      fullWidth
      onClose={onClose}
      dataTestLocator="Search Alert Confirmation"
      title={
        <Typography.H3
          className={classes.title}
          data-test-locator="Search Alert Dialog Title"
        >
          {getAlertTitle()}
        </Typography.H3>
      }
      content={
        <Grid
          className={classes.content}
          container
          direction="column"
          spacing={2}
        >
          {modalType !== 'edit' && (
            <Grid item>
              <Typography.Paragraph data-test-locator="Search Alert Dialog Content">
                {hasAlert
                  ? T('search_alert_update_confirmation_text')
                  : T('search_alert_create_confirmation_text')}
              </Typography.Paragraph>
            </Grid>
          )}

          <Grid item>
            <TextField
              data-test-locator="Search Alert Dialog Name Input"
              label={T('search.alerts.modal.alert_name')}
              helperText={`${title.length}/${MAX_TITLE_LENGTH}`}
              InputLabelProps={{
                shrink: true,
              }}
              FormHelperTextProps={{
                classes: {
                  root: classes.titleHelperText,
                },
              }}
              inputProps={{
                maxLength: MAX_TITLE_LENGTH,
              }}
              value={title}
              className={classes.titleField}
              onChange={onAlertTitleChange}
            />
          </Grid>

          <Grid item>
            <Typography.ParagraphTitle>
              {msgType.title}
            </Typography.ParagraphTitle>
            <RadioGroup
              aria-label="channel"
              value={channel}
              onChange={event => {
                setChannel(event.target.value as SearchAlertChannel);
              }}
              row
            >
              {msgType.items.map(({ value, label }) => (
                <FormControlLabel
                  key={value}
                  value={value}
                  control={
                    <Radio
                      color="secondary"
                      data-test-locator={`Search Alert Option ${value}`}
                    />
                  }
                  label={
                    <Grid component="span" container spacing={1}>
                      <Grid component="span" item>
                        {label}
                      </Grid>
                    </Grid>
                  }
                />
              ))}
            </RadioGroup>
          </Grid>

          {channel === 'whatsapp' && !userPhone && (
            <Grid item spacing={1} direction="column">
              <Grid item>
                <Typography.ParagraphTitle>
                  {T('search_alert_create_phone')}
                </Typography.ParagraphTitle>
              </Grid>

              <Grid item>
                <PhoneInput
                  id="phone"
                  countries={countries}
                  value={newUserPhone}
                  onChange={phoneNumber => {
                    setUserPhone(phoneNumber);
                  }}
                />
              </Grid>
            </Grid>
          )}

          <Grid item>
            <Typography.ParagraphTitle>
              {msgFrequency.title}
            </Typography.ParagraphTitle>
            <RadioGroup
              aria-label="channel"
              value={frequency}
              onChange={event => {
                setFrequency(event.target.value as SearchAlertFrequency);
              }}
              row
            >
              {msgFrequency.items.map(({ value, label }) => (
                <FormControlLabel
                  key={value}
                  value={value}
                  control={
                    <Radio
                      color="secondary"
                      data-test-locator={`Search Alert Option ${value}`}
                    />
                  }
                  label={
                    <Grid component="span" container spacing={1}>
                      <Grid component="span" item>
                        {label}
                      </Grid>
                    </Grid>
                  }
                />
              ))}
            </RadioGroup>
          </Grid>
        </Grid>
      }
      dialogActions={
        <Button
          onClick={handleSaveAlert}
          color="primary"
          variant="contained"
          disabled={isCTADisabled}
          data-test-locator="Search Alert Dialog Button"
          fullWidth={!md}
        >
          {getCTALabel()}
        </Button>
      }
    />
  );
};
