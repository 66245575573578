import React from 'react';

import loadable from '@loadable/component';
import { Skeleton } from '@mui/material';
import {
  PageInputFieldFallback,
  PageInputFieldFallbackInlineContainer,
} from '../PageInputField/PageInputFieldFallback';

const FallbackSkeleton = () => {
  return (
    <React.Fragment>
      <Skeleton height="85px" width="100%" />

      <PageInputFieldFallbackInlineContainer>
        <PageInputFieldFallback />
        <PageInputFieldFallback />
      </PageInputFieldFallbackInlineContainer>
    </React.Fragment>
  );
};

export const LoadableSearchFilterPrice = loadable(
  () =>
    import(/* webpackChunkName: "SearchFilterPricing" */ './SearchFilterPrice'),
  { fallback: <FallbackSkeleton /> },
);
