import React from 'react';

import { CurrencyFormat } from '@ha/intl';

import { Currency } from 'ha/constants/Currencies';

import { useIntl } from 'ha/i18n';
import { BillsValue } from 'ha/types/SearchFilters';

import {
  LocalState,
  SearchFiltersContext,
} from 'ha/pages/Search/SearchFiltersContext';

import { FilterRange } from '../../types';
import { PageHighlightedFilter } from '../PageHighlightedFilter/PageHighlightedFilter';
import { SearchFilter } from '../SearchFilter/SearchFilter';
import { LoadableSearchFilterBillsIncluded } from '../SearchFilterPricing/SearchFilterBillsIncluded.lazy';
import { LoadableSearchFilterCurrency } from '../SearchFilterPricing/SearchFilterCurrency.lazy';
import { LoadableSearchFilterPrice } from '../SearchFilterPricing/SearchFilterPrice.lazy';
import { SearchFilterPricingBlock } from '../SearchFilterPricing/SearchFilterPricingBlock';

export const SearchControlsHighlightedFilterPricing = () => {
  const { T, formatCurrencyRange } = useIntl();

  const {
    localState,
    clearFilters,
    priceParams,
    onChangeCurrency,
    onChangeBills,
    onChangePrice,
  } = React.useContext(SearchFiltersContext);

  const filterCurrency = (localState.currency ?? 'EUR') as Currency;
  const updateCurrency = onChangeCurrency;

  const filterPriceInCents = {
    from: localState.price.min ?? undefined,
    to: localState.price.max ?? undefined,
  };
  const updatePrice = ({ from, to }: FilterRange) => {
    onChangePrice({ min: from ?? null, max: to ?? null });
  };

  const filterBillsIncluded = localState.bills;
  const updateBillsIncluded = onChangeBills;

  const handleReset = () => {
    clearFilters(
      Object.keys(localState).filter(
        (key: keyof LocalState) =>
          !['currency', 'bills', 'price'].includes(key),
      ) as (keyof LocalState)[],
    );
  };

  const priceSelected =
    filterPriceInCents.from !== undefined ||
    filterPriceInCents.to !== undefined;

  const highlightedFilterPriceLabel = priceSelected
    ? formatCurrencyRange(
        filterCurrency,
        Math.max(
          filterPriceInCents.from ?? priceParams.priceMin,
          priceParams.priceMin,
        ),
        Math.min(
          filterPriceInCents.to ?? priceParams.priceMax,
          priceParams.priceMax,
        ),
        CurrencyFormat.WITH_CURRENCY_SYMBOL_NO_FRACTION,
      )
    : T('search.page.chip.price');

  const billsIncludedSelected = filterBillsIncluded.includes(
    BillsValue.INCLUDED,
  );

  const highlightedFilterBillsIncludedLabel = billsIncludedSelected
    ? T('search.listing_card.bills_included')
    : null;

  const pricingSelected = priceSelected || billsIncludedSelected;

  const label = [
    highlightedFilterPriceLabel,
    highlightedFilterBillsIncludedLabel,
  ]
    .filter(Boolean)
    .join(', ');

  return (
    <PageHighlightedFilter
      slots={{
        chip: (
          <PageHighlightedFilter.Chip highlighted={pricingSelected}>
            {label}
          </PageHighlightedFilter.Chip>
        ),
      }}
    >
      <PageHighlightedFilter.Content>
        <SearchFilter>
          <SearchFilter.Title
            slots={{
              end: (
                <LoadableSearchFilterCurrency
                  value={filterCurrency}
                  onChange={updateCurrency}
                />
              ),
            }}
          />

          <SearchFilterPricingBlock>
            <LoadableSearchFilterPrice
              currency={filterCurrency}
              valueInCents={filterPriceInCents}
              absoluteMaxInCents={priceParams.priceMax}
              absoluteMinInCents={priceParams.priceMin}
              onChange={updatePrice}
              distribution={priceParams.priceDistribution}
            />

            <LoadableSearchFilterBillsIncluded
              value={filterBillsIncluded}
              onChange={updateBillsIncluded}
            />
          </SearchFilterPricingBlock>
        </SearchFilter>
      </PageHighlightedFilter.Content>
      <PageHighlightedFilter.Footer onReset={handleReset} />
    </PageHighlightedFilter>
  );
};
