import { useCallback } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { track } from '@hbf/analytics';

import { useIntl } from 'ha/i18n';
import { SortingValue } from 'ha/types/SearchFilters';

import { onChangeFilters } from '../actions';
import { getCurrentSorting, getCityCanonical } from '../selectors';

const useSorting = () => {
  const { T } = useIntl();

  const currentSorting = useSelector(getCurrentSorting);
  const cityCanonical = useSelector(getCityCanonical);

  const dispatch = useDispatch();

  const onOpen = useCallback(() => {
    track('Select search sort', {
      listingcity: cityCanonical,
    });
  }, [cityCanonical]);

  const onChange = useCallback(
    (value: SortingValue) => {
      track('Search sort applied', {
        sortType: value,
        listingcity: cityCanonical,
      });

      dispatch(onChangeFilters({ sorting: value }));
    },
    [dispatch, cityCanonical],
  );

  const options = [
    { key: T('Best match'), value: SortingValue.BestMatch },
    { key: T('Most recent'), value: SortingValue.MostRecent },
    { key: T('Lowest price'), value: SortingValue.LowToHigh },
    { key: T('Highest price'), value: SortingValue.HighToLow },
    {
      key: T('search.filters.popup.header.advertiser_rating'),
      value: SortingValue.AdvertiserRating,
    },
  ];

  return {
    currentSorting,
    options,
    onChange,
    onOpen,
  };
};

export { useSorting };
