import { handleActions } from 'redux-actions';

import { BillsValue } from 'ha/types/SearchFilters';

import { Actions } from '../constants';

import { BillsFilterState } from './types';

const initialState = {
  value: [],
};

interface Payload {
  bills: BillsValue;
}

const billsFilter = handleActions<BillsFilterState, Payload>(
  {
    [Actions.UPDATE_SEARCH_PARAMS]: (state, { payload: { bills = [] } }) => ({
      ...state,
      value: Array.isArray(bills) ? bills : [bills],
    }),
  },
  initialState,
);

export const getBillsFilter = (state: BillsFilterState) => state.value;
export { billsFilter };
