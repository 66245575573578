import { handleActions } from 'redux-actions';
import { Actions } from '../constants';

const initialState = null;

const recentlyAddedFilter = handleActions(
  {
    [Actions.UPDATE_SEARCH_PARAMS]: (
      _,
      { payload: { recentlyAdded = null } },
    ) => recentlyAdded,
  },
  initialState,
);

export const getRecentlyAddedFilter = state => state;
export { recentlyAddedFilter };
