import React from 'react';

import { DEFAULT_CURRENCY } from 'ha/constants/Currencies';
import { DEFAULT_SEARCH_FLEXIBILITY } from 'ha/constants/FlexibleSearchDates';

import {
  DateFilterValue,
  TypeFilterValue,
  RoomsFilterValue,
  PriceFilterValue,
  FurnitureFilterValue,
  BillsFilterValue,
  SuitableForFilterValue,
  GenderFilterValue,
  RulesFilterValue,
  RegistrationFilterValue,
  RecentlyAddedFilterValue,
  ContractTypeFilterValue,
  SearchAreaFilterValue,
  PropertySizeFilterValue,
  FacilitiesFilterValue,
  AmenitiesFilterValue,
  RadiusFilterValue,
  RadiusValue,
  AvailabilityFilterValue,
  AdvertiserRatingFilterValue,
} from 'ha/types/SearchFilters';

import { DistributionValue } from './utils/reformatDistribution';

export interface LocalState {
  dates: DateFilterValue;
  types: TypeFilterValue;
  advRating: AdvertiserRatingFilterValue;
  furniture: FurnitureFilterValue;
  bills: BillsFilterValue;
  rooms: RoomsFilterValue;
  rules: RulesFilterValue;
  price: PriceFilterValue;
  currency: string;
  registration: RegistrationFilterValue;
  searchArea: SearchAreaFilterValue;
  radius: RadiusFilterValue;
  place: string;
  recentlyAdded: RecentlyAddedFilterValue;
  gender: GenderFilterValue;
  suitableFor: SuitableForFilterValue;
  contractType: ContractTypeFilterValue;
  propertySize?: PropertySizeFilterValue;
  facilities: FacilitiesFilterValue;
  amenities: AmenitiesFilterValue;
}

export interface SearchFiltersContextValue {
  localState: LocalState;
  priceParams: {
    priceMin: number;
    priceMax: number;
    priceDistribution: DistributionValue[];
  };
  onChangeDates: (_: DateFilterValue) => void;
  onChangeAvailability: (_: AvailabilityFilterValue) => void;
  onChangeTypes: (_: TypeFilterValue) => void;
  onChangeRooms: (_: RoomsFilterValue) => void;
  onChangePrice: (_: PriceFilterValue) => void;
  onChangeCurrency: (_: string) => void;
  onChangeAdvertiserRatings: (_: AdvertiserRatingFilterValue) => void;
  onChangeFurniture: (_: FurnitureFilterValue) => void;
  onChangeBills: (_: BillsFilterValue) => void;
  onChangeSuitableFor: (_: SuitableForFilterValue) => void;
  onChangeGender: (_: GenderFilterValue) => void;
  onChangeRules: (_: RulesFilterValue) => void;
  onChangeRegistration: (_: RegistrationFilterValue) => void;
  onChangeSearchAreaFilter: (_: SearchAreaFilterValue) => void;
  onChangeRadius: (_: RadiusFilterValue) => void;
  onChangePlace: (_: string) => void;
  onChangeRecentlyAdded: (_: RecentlyAddedFilterValue) => void;
  onChangeContractType: (_: ContractTypeFilterValue) => void;
  onChangePropertySize: (_: PropertySizeFilterValue) => void;
  onChangeFacilities: (_: FacilitiesFilterValue) => void;
  onChangeAmenities: (_: AmenitiesFilterValue) => void;
  clearFilters: (filtersToKeep?: Array<keyof LocalState>) => void;
  isFilterEmpty: (filterName: keyof LocalState) => boolean;
  applyFilters: () => void;
  revertFilters: (revertKeys: (keyof LocalState)[]) => void;
}

export const EmptyState: LocalState = {
  dates: {
    startDate: null,
    endDate: null,
    flexDays: DEFAULT_SEARCH_FLEXIBILITY.Search,
  },
  types: [],
  advRating: [],
  furniture: [],
  bills: [],
  rooms: {
    bedroomCount: [],
  },
  rules: [],
  price: {
    min: null,
    max: null,
  },
  currency: DEFAULT_CURRENCY,
  registration: [],
  searchArea: [],
  recentlyAdded: null,
  gender: [],
  suitableFor: [],
  contractType: [],
  radius: RadiusValue.DEFAULT,
  place: '',
  propertySize: {
    sizeMin: undefined,
    sizeMax: undefined,
  },
  facilities: [],
  amenities: [],
};

export const SearchFiltersContext =
  React.createContext<SearchFiltersContextValue>({
    localState: EmptyState,
    priceParams: {
      priceMin: 0,
      priceMax: 0,
      priceDistribution: [],
    },
    onChangeDates: (_: DateFilterValue) => {},
    onChangeTypes: (_: TypeFilterValue) => {},
    onChangeRooms: (_: RoomsFilterValue) => {},
    onChangePrice: (_: PriceFilterValue) => {},
    onChangeCurrency: (_: string) => {},
    onChangeAdvertiserRatings: (_: AdvertiserRatingFilterValue) => {},
    onChangeFurniture: (_: FurnitureFilterValue) => {},
    onChangeBills: (_: BillsFilterValue) => {},
    onChangeSuitableFor: (_: SuitableForFilterValue) => {},
    onChangeGender: (_: GenderFilterValue) => {},
    onChangeRules: (_: RulesFilterValue) => {},
    onChangeRegistration: (_: RegistrationFilterValue) => {},
    onChangeSearchAreaFilter: (_: SearchAreaFilterValue) => {},
    onChangeRadius: (_: RadiusFilterValue) => {},
    onChangePlace: (_: string) => {},
    onChangeRecentlyAdded: (_: RecentlyAddedFilterValue) => {},
    onChangeContractType: (_: ContractTypeFilterValue) => {},
    onChangePropertySize: (_: PropertySizeFilterValue) => {},
    onChangeFacilities: (_: FacilitiesFilterValue) => {},
    onChangeAmenities: (_: AmenitiesFilterValue) => {},
    clearFilters: () => {},
    isFilterEmpty: () => true,
    applyFilters: () => {},
    revertFilters: () => {},
    onChangeAvailability: (_: AvailabilityFilterValue) => {},
  });

SearchFiltersContext.displayName = 'SearchFiltersContext';
