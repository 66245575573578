import React from 'react';

import { useParams } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { makeStyles } from 'tss-react/mui';

import { Link, Grid, units } from '@hbf/dsl/legacy';

import { useIntl } from 'ha/i18n';

import { getCitiesNearby, getCountryName } from '../selectors';

const useStyles = makeStyles()(theme => ({
  wrapper: {
    margin: units.yx(0.75, 0),

    [theme.breakpoints.down('md')]: {
      padding: units.yx(0, 0.5),
    },
  },
}));

const SearchCitiesNearby: React.FC = () => {
  const { classes } = useStyles();
  const { T, urlResolver } = useIntl();
  const params = useParams();
  const citiesNearby = useSelector(getCitiesNearby) ?? [];
  const localisedCountry = useSelector(getCountryName);

  if (!citiesNearby?.length) {
    return null;
  }

  return (
    <Grid item xs={12} className={classes.wrapper}>
      <span>{`${T('Other cities in %s:', localisedCountry)} `}</span>
      {citiesNearby.map(({ cityName, cityCanonical, searchUrl }, index) => {
        const url = urlResolver.applyFilterToSearchUrl(
          searchUrl,
          params.localizedKind,
        );

        return (
          <React.Fragment key={cityCanonical}>
            <Link to={url} kind="mint-underline" title={cityName}>
              {cityName}
            </Link>
            {index + 1 < citiesNearby.length ? ', ' : null}
          </React.Fragment>
        );
      })}
    </Grid>
  );
};

export { SearchCitiesNearby };
