import { getFromStorage, setInStorage } from 'ha/utils/storage';
import { LocalState } from '../SearchFiltersContext';

export const LOCAL_STORAGE_KEY = 'searchLocalState';

export function saveLocalState(localState: LocalState) {
  setInStorage(LOCAL_STORAGE_KEY, localState);
}

export function loadSavedLocalState() {
  return getFromStorage(LOCAL_STORAGE_KEY) as LocalState;
}
