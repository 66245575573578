import { Language } from '@ha/intl';
import {
  LocaleToCanonicalCollections,
  TranslatedCollectionToCanonical,
} from 'ha/constants/SearchPageCollections';

export function getTranslatedCollection(
  lang: Language,
  encodedCollection: string,
) {
  // Do a reverse search of current collection in url to get it in the correct locale.
  const localeCollections = LocaleToCanonicalCollections[lang];
  const canonicalCollection =
    TranslatedCollectionToCanonical[encodedCollection];
  const translatedCollection =
    (localeCollections &&
      canonicalCollection &&
      localeCollections[canonicalCollection]) ||
    '';

  return translatedCollection;
}
