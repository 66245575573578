import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { getRouteData } from 'ha/modules/Hermes';

import { getCollectionFilter, getCityName } from '../selectors';

import { SearchCollectionSubtitle } from '../components/SearchCollectionSubtitle';

const enhance = compose(
  connect(
    createStructuredSelector({
      canonicalCollection: getCollectionFilter,
      city: getCityName,
      hermesData: getRouteData,
    }),
  ),
);

const SearchCollectionSubtitleContainer = enhance(SearchCollectionSubtitle);
export { SearchCollectionSubtitleContainer };
