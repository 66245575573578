import { AlertDiamond, CheckCircle1 } from '@hbf/icons/streamline-light';
import React from 'react';
import { useSelector } from 'react-redux';
import { Theme } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import { useIntl } from 'ha/i18n';
import { useMediaQuery } from 'ha/helpers/MediaQuery';
import { Grid, Typography, units } from '@hbf/dsl/legacy';
import { DialogNext as Dialog } from 'ha/components/Dialog';
import { getIsAdvertiser } from 'ha/modules/AuthLogic/selectors';

import { getIsErrorSearchAlert } from '../../selectors/searchAlertSelectors';

const useStyles = makeStyles()((theme: Theme) => ({
  gridContainer: {
    textAlign: 'center',
    height: '100%',

    [theme.breakpoints.up('sm')]: {
      marginBottom: units.rem(2.5),
      marginTop: units.rem(0.5),
    },
  },
  content: {
    marginTop: units.rem(1),
    marginBottom: units.rem(2.5),
  },
  iconWrapper: {
    marginTop: units.rem(2),
  },
  icon: {
    width: units.rem(3.2),
    height: units.rem(3.2),
  },
  paragraphWrapper: {
    marginTop: units.rem(0.5),
  },
  dialogPaper: {
    maxWidth: '500px',
  },
}));

interface Props {
  isOpen: boolean;
  onClose: () => void;
  hasAlert: boolean;
}

const SearchAlertResponseDialog: React.FC<Props> = ({
  isOpen,
  onClose,
  hasAlert,
}) => {
  const { classes } = useStyles();
  const { T } = useIntl();
  const { sm } = useMediaQuery();

  const isAdvertiser = useSelector(getIsAdvertiser);
  const hasError = useSelector(getIsErrorSearchAlert);

  return (
    <Dialog
      show={isOpen}
      maxWidth="xs"
      fullScreen={!sm}
      onClose={onClose}
      dataTestLocator="Search Alert Response"
      classes={{
        paper: classes.dialogPaper,
      }}
      content={
        <Grid
          className={classes.gridContainer}
          container
          direction="column"
          justifyContent="center"
        >
          <Grid item className={classes.iconWrapper}>
            {hasError ? (
              <AlertDiamond color="error" className={classes.icon} />
            ) : (
              <CheckCircle1 color="secondary" className={classes.icon} />
            )}
          </Grid>

          <Grid item className={classes.content}>
            <Typography.H3 align="center">
              {hasError
                ? hasAlert
                  ? T('search_alert_update_error_message')
                  : T('search_alert_create_error_message')
                : hasAlert
                ? T('search_alert_updated_successfully')
                : T('search_alert_created_successfully')}
            </Typography.H3>

            <div className={classes.paragraphWrapper}>
              {!isAdvertiser && (
                <Typography.Paragraph align="center">
                  {T('search_alert_manage_in_menu')}
                </Typography.Paragraph>
              )}

              {hasError && (
                <Typography.Paragraph
                  align="center"
                  data-test-locator="Search Alert Upsert Error"
                >
                  {T('search_alert_error_try_again')}
                </Typography.Paragraph>
              )}
            </div>
          </Grid>
        </Grid>
      }
    />
  );
};

export { SearchAlertResponseDialog };
