import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import {
  getCollectionFilter,
  getLocation,
  isSearchByPartner,
  getlangURLListState,
} from 'ha/pages/Search/selectors';

import { SearchCategories } from 'ha/pages/Search/components/SearchCategories';

const enhance = compose(
  connect(
    createStructuredSelector({
      canonicalCollection: getCollectionFilter,
      place: getLocation,
      langUrlList: getlangURLListState,
      isSearchByPartner,
    }),
  ),
);

const SearchCategoriesContainer = enhance(SearchCategories);
export { SearchCategoriesContainer };
