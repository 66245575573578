import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

const useQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const updateSearchAlert = searchParams.get('updateSearchAlert');

  const removeUpdateSearchAlertParam = useCallback((): void => {
    if (updateSearchAlert) {
      setSearchParams(
        params => {
          params.delete('updateSearchAlert');
          return params;
        },
        { replace: true },
      );
    }
  }, [updateSearchAlert, setSearchParams]);

  return {
    removeUpdateSearchAlertParam,
  };
};

export { useQueryParams };
