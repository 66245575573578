import React from 'react';

import loadable from '@loadable/component';
import { PageToggleButtonFallback } from '../PageToggleButton/PageToggleButtonFallback';
import { PageToggleButtonContainer } from '../PageToggleButton/PageToggleButtonContainer';

const FallbackSkeleton = () => {
  return (
    <PageToggleButtonContainer>
      <PageToggleButtonFallback />
      <PageToggleButtonFallback />
      <PageToggleButtonFallback />
      <PageToggleButtonFallback />
    </PageToggleButtonContainer>
  );
};

export const LoadableSearchFilterAdvertiserRating = loadable(
  () =>
    import(
      /* webpackChunkName: "DrawerSearchFilters" */ './SearchFilterAdvertiserRating'
    ),
  { fallback: <FallbackSkeleton /> },
);
