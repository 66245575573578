import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Skeleton } from '@mui/material';
import { Card } from '../../Card';

const useStyles = makeStyles()(theme => ({
  container: {
    textDecoration: 'none',
  },
  header: {
    position: 'relative',
    width: '100%',
    paddingBottom: '100%',
  },
  gallerySkeleton: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  body: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  section: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
}));

export const ListingCardSkeleton: React.FC = () => {
  const { classes } = useStyles();

  return (
    <Card>
      <div className={classes.header}>
        <Skeleton variant="rounded" className={classes.gallerySkeleton} />
      </div>
      <div className={classes.body}>
        <div className={classes.section}>
          <Skeleton variant="rounded" height={34} width="70%" />
        </div>
        <div className={classes.section}>
          <Skeleton variant="rounded" height={18} width="90%" />
        </div>
        <div className={classes.section}>
          <Skeleton variant="rounded" height={18} width="30%" />
        </div>
        <div className={classes.section}>
          <Skeleton variant="rounded" height={24} width="35%" />
          <Skeleton variant="rounded" height={24} width="10%" />
        </div>
      </div>
    </Card>
  );
};
