import { combineReducers } from 'redux';

import {
  advertiserRatingFilter,
  getAdvertiserRatingFilter as getAdvertiserRating,
} from './advertiserRatingFilter';
import { billsFilter, getBillsFilter as getBills } from './billsFilter';
import { citiesNearbyReducer as citiesNearby } from './citiesNearby';
import { city } from './city';
import {
  collectionFilter,
  getCategories,
  getCollection,
} from './collectionFilter';
import { contractTypeFilter } from './contractTypeFilter';
import { currencyRatesReducer as currencyRates } from './currencyRates';
import { dateFilter } from './dateFilter';
import { filters } from './filters';
import {
  furnitureFilter,
  getFurnitureFilter as getFurniture,
} from './furnitureFilter';
import { genderFilter, getGenderFilter as getGender } from './genderFilter';
import { langURLListReducer as langURLList } from './langURLList';
import { lazyListing } from './lazyListing';
import { listings } from './listings';
import { listingsSummaryReducer as listingsSummary } from './listingsSummary';
import * as loadStateSelectors from './loadState';
import { map } from './map';
import { pageInfo } from './pageInfo';
import { priceFilter } from './priceFilter';
import {
  getRecentlyAddedFilter as getRecentlyAdded,
  recentlyAddedFilter,
} from './recentlyAddedFilter';
import {
  getRegistrationFilter as getRegistration,
  registrationFilter,
} from './registrationFilter';
import { requestCache } from './requestCache';
import { roomsFilter } from './roomsFilter';
import { getRulesFilter as getRules, rulesFilter } from './rulesFilter';
import { searchAlertReducer as searchAlert } from './searchAlert';
import { sortingReducer as sorting } from './sorting';
import {
  getSuitableForFilter as getSuitableFor,
  suitableForFilter,
} from './suitableForFilter';
import { SearchState } from './types';
import { universities } from './universities';

const reducer = combineReducers({
  loadState: loadStateSelectors.loadState,
  collectionFilter,
  advertiserRatingFilter,
  furnitureFilter,
  billsFilter,
  roomsFilter,
  registrationFilter,
  suitableForFilter,
  recentlyAddedFilter,
  rulesFilter,
  genderFilter,
  contractTypeFilter,
  listings,
  listingsSummary,
  map,
  pageInfo,
  dateFilter,
  priceFilter,
  universities,
  citiesNearby,
  langURLList,
  currencyRates,
  searchAlert,
  requestCache,
  sorting,
  city,
  lazyListing,
  filters,
});

export const getCountryCode = (state: SearchState) =>
  loadStateSelectors.getCountryCode(state.loadState);
export const getFurnitureFilter = (state: SearchState) =>
  getFurniture(state.furnitureFilter);
export const getBillsFilter = (state: SearchState) =>
  getBills(state.billsFilter);
export const getGenderFilter = (state: SearchState) =>
  getGender(state.genderFilter);
export const getCategoriesFilter = (state: SearchState) =>
  getCategories(state.collectionFilter);
export const getCollectionFilter = (state: SearchState) =>
  getCollection(state.collectionFilter);
export const getRegistrationFilter = (state: SearchState) =>
  getRegistration(state.registrationFilter);
export const getRulesFilter = (state: SearchState) =>
  getRules(state.rulesFilter);
export const getSuitableForFilter = (state: SearchState) =>
  getSuitableFor(state.suitableForFilter);
export const getRecentlyAddedFilter = (state: SearchState) =>
  getRecentlyAdded(state.recentlyAddedFilter);
export const getAdvertiserRatingFilter = (state: SearchState) =>
  getAdvertiserRating(state.advertiserRatingFilter);
export { reducer };
