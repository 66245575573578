export const excludedCities = [
  'Paris',
  'Evry',
  'Gif-sur-Yvette',
  'Longjumeau',
  'Massy',
  'Jouy-en-josas',
  'Versailles',
  'Montigny-le-bretonneux',
  'Bures-sur-Yvette',
  'Igny',
  'Orsay',
  'Saclay',
  'Saint-Aubin',
  'Villiers-le-Bâcle',
  'Aubervilliers',
  'Antony',
  'Châtenay-Malabry',
  'Sceaux',
  'Bourg-la-Reine',
  'Bagneux',
  'Fontenay-aux-Roses',
  'Le-Plessis-Robinson',
  'Clamart',
  'Châtillon',
  'Montrouge',
  'Malakoff',
  'Vanves',
  'Pantin',
  'Issy-les-Moulineaux',
  'Boulogne-Billancourt',
  'Meudon',
  'Sèvres',
  'Chaville',
  "Ville-d'Avray",
  'Saint-Cloud',
  'Montreuil',
  'Saint-Denis',
  'Garches',
  'Rueil-Malmaison',
  'Suresnes',
  'Puteaux',
  'Nanterre',
  'Colombes',
  'La-Garenne-Colombes',
  'Bois-Colombes',
  'Courbevoie',
  'Neuilly-sur-Seine',
  'Levallois-Perret',
  'Clichy',
  'Asnières-sur-Seine',
  'Gennevilliers',
  'Villeneuve-la-Garenne',
  'Saint-ouen',
  'Vitry-sur-Seine',
  'Créteil',
  'Vincennes',
  "L'Haÿ-les-Roses",
  'Nogent-sur-Marne',
  'Villejuif',
  'Saint-Maurice',
  'Cachan',
  'Champigny-sur-Marne',
  'Charenton-le-Pont',
  'Saint-Maur-des-Fossés',
  'Saint-Mandé',
  'Choisy-le-Roi',
  'Fontenay-sous-Bois',
  'Fresnes',
  'Gentilly',
  'Ivry-sur-Seine',
  'Joinville-le-Pont',
  'Le-Kremlin-Bicêtre',
  'Maisons-Alfort',
];
