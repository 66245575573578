import { connect } from 'react-redux';
import {
  getPageInfo,
  getAnalyticsForFilters,
  getAnalyticsForListings,
  getCountryCode,
  getCityCanonical,
  getAdvertiserName,
  getIsImperialSystem,
} from 'ha/pages/Search/selectors';
import { getIsMobile } from 'ha/modules/UI/selectors';
import { GlobalState } from 'ha/types/store';
import { ListingPreviewCardTracked } from '../components/ListingPreviewCardTracked';

const ListingPreviewCardTrackedContainer = connect((state: GlobalState) => ({
  pageInfo: getPageInfo(state),
  countryCode: getCountryCode(state),
  cityCanonical: getCityCanonical(state),
  filtersAnalytics: getAnalyticsForFilters(state),
  listingsAnalytics: getAnalyticsForListings(state),
  isAdvertiserSearch: Boolean(getAdvertiserName(state)),
  isMobile: getIsMobile(state),
  isImperialSystem: getIsImperialSystem(state),
}))(ListingPreviewCardTracked);
export { ListingPreviewCardTrackedContainer };
