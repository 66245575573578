import { useState } from 'react';

import { useServices } from 'ha/services';
import { CookieAttrs } from 'ha/services/cookie';
import { CookiesNames } from 'ha/constants/Cookies';

export function useCookie(
  key: CookiesNames,
): [
  string | undefined,
  (value: string, options?: CookieAttrs) => void,
  (options?: CookieAttrs) => void,
] {
  const {
    cookie: { getCookie, setCookie, removeCookie },
  } = useServices();

  const [item, setItem] = useState(() => getCookie(key));

  const setValue = (value: string, options?: CookieAttrs) => {
    setItem(value);
    setCookie(key, value, options);
  };

  const removeValue = (options?: CookieAttrs) => {
    setItem(undefined);
    removeCookie(key, options);
  };

  return [item, setValue, removeValue];
}
