/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createSelector } from 'reselect';

import { isNorthAmericanCountry } from '@ha/core/SupportedCountries';

import { getCurrentScreenSize } from 'ha/modules/UI/selectors';

import { boxAspectRatio as ImageRatio } from 'ha/components.legacy/Listing/constants';

import { ImageHeight } from '../constants';
import { RootState } from '../types';

export const getImageSize = createSelector(
  [getCurrentScreenSize],
  screenSize => {
    const height = ImageHeight[screenSize];

    if (!height) return null;

    return `${(height * ImageRatio).toFixed(0)}x${height}`;
  },
);

const getCities = (state: RootState) => state.cities;

export const getListings = (state: RootState) => getCities(state).listings;

export const getCategories = (state: RootState) => {
  const { apartments, studios, privateRooms, sharedRooms } = getCities(state);

  return {
    apartments,
    studios,
    privateRooms,
    sharedRooms,
  };
};

export const isError = (state: RootState) => getCities(state).error;

export const getListingsCount = (state: RootState) =>
  getCities(state).listingsCount;

export const getCityLocalized = (state: RootState) =>
  getCities(state).cityLocalized;
export const getCityCanonical = (state: RootState) =>
  getCities(state).cityCanonical;
export const getCountryLocalized = (state: RootState) =>
  getCities(state).countryLocalized;
export const getCountryCode = (state: RootState) =>
  getCities(state).countryCode;
export const getCountryCanonical = (state: RootState) =>
  getCities(state).countryCanonical;
const getLangURLList = (state: RootState) => getCities(state).langURLList;

export const getCityLandingUrlList = (state: RootState) => {
  const langURLList = getLangURLList(state);

  if (!langURLList) {
    return langURLList;
  }

  return Object.keys(langURLList).reduce((acc, key) => {
    acc[key] = langURLList[key].replace('/s/', '/');

    return acc;
  }, {});
};

export const getUniversities = (state: RootState) =>
  getCities(state).universities;
export const getFeaturedCities = (state: RootState) =>
  getCities(state).citiesFeatured;
export const getPathname = (state: RootState) => getCities(state).pathname;
export const getIsImperialSystem = (state: RootState) =>
  isNorthAmericanCountry(getCountryCode(state));
