import { handleActions } from 'redux-actions';

import { TranslatedCollectionToCanonical } from 'ha/constants/SearchPageCollections';

import { CanonicalCollection, TypeFilterValue } from 'ha/types/SearchFilters';

import { Actions } from '../constants';

import { CollectionFilterState } from './types';

const initialState: CollectionFilterState = {
  collection: '',
  categories: [],
};

interface Payload {
  categories: TypeFilterValue;
  collection: CanonicalCollection;
  localizedKind: any;
}

const collectionFilter = handleActions<CollectionFilterState, Payload>(
  {
    [Actions.UPDATE_SEARCH_PARAMS]: (
      state,
      // TODO: Localized kind is a bad name for that part of the URL
      { payload: { localizedKind, categories = [] } },
    ) => ({
      ...state,
      collection: localizedKind
        ? TranslatedCollectionToCanonical[localizedKind]
        : '',
      categories: Array.isArray(categories) ? categories : [categories],
    }),
  },
  initialState,
);

export const getCollection = (state: CollectionFilterState) => state.collection;
export const getCategories = (state: CollectionFilterState) => state.categories;
export { collectionFilter };
