import { filterBuilder, BuilderContext } from '@ha/algolia';
import { AlgoliaQuery } from 'ha/services/algolia';
import { getGeoBoundaryFilter } from './getGeoBoundaryFilter';
import { getFilterParams } from './getFilterParams';
import { ReqParams, SearchParams, CurrencyEuroRates } from './types';

const queryFilterBuilder = filterBuilder();

const getAlgoliaPinsQuery = (
  reqParams: ReqParams,
  searchParams: SearchParams,
  currencyEuroRates: CurrencyEuroRates,
  context: BuilderContext,
): AlgoliaQuery => {
  const geoBoundaryFilter = getGeoBoundaryFilter(reqParams, searchParams);
  const filterParams = getFilterParams(reqParams, currencyEuroRates);
  const filters = queryFilterBuilder(filterParams, context);

  return {
    query: '',
    params: {
      distinct: true,
      insideBoundingBox: geoBoundaryFilter,
      filters,
      hitsPerPage: 1000,
      attributesToRetrieve: [
        'internalID',
        '_geoloc',
        'priceEUR',
        'minPrice',
        'priceType',
        'unitTypeInternalID',
        'facility_student_housing',
        'facility_co_living',
      ],
      attributesToHighlight: [],
    },
  };
};

export { getAlgoliaPinsQuery };
