import React from 'react';

import loadable from '@loadable/component';
import {
  PageCheckboxFallback,
  PageCheckboxFallbackList,
} from '../PageCheckbox/PageCheckboxFallback';

const FallbackSkeleton = () => {
  return (
    <PageCheckboxFallbackList>
      <PageCheckboxFallback />
      <PageCheckboxFallback />
      <PageCheckboxFallback />
    </PageCheckboxFallbackList>
  );
};
export const LoadableSearchFilterPropertyType = loadable(
  () =>
    import(
      /* webpackChunkName: "SearchFilterPropertyType" */ './SearchFilterPropertyType'
    ),
  { fallback: <FallbackSkeleton /> },
);
