import { handleActions } from 'redux-actions';

import { Actions } from '../constants';

const initialState = { EUR: 1 };

const currencyRatesReducer = handleActions(
  {
    [Actions.LOAD_DONE]: (_, { payload: { currencyRates } }) => currencyRates,
  },
  initialState,
);
export { currencyRatesReducer };
