import { handleActions } from 'redux-actions';
import { Actions, PageSize } from '../constants';

const initialState = {
  limit: PageSize,
  offset: 0,
  pages: 0,
  total: 0,
  isExhaustive: true,
};

const pageInfo = handleActions(
  {
    [Actions.LOAD_START]: (state, { payload: requestId }) => ({
      ...state,
      requestId,
    }),

    [Actions.LOAD_DONE]: (state, { payload }) => ({
      ...payload.pageInfo,
      total: payload.listingsCount,
      offset: state.offset,
      requestId: state.requestId,
      userId: state.userId,
      advertiserName: state.advertiserName,
    }),

    [Actions.LOAD_ADVERTISER_DONE]: (state, { payload }) => ({
      ...state,
      advertiserName: payload.firstName,
    }),

    [Actions.UPDATE_SEARCH_PARAMS]: (
      state,
      { payload: { page, limit, userId } },
    ) => {
      const pageInt = page ? parseInt(page, 10) : 0;
      const nextLimit = limit || state.limit;

      return {
        ...state,
        limit: nextLimit,
        offset: pageInt > 0 ? (pageInt - 1) * nextLimit : 0,
        userId: userId ? Number(userId) : undefined,
      };
    },

    [Actions.TOGGLE_MOBILE_DATE_PICKER]: state => ({
      ...state,
    }),
  },
  initialState,
);
export { pageInfo };
