import React from 'react';
import { Tabs } from '@hbf/dsl/core';
import { Enum } from '@ha/core/utilities';
import { SearchFiltersContext } from 'ha/pages/Search/SearchFiltersContext';
import { useTrackEvent } from 'ha/modules/Analytics/helpers/TrackEvent';
import { useIntl } from 'ha/i18n';
import { useSelector } from 'react-redux';
import { getUser } from 'ha/modules/AuthLogic/selectors';
import { makeStyles } from 'tss-react/mui';
import { Divider } from '@mui/material';
import {
  QUICK_FILTER_ANALYTICS_EVENT,
  QUICK_FILTER_ANALYTICS_NAME,
  QUICK_FILTER_TRANSLATIONS,
  QuickFilter,
  getQuickFilterMatch,
  useQuickFilters,
} from './constants';

const useStyles = makeStyles()(theme => ({
  tabs: {
    width: 'max-content',
  },

  divider: {
    marginBlock: theme.utils.spacing('ref/spacing/2'),
    borderColor: theme.palette.mono.dark,
  },
}));

// Mui Tabs insists on inspecting children and cloning them, so we need to trick it into skipping over non tab items
const TabDivider = React.forwardRef(() => {
  const { classes } = useStyles();

  return (
    <Divider orientation="vertical" flexItem className={classes.divider} />
  );
});

export const SearchControlsQuickFilters = () => {
  const { T } = useIntl();

  const { classes } = useStyles();

  const user = useSelector(getUser);

  const { localState, isFilterEmpty } = React.useContext(SearchFiltersContext);
  const track = useTrackEvent();

  const activeQuickFilter =
    getQuickFilterMatch({ filters: localState, isFilterEmpty })?.[0] ??
    QuickFilter.YourSearch;

  const { onChangeQuickFilter } = useQuickFilters();

  const handleChange = (_: unknown, value: QuickFilter | false) => {
    if (value === false) return;

    track(QUICK_FILTER_ANALYTICS_EVENT, {
      quickFilterName: QUICK_FILTER_ANALYTICS_NAME[value],
      userID: user?.id,
    });

    onChangeQuickFilter(value);
  };

  return (
    <Tabs
      className={classes.tabs}
      size="sm"
      underline={false}
      value={activeQuickFilter}
      onChange={handleChange}
    >
      {activeQuickFilter === QuickFilter.YourSearch && (
        <Tabs.Item key={QuickFilter.YourSearch} value={QuickFilter.YourSearch}>
          {T(QUICK_FILTER_TRANSLATIONS[QuickFilter.YourSearch])}
        </Tabs.Item>
      )}

      {activeQuickFilter === QuickFilter.YourSearch && <TabDivider />}

      {Enum.values(QuickFilter)
        .filter(value => value !== QuickFilter.YourSearch)
        .map((filter: QuickFilter) => (
          <Tabs.Item key={filter} value={filter}>
            {T(QUICK_FILTER_TRANSLATIONS[filter])}
          </Tabs.Item>
        ))}
    </Tabs>
  );
};
