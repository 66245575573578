import React from 'react';

import { Typography } from '@hbf/dsl/core';

import { useIntl } from 'ha/i18n';

import { RebrandThemeProvider } from 'ha/modules/ThemeProvider';

import { SearchControlsAlertButton } from '../SearchControlsAlert/SearchControlsAlertButton';

import { SearchResultsGuideBase } from './SearchResultsGuideBase';

const SearchResultsEmpty: React.FC = () => {
  const { T } = useIntl();
  return (
    <RebrandThemeProvider>
      <SearchResultsGuideBase
        slots={{
          header: (
            <React.Fragment>
              <Typography
                variant="heading/mobile/h3-semibold"
                data-test-locator="SearchResultsEmpty/Title"
              >
                {T('search.page.header.no_results.too_many_filters')}
              </Typography>

              <Typography
                variant="body/sm-regular"
                data-test-locator="SearchResultsEmpty/Description"
              >
                {T('search.page.description.no_results.too_many_filters')}
              </Typography>
            </React.Fragment>
          ),
          createAlertButton: (
            <SearchControlsAlertButton
              customEventProps={{ eventOriginated: 'Search with no results' }}
            />
          ),
        }}
      />
    </RebrandThemeProvider>
  );
};

export { SearchResultsEmpty };
