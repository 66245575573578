import { handleActions } from 'redux-actions';

import { RoomsValue } from 'ha/types/SearchFilters';

import { Actions } from '../constants';

import { RoomsFilterState } from './types';

export const initialState = {
  bedroomCount: [],
};

interface Payload {
  bedroomCount: RoomsValue;
}

const roomsFilter = handleActions<RoomsFilterState, Payload>(
  {
    [Actions.UPDATE_SEARCH_PARAMS]: (
      state,
      { payload: { bedroomCount = [] } },
    ) => ({
      ...state,
      bedroomCount: Array.isArray(bedroomCount) ? bedroomCount : [bedroomCount],
    }),
  },
  initialState,
);
export { roomsFilter };
