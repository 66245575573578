/* eslint-disable max-statements */
import isEqual from 'lodash-es/isEqual';

import { Currency } from 'ha/constants/Currencies';

import { setRemarketingTag } from 'ha/modules/Analytics/actions';
import { Thunk } from 'ha/myredux/types';
import { Filters, SearchAreaValue } from 'ha/types/SearchFilters';
import { encodeSearchQuery } from 'ha/utils/encodeSearchQuery';
import { getSearchUrl } from 'ha/utils/filters/getSearchUrl';
import { FilterQueryParams } from 'ha/utils/filters/types';

import { getCurrentLanguageCode } from 'ha/modules/LanguageSwitcher/selectors';

import { Actions, PageType } from '../constants';
import {
  getAdvertiserRatingFilter,
  getBillsFilter,
  getCategoriesFilter,
  getCityCanonical,
  getCountryCode,
  getCurrentSorting,
  getDateFilter,
  getFurnitureFilter,
  getGenderFilter,
  getLocation,
  getMapLocation,
  getPriceFilterDefaultCurrency,
  getRecentlyAddedFilter,
  getRegistrationFilter,
  getRulesFilter,
  getSearchParams,
  getSuitableForFilter,
  getUseMapAsAFilter,
} from '../selectors';
import {
  getAmenitiesFilter,
  getContractTypeFilter,
  getCurrencyFilterValue,
  getFacilitiesFilter,
  getPriceFilterValue,
  getPropertySizeFilter,
  getRadiusFilter,
  getRoomsFilterValue,
} from '../selectors/filterSelectors';

interface FinishFilters {
  queryParams: FilterQueryParams;
  pathname?: string;
}

const changeFilters = (payload: FinishFilters) => {
  return {
    type: Actions.CHANGE_FILTERS,
    payload,
    meta: {
      pathname: payload.pathname,
      // we do not pass arrays since queryParams middleware creates a separate pair for each element,
      // e.g. { foo: [1,2] } => ?foo=1&foo=2, but for search page we want to keep the old behavior { foo: [1,2] } => ?foo=1,2
      // without affecting other pages, so transform arrays into strings here
      queryParams: Object.keys(payload.queryParams).reduce((acc, key) => {
        const value = payload.queryParams[key];

        if (Array.isArray(value)) {
          acc[key] = value.toString();
        } else {
          acc[key] = value;
        }

        return acc;
      }, {}),
      mergeQueryParams: true,
    },
    error: false,
  };
};

export const trackFiltersUpdate =
  ({ categories, startDate, endDate }: FilterQueryParams): Thunk =>
  (dispatch, getState) => {
    const state = getState();
    const cityCanonical = getCityCanonical(state);
    const countryCode = getCountryCode(state);
    const { categories: prevCategories } = getSearchParams(state);
    const { endDate: prevEndDate, startDate: prevStartDate } =
      getDateFilter(state);

    const categoriesChanged = !isEqual(prevCategories, categories);
    const datesChanges =
      (startDate !== undefined || endDate !== undefined) &&
      !(startDate === prevStartDate && endDate === prevEndDate);

    if (categoriesChanged || datesChanges) {
      setRemarketingTag(
        {
          hrental_pagetype: PageType,
          hrental_city: cityCanonical,
          hrental_country: countryCode,
          hrental_startdate: datesChanges ? startDate : prevStartDate,
          hrental_enddate: datesChanges ? endDate : prevEndDate,
          hrental_search_category: categoriesChanged
            ? categories
            : prevCategories,
        },
        { nonInteraction: 0 },
      );
    }
  };

const onChangeFilters =
  ({
    price,
    types,
    advRating,
    registration,
    rules,
    recentlyAdded,
    furniture,
    bills,
    rooms,
    dates,
    gender,
    suitableFor,
    contractType,
    sorting,
    searchArea,
    propertySize,
    facilities,
    amenities,
    radius,
    place,
    currency,
  }: Filters): Thunk =>
  async (dispatch, getState, services) => {
    const state = getState();

    const datesFilter = getDateFilter(state);
    const priceFilter = getPriceFilterValue(state);
    const currencyFilter = getCurrencyFilterValue(state);
    const useMapAsAFilter = getUseMapAsAFilter(state);
    const propertySizeFilter = getPropertySizeFilter(state);

    const queryParams: FilterQueryParams = {
      page: undefined,
      bedroomCount: getRoomsFilterValue(state).bedroomCount || [],
      categories: getCategoriesFilter(state),
      bills: getBillsFilter(state),
      advRating: getAdvertiserRatingFilter(state),
      furniture: getFurnitureFilter(state),
      registration: getRegistrationFilter(state),
      suitableFor: getSuitableForFilter(state),
      rules: getRulesFilter(state),
      gender: getGenderFilter(state),
      recentlyAdded: getRecentlyAddedFilter(state),
      sorting: getCurrentSorting(state),
      contractType: getContractTypeFilter(state),
      facilities: getFacilitiesFilter(state),
      amenities: getAmenitiesFilter(state),
      radius: getRadiusFilter(state),
      startDate: datesFilter.startDate,
      endDate: datesFilter.endDate,
      flexDays: datesFilter.flexDays,
      priceMin: priceFilter.min,
      priceMax: priceFilter.max,
      currency: currencyFilter,
      searchArea: useMapAsAFilter ? SearchAreaValue.MapBounds : undefined,
      sizeMin: propertySizeFilter.sizeMin,
      sizeMax: propertySizeFilter.sizeMax,
    };

    if (types) {
      queryParams.categories = types;
    }

    if (advRating) {
      queryParams.advRating = advRating;
    }

    if (furniture !== undefined) {
      queryParams.furniture = furniture;
    }

    if (bills !== undefined) {
      queryParams.bills = bills;
    }

    if (registration !== undefined) {
      queryParams.registration = registration;
    }

    if (recentlyAdded !== undefined) {
      queryParams.recentlyAdded = recentlyAdded;
    }

    if (suitableFor) {
      queryParams.suitableFor = suitableFor;
    }

    if (rules) {
      queryParams.rules = rules;
    }

    if (gender) {
      queryParams.gender = gender;
    }

    if (contractType) {
      queryParams.contractType = contractType;
    }

    if (sorting) {
      queryParams.sorting = sorting;
    }

    if (rooms) {
      queryParams.bedroomCount = rooms.bedroomCount;
    }

    if (price) {
      queryParams.priceMin = price.min;
      queryParams.priceMax = price.max;
    }

    if (currency) {
      queryParams.currency = currency;
    }

    if (dates) {
      queryParams.startDate = dates.startDate;
      queryParams.endDate = dates.endDate;
      queryParams.flexDays = dates.flexDays;
    }

    if (searchArea?.length) {
      queryParams.searchArea = searchArea.includes(SearchAreaValue.MapBounds)
        ? SearchAreaValue.MapBounds
        : undefined;
    }

    if (propertySize) {
      queryParams.sizeMin = propertySize.sizeMin;
      queryParams.sizeMax = propertySize.sizeMax;
    }

    if (facilities !== undefined) {
      queryParams.facilities = facilities;
    }

    if (amenities !== undefined) {
      queryParams.amenities = amenities;
    }

    if (radius !== undefined) {
      queryParams.radius = radius;
    }

    const lang = getCurrentLanguageCode(state);

    dispatch(trackFiltersUpdate(queryParams));

    const searchQuery = getLocation(state);

    let location = getMapLocation(state);
    let cityDefaultCurrency = getPriceFilterDefaultCurrency(state) || undefined;
    if (place && place !== searchQuery) {
      const { data: city } = await services.apiV2.searchCity({
        query: place,
        languages: [lang],
      });
      const { cityName, countryName } = city[lang];

      cityDefaultCurrency = city[lang].currency as Currency;
      location = encodeSearchQuery([cityName, countryName].join(', '));
    }

    const { pathname, queryParams: updatedQueryParams } = getSearchUrl({
      location,
      lang,
      queryParams,
      cityDefaultCurrency,
    });

    dispatch(
      changeFilters({
        pathname,
        queryParams: updatedQueryParams,
      }),
    );
  };

export { onChangeFilters };
