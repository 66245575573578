import React from 'react';

import { useSelector } from 'react-redux';

import { Button } from '@hbf/dsl/core';

import { useIntl } from 'ha/i18n';
import { getIsAuthenticated } from 'ha/modules/AuthLogic/selectors';
import { isEnabled, useFeatureFlags } from 'ha/modules/FeatureFlags';

import { SmartTrack as Track } from 'ha/components/track/SmartTrack';

import {
  getNumberOfListings,
  getCityCanonical,
} from '../../../Search/selectors';
import {
  isSearchAlertDisabled,
  hasSearchAlert,
} from '../../../Search/selectors/searchAlertSelectors';

import { SearchAlertContext } from './SearchControlsAlertContext';

interface Props {
  customEventProps?: Record<string, string>;
}

export const SearchControlsAlertButton = ({ customEventProps = {} }: Props) => {
  const { T } = useIntl();

  const isAuthenticated = useSelector(getIsAuthenticated);
  const { enableSearchAlertsWithoutAuth } = useFeatureFlags();

  const totalNumberOfListings = useSelector(getNumberOfListings);
  const cityCanonical = useSelector(getCityCanonical);
  // prevent the button from being clicked when alerts cannot be created
  const isDisabled = Boolean(useSelector(isSearchAlertDisabled));
  const searchAlertExists = useSelector(hasSearchAlert);

  const { setSearchAlertOpen } = SearchAlertContext.useContext();

  const getTextContent = () => {
    if (isEnabled(enableSearchAlertsWithoutAuth) && !isAuthenticated) {
      return T('unauthorised_alerts.cta');
    }

    return searchAlertExists
      ? T('search.alerts.modal.title.update_alert')
      : T('search.alerts.modal.title.create_alert');
  };

  return (
    <Track
      name="Clicked create search alert"
      type="button"
      getDynamicData={() => ({
        resultCount: totalNumberOfListings,
        listingcity: cityCanonical,
        ...customEventProps,
      })}
    >
      <Button
        onClick={() => setSearchAlertOpen(true)}
        variant="text"
        disabled={isDisabled}
        color="neutral"
        size="medium"
        data-test-locator="SearchControls/AlertButton"
      >
        {getTextContent()}
      </Button>
    </Track>
  );
};
