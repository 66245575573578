import { LocalState } from '../Search/SearchFiltersContext';

export type FilterRange = { from: number | undefined; to: number | undefined };

export const SEARCH_FILTERS_GROUP_PRICING = [
  'price',
  'bills',
  'currency',
] satisfies (keyof LocalState)[];

export const SEARCH_FILTERS_GROUP_PROPERTY_TYPE = [
  'types',
  'rooms',
] satisfies (keyof LocalState)[];

export const HIGHLIGHTED_FILTERS: (keyof LocalState)[] = [
  ...SEARCH_FILTERS_GROUP_PRICING,
  'types',
  'rooms',
  'furniture',
];

export const AVAILABLE_SEARCH_FILTERS: (keyof LocalState)[] = [
  ...SEARCH_FILTERS_GROUP_PRICING,
  ...SEARCH_FILTERS_GROUP_PROPERTY_TYPE,
  'gender',
  'propertySize',
  'furniture',
  'advRating',
  'facilities',
  'amenities',
  'suitableFor',
  'contractType',
  'registration',
];
