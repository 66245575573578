import React from 'react';

import { createRequiredContext } from 'ha/core/RequiredContext';
import { useIntl } from 'ha/i18n';
import {
  ExtendedComponent,
  ExtendProps,
} from 'ha/modules/Navigation/components/ExtendedComponent/types';
import { defineKey } from '@ha/intl';
import {
  Countries,
  isRegistrationRequiredCountry,
  CountriesWithRequiredRegistration,
} from '@ha/core/SupportedCountries';

const SearchFilterRegistrationSectionContext = createRequiredContext<{
  countryCode: string;
}>('SearchFilterRegistrationSectionContext');

const REQUIRED_REGISTRATION_DESCRIPTION_TRANSLATIONS = {
  [Countries.Austria]: defineKey('tenant.registration_info.austria'),
  [Countries.Belgium]: defineKey('tenant.registration_info.belgium'),
  [Countries.Switzerland]: defineKey('tenant.registration_info.switzerland'),
  [Countries.Germany]: defineKey('tenant.registration_info.germany'),
  [Countries.Iceland]: defineKey('tenant.registration_info.iceland'),
  [Countries.Italy]: defineKey('tenant.registration_info.italy'),
  [Countries.Netherlands]: defineKey('tenant.registration_info.netherlands'),
  [Countries.Portugal]: defineKey('tenant.registration_info.portugal'),
  [Countries.Spain]: defineKey('tenant.registration_info.spain'),
} satisfies Record<CountriesWithRequiredRegistration, string>;

const SearchFilterRegistrationSectionDescription = <
  CustomComponent extends ExtendedComponent,
>({
  component,
}: Partial<ExtendProps<CustomComponent>>) => {
  const { T } = useIntl();

  const { countryCode } = SearchFilterRegistrationSectionContext.useContext();

  const Component = (component ?? React.Fragment) as ExtendedComponent;

  return (
    <Component>
      {T(
        REQUIRED_REGISTRATION_DESCRIPTION_TRANSLATIONS[
          countryCode.toUpperCase()
        ],
      )}
    </Component>
  );
};

export const SearchFilterRegistrationSection = ({
  countryCode,
  children,
}: React.PropsWithChildren<{
  countryCode: string;
}>) => {
  // omit countries with known no registration policies
  if (!isRegistrationRequiredCountry(countryCode)) return null;

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <SearchFilterRegistrationSectionContext.Provider value={{ countryCode }}>
      {children}
    </SearchFilterRegistrationSectionContext.Provider>
  );
};

SearchFilterRegistrationSection.Description =
  SearchFilterRegistrationSectionDescription;
