import { createAction } from 'redux-actions';
import { Actions } from '../constants';
import { getCachedCurrencyEuroRates } from '../selectors';

const flow = {
  start: createAction(Actions.LOAD_CURRENCY_RATES_START),
  done: createAction(Actions.LOAD_CURRENCY_RATES_DONE),
  error: createAction(Actions.LOAD_CURRENCY_RATES_ERROR),
};

const loadCurrencyRates = () => (dispatch, getState, services) => {
  const state = getState();
  const previousRates = getCachedCurrencyEuroRates(state);

  // We need to fetch currency rates the first time.
  if (previousRates) {
    return Promise.resolve({ data: { rates: previousRates } });
  }

  dispatch(flow.start());

  return services.apiV2
    .getCurrencyEuroRates()
    .then(response => {
      dispatch(flow.done(response.data.rates));

      return response;
    })
    .catch(errors => {
      dispatch(flow.error(errors));

      return Promise.reject(errors);
    });
};

export { loadCurrencyRates };
