import { createAction } from 'redux-actions';
import { reportError } from 'ha/helpers/bugReporter/reportError';
import { Actions } from '../constants';

const flow = {
  start: createAction(Actions.LOAD_ADVERTISER_START),
  done: createAction(Actions.LOAD_ADVERTISER_DONE),
  error: createAction(Actions.LOAD_ADVERTISER_ERROR),
};

const loadAdvertiser =
  advertiserId =>
  (dispatch, getState, { apiV2 }) => {
    const requestId = Date.now();

    dispatch(flow.start(requestId));

    return apiV2
      .getUser(advertiserId)
      .then(response => {
        dispatch(flow.done(response.data));
      })
      .catch(error => {
        reportError('Failed to load advertiser', { metaData: { error } });
        dispatch(flow.error(error));
      });
  };

export { loadAdvertiser };
