import { reportError } from 'ha/helpers/bugReporter/reportError';

function reportUnsupportedBrowser({ cause }: { cause?: Error } = {}) {
  reportError(new Error('Cannot get system timezone'), {
    metaData: { cause },
  });
}

export function getTimezone() {
  try {
    if (typeof Intl !== 'object') {
      reportUnsupportedBrowser();

      return undefined;
    }

    if (!Intl.supportedValuesOf('timeZone')) {
      reportUnsupportedBrowser();

      return undefined;
    }

    return new Intl.DateTimeFormat().resolvedOptions().timeZone ?? undefined;
  } catch (error) {
    reportUnsupportedBrowser({ cause: error });

    return undefined;
  }
}

export const DefaultTimezone = 'Europe/Berlin';
