import { handleActions } from 'redux-actions';

import { Actions } from 'ha/pages/Search/constants';

const initialState = {
  previousParams: {},
  requestId: {
    search: null,
    universities: null,
    citiesNearby: null,
  },
  responses: {
    updateSearch: null,
    currencyEuroRates: null,
  },
};

const updateRequestId =
  key =>
  (state, { payload: requestId }) => ({
    ...state,
    requestId: {
      ...state.requestId,
      [key]: requestId,
    },
  });

const updateCachedResponse =
  key =>
  (state, { payload: data }) => ({
    ...state,
    responses: {
      ...state.responses,
      [key]: data,
    },
  });

const requestCache = handleActions(
  {
    [Actions.SET_PREVIOUS_PARAMS]: (state, { payload: previousParams }) => ({
      ...state,
      previousParams,
    }),

    [Actions.LOAD_START]: updateRequestId('search'),
    [Actions.LOAD_UNIVERSITIES_START]: updateRequestId('universities'),
    [Actions.LOAD_CITIES_NEARBY_START]: updateRequestId('citiesNearby'),

    [Actions.UPDATE_SEARCH_DONE]: updateCachedResponse('updateSearch'),
    [Actions.LOAD_CURRENCY_RATES_DONE]:
      updateCachedResponse('currencyEuroRates'),
  },
  initialState,
);
export { requestCache };
