// indexation rules so that we only index search pages with countries included in the sitemap
const countriesCanonical = {
  Austria: true,
  Belgium: true,
  Canada: true,
  Czechia: true,
  Croatia: true,
  Denmark: true,
  Finland: true,
  France: true,
  Germany: true,
  Greece: true,
  Hungary: true,
  Iceland: true,
  Ireland: true,
  Italy: true,
  Latvia: true,
  Netherlands: true,
  Norway: true,
  Poland: true,
  Portugal: true,
  Slovenia: true,
  Spain: true,
  Sweden: true,
  Switzerland: true,
  'United Kingdom': true,
  'United States': true,
};

const shouldCountryBeIndexed = (canonicalCountryName: string) =>
  canonicalCountryName in countriesCanonical;

export { shouldCountryBeIndexed };
