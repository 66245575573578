const WHITE_LISTED_PARAMS = [
  'page',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
];

export function checkSearchQueryEmpty(searchParams: URLSearchParams) {
  return (
    Array.from(searchParams.keys()).filter(
      // Remove whitelisted keys from the an array
      key => WHITE_LISTED_PARAMS.includes(key) === false,
    ).length === 0
  );
}
