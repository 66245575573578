import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { Filters } from 'ha/types/SearchFilters';

import { ReqParams } from './algoliaQueries/types';

dayjs.extend(utc);

const transformFiltersToSearchParamsSubset = ({
  price,
  dates,
  types,
  sorting,
  propertySize,
  ...rest
}: Filters): Partial<ReqParams> => ({
  ...rest,
  priceMin: price?.min ?? undefined,
  priceMax: price?.max ?? undefined,
  startDate: dates?.startDate ? dayjs.utc(dates.startDate).format() : undefined,
  endDate: dates?.endDate ? dayjs.utc(dates.endDate).format() : undefined,
  flexDays: dates?.flexDays ?? undefined,
  categories: types,
  sizeMin: propertySize?.sizeMin ?? undefined,
  sizeMax: propertySize?.sizeMax ?? undefined,
});

export { transformFiltersToSearchParamsSubset };
