import React from 'react';

import { useSearchParams } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';

import { Language } from '@ha/intl';

import { CanonicalCollections } from 'ha/constants/SearchPageCollections';

import { useTitle } from 'ha/helpers/hooks/useTitle';
import { useIntl } from 'ha/i18n';

import { CITY_STUDENT_ACCOMMODATION_SEO } from '../constants';

interface Props {
  canonicalCollection: string;
  cityName: string;
  countryName: string;
  countryCode: string;
  currency: string;
  priceMax: number;
  priceMin: number;
  priceAverage: number;
  advertiserName?: string;
  total: number;
  latitude: number;
  longitude: number;
}

const SearchMetaInternal: React.FC<Props> = ({
  cityName,
  countryName,
  countryCode,
  canonicalCollection,
  currency,
  priceMax: noCentsPriceMax,
  priceMin: noCentsPriceMin,
  priceAverage: noCentsPriceAverage,
  advertiserName,
  total,
  latitude,
  longitude,
}) => {
  const { T, lang } = useIntl();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');

  const priceMax = noCentsPriceMax / 100;
  const priceMin = noCentsPriceMin / 100;
  const priceAverage = Math.floor(noCentsPriceAverage / 100);

  const mainPageTitle = React.useMemo(() => {
    if (countryCode === 'gb') {
      return T('seo_search.uk.meta_title', cityName, countryName);
    }
    return T('seo_search_meta_title', cityName, countryName);
  }, [countryCode, T, cityName, countryName]);

  const title = React.useMemo(() => {
    return advertiserName
      ? T('seo_search_meta_title_advertiser_page', advertiserName, cityName)
      : {
          [CanonicalCollections.ApartmentForRent]: T(
            'seo_search_meta_title_apartment_for_rent',
            cityName,
            countryName,
          ),
          [CanonicalCollections.PrivateRoomForRent]: T(
            'seo_search_meta_title_private_room_for_rent',
            cityName,
            countryName,
          ),
          [CanonicalCollections.SharedRoomForRent]: T(
            'seo_search_meta_title_shared_room_for_rent',
            cityName,
            countryName,
          ),
          [CanonicalCollections.StudioForRent]: T(
            'seo_search_meta_title_studio_for_rent',
            cityName,
            countryName,
          ),
          [CanonicalCollections.CheapAccommodation]: T(
            'seo_search_meta_title_cheap_accommodation',
            cityName,
            countryName,
          ),
          [CanonicalCollections.FurnishedApartments]: T(
            'seo_search_meta_title_furnished_apartments',
            cityName,
            countryName,
          ),
          [CanonicalCollections.StudentAccommodation]:
            CITY_STUDENT_ACCOMMODATION_SEO.includes(cityName) &&
            lang === Language.en
              ? T('seo_search_meta_title_students', cityName, countryName)
              : T(
                  'seo_search_meta_title_student_accommodation',
                  cityName,
                  countryName,
                ),
          [CanonicalCollections.LongTermRentals]: T(
            'seo_search_meta_title_long_term_rentals',
            cityName,
            countryName,
          ),
          [CanonicalCollections.OneBedroomApartments]: T(
            'seo_search_meta_title_one_bedroom_apartments',
            cityName,
            countryName,
          ),
          [CanonicalCollections.TwoBedroomApartments]: T(
            'seo_search_meta_title_two_bedroom_apartments',
            cityName,
            countryName,
          ),
          [CanonicalCollections.StudentRoom]: T(
            'seo_search_meta_title_student_room',
            cityName,
            countryName,
          ),
          '': mainPageTitle,
        }[canonicalCollection];
  }, [
    T,
    advertiserName,
    canonicalCollection,
    cityName,
    countryName,
    lang,
    mainPageTitle,
  ]);

  const description = React.useMemo(() => {
    return advertiserName
      ? T(
          'seo_search_meta_description_advertiser_page',
          advertiserName,
          cityName,
          currency,
          priceMin,
        )
      : {
          [CanonicalCollections.ApartmentForRent]: T(
            'seo_search_meta_description_apartment_for_rent',
            cityName,
            currency,
            priceMin,
          ),
          [CanonicalCollections.PrivateRoomForRent]: T(
            'seo_search_meta_description_private_room_for_rent',
            cityName,
            currency,
            priceMin,
          ),
          [CanonicalCollections.SharedRoomForRent]: T(
            'seo_search_meta_description_shared_room_for_rent',
            cityName,
            currency,
            priceMin,
          ),
          [CanonicalCollections.StudioForRent]: T(
            'seo_search_meta_description_studio_for_rent',
            cityName,
          ),
          [CanonicalCollections.CheapAccommodation]: T(
            'seo_search_meta_description_cheap_accommodation',
            cityName,
            currency,
            priceMin,
          ),
          [CanonicalCollections.FurnishedApartments]: T(
            'seo_search_meta_description_furnished_apartments',
            cityName,
            currency,
            priceMin,
          ),
          [CanonicalCollections.StudentAccommodation]:
            cityName === 'Berlin' && lang === Language.en
              ? T(
                  'seo_search_meta_description_students',
                  cityName,
                  cityName,
                  cityName,
                )
              : T(
                  'seo_search_meta_description_student_accommodation',
                  cityName,
                  currency,
                  priceMin,
                ),
          [CanonicalCollections.LongTermRentals]: T(
            'seo_search_meta_description_long_term_rentals',
            cityName,
          ),
          [CanonicalCollections.OneBedroomApartments]: T(
            'seo_search_meta_description_one_bedroom_apartments',
            cityName,
            currency,
            priceMin,
          ),
          [CanonicalCollections.TwoBedroomApartments]: T(
            'seo_search_meta_description_two_bedroom_apartments',
            cityName,
            currency,
            priceMin,
          ),
          [CanonicalCollections.StudentRoom]: T(
            'seo_search_meta_description_student_room',
            cityName,
            currency,
            priceMin,
          ),
          '': T('seo_search_meta_description', cityName, currency, priceMin),
        }[canonicalCollection];
  }, [
    T,
    advertiserName,
    canonicalCollection,
    cityName,
    currency,
    lang,
    priceMin,
  ]);

  const genericDescription = React.useMemo(() => {
    const metaDescription: Record<string, string> = {
      // generic search page meta description

      '': T(
        'seo.generic.meta_description.greece_hungary_czech_republic',
        cityName,
        countryName,
        total,
        cityName,
      ),

      // category search page meta description

      [CanonicalCollections.ApartmentForRent]: T(
        'seo.category.meta_description.apartments.greece_hungary_czech_republic',
        {
          city: cityName,
          country: countryName,
        },
      ),
      [CanonicalCollections.FurnishedApartments]: T(
        'seo.category.meta_description.furnished_apartments.greece_hungary_czech_republic',
        {
          city: cityName,
          country: countryName,
        },
      ),
      [CanonicalCollections.LongTermRentals]: T(
        'seo.category.meta_description.long_term.greece_hungary_czech_republic',
        {
          city: cityName,
          country: countryName,
        },
      ),
      [CanonicalCollections.OneBedroomApartments]: T(
        'seo.category.meta_description.one_bedroom.greece_hungary_czech_republic',
        {
          city: cityName,
          country: countryName,
        },
      ),
      [CanonicalCollections.PrivateRoomForRent]: T(
        'seo.category.meta_description.private_room.greece_hungary_czech_republic',
        {
          city: cityName,
          country: countryName,
        },
      ),
      [CanonicalCollections.SharedRoomForRent]: T(
        'seo.category.meta_description.shared_rooms.greece_hungary_czech_republic',
        {
          city: cityName,
          country: countryName,
        },
      ),
      [CanonicalCollections.StudentAccommodation]: T(
        'seo.category.meta_description.student_acco.greece_hungary_czech_republic',
        {
          city: cityName,
          country: countryName,
        },
      ),
      [CanonicalCollections.StudentRoom]: T(
        'seo.category.meta_description.student_rooms.greece_hungary_czech_republic',
        {
          city: cityName,
          country: countryName,
        },
      ),
      [CanonicalCollections.StudioForRent]: T(
        'seo.category.meta_description.studios.greece_hungary_czech_republic',
        {
          city: cityName,
          country: countryName,
        },
      ),
      [CanonicalCollections.TwoBedroomApartments]: T(
        'seo.category.meta_description.two_bedroom.greece_hungary_czech_republic',
        {
          city: cityName,
          country: countryName,
        },
      ),
      [CanonicalCollections.CheapAccommodation]: T(
        'seo.category.meta_description.cheap_accommodation.greece_hungary_czech_republic',
        {
          city: cityName,
          country: countryName,
        },
      ),
    };

    return metaDescription[canonicalCollection];
  }, [T, cityName, countryName, canonicalCollection, total]);

  const getDescription = () => {
    if (!advertiserName) {
      return genericDescription;
    }

    return description;
  };

  // Removes any text that's not a digit
  const pageDigit = page ? page.replace(/\D/g, '') : '';
  const pageSuffix = pageDigit ? ` | ${T('Page %s', pageDigit)}` : '';

  const suffixedTitle = `${title}${pageSuffix}`;

  const isCheapAccommodation =
    canonicalCollection === CanonicalCollections.CheapAccommodation;

  const structuredData = {
    '@context': 'http://schema.org',
    '@type': ['Apartment', 'Product'],
    name: suffixedTitle,
    description: getDescription(),
    offers: {
      '@type': 'AggregateOffer',
      priceCurrency: currency,
      offerCount: total,
      highPrice: isCheapAccommodation ? priceAverage : priceMax,
      lowPrice: priceMin,
    },
    hasMap: `http://www.google.com/maps/place/${latitude},${longitude}`,
  };

  const htmlTitle = useTitle(suffixedTitle, { withSuffix: true });

  return (
    <Helmet>
      <title key="title">{htmlTitle}</title>
      <meta key="meta og:title" property="og:title" content={suffixedTitle} />

      <meta
        key="meta description"
        name="description"
        content={getDescription()}
      />
      <meta
        key="meta og:description"
        property="og:description"
        content={getDescription()}
      />

      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
  );
};

const SearchMeta = React.memo(SearchMetaInternal);
export { SearchMeta };
