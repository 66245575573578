import { handleActions } from 'redux-actions';
import { Actions } from '../constants';

const initialState = [];

const listings = handleActions(
  {
    [Actions.LOAD_DONE]: (_, { payload: { edges } }) => edges,
  },
  initialState,
);
export { listings };
