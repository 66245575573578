import { createAction } from 'redux-actions';

import get from 'lodash-es/get';
import last from 'lodash-es/last';
import mapValues from 'lodash-es/mapValues';

import { normalizeListing } from '@ha/algolia';

import { DEFAULT_CURRENCY } from 'ha/constants/Currencies';

import { reportError } from 'ha/helpers/bugReporter/reportError';
import { getFeatureFlags } from 'ha/modules/FeatureFlags';
import { getTranslatedCollection } from 'ha/utils/urls/getTranslatedCollection';

import {
  Actions,
  DefaultDistributionSteps,
  DefaultDistributionTrimPercentage,
} from '../constants';
import {
  getSearchRequestId,
  getCurrentIndex,
  getIsImperialSystem,
} from '../selectors';
import { getFilterBuilderContext } from '../selectors/algoliaSelectors';
import {
  getAlgoliaListingsQuery,
  getAlgoliaFacetQuery,
  reformatDistribution,
  getDistribution,
  getTrimmedDistribution,
  normalizeMinMaxPrices,
} from '../utils';

export const flow = {
  start: createAction(Actions.LOAD_START),
  done: createAction(Actions.LOAD_DONE),
  error: createAction(Actions.LOAD_ERROR),
};

const loadSearchResults = (
  reqParams,
  { localizedKind, priceAverage },
  cityInfo,
  currencyEuroRates,
) => {
  return (dispatch, getState, services) => {
    const requestId = Date.now();

    dispatch(flow.start(requestId));

    const state = getState();

    const { withDynamicMinimumPrice } = getFeatureFlags(state);

    // index chosen based on master variants and FFs inside the selector
    const indexName = getCurrentIndex(state);

    const isImperialSystem = getIsImperialSystem(state);

    return services.algolia
      .searchListings(
        [
          // Algolia - Main Query for fetching the listing and information for the listing results
          getAlgoliaListingsQuery(
            reqParams,
            cityInfo,
            currencyEuroRates,
            getFilterBuilderContext(state, 'listings', cityInfo),
            isImperialSystem,
            { withoutFacets: true },
          ),
          // Algolia - Fetch count of listing per price bracket for composing the price histogram
          getAlgoliaFacetQuery(
            withDynamicMinimumPrice === 'on' ? ['minPrice'] : ['priceEUR'],
            reqParams,
            cityInfo,
            currencyEuroRates,
            getFilterBuilderContext(state, 'priceFacet', cityInfo),
          ),
        ],
        indexName,
      )
      .then(response => {
        if (getSearchRequestId(state) !== requestId) {
          // Query is not the last one. Dismiss results.
          return;
        }

        /* eslint-disable prefer-const */
        let [listings, priceFacet] = response.results;

        // in the first load use either the user specified currency (from query params)
        //  or default to the country currency from /api/v2/geonames/search-city call.
        const currency =
          reqParams.currency || cityInfo.currency || DEFAULT_CURRENCY;
        const currencyRate = currencyEuroRates[currency];
        const currencyRates = currencyEuroRates;

        let min = get(priceFacet.facets_stats, ['priceEUR', 'min'], 0);
        let max = get(priceFacet.facets_stats, ['priceEUR', 'max'], 0);

        if (withDynamicMinimumPrice === 'on') {
          min = get(priceFacet.facets_stats, ['minPrice', 'min'], 0);
          max = get(priceFacet.facets_stats, ['minPrice', 'max'], 0);
        }

        const { minPrice, maxPrice } = normalizeMinMaxPrices(
          min,
          max,
          currencyRate,
        );

        const priceDistributionArg = {
          facet:
            (withDynamicMinimumPrice === 'on'
              ? priceFacet.facets?.minPrice
              : priceFacet.facets?.priceEUR) || [],
          min: minPrice,
          max: maxPrice,
          rate: currencyRate,
          steps: DefaultDistributionSteps,
          trimPercentage: DefaultDistributionTrimPercentage,
        };

        const priceDistribution = reformatDistribution(
          getDistribution(priceDistributionArg),
        );

        const priceMin = minPrice * 100;
        const priceMax = maxPrice * 100;

        const trimmedPriceDistribution = reformatDistribution(
          getTrimmedDistribution(priceDistributionArg),
        );
        const trimmedPriceMin = priceMin;
        const trimmedPriceMax = last(trimmedPriceDistribution).range * 100;

        const langURLListWithCollections = mapValues(
          cityInfo.langURLList,
          (url, lang) => {
            const translatedCollection = getTranslatedCollection(
              lang,
              localizedKind,
            );

            return `${url}${
              translatedCollection ? `/${translatedCollection}` : ''
            }`;
          },
        );

        dispatch(
          flow.done({
            ...cityInfo,
            langURLListWithCollections,
            currency,
            defaultCurrency: cityInfo.currency,
            edges: listings.hits.map(
              normalizeListing({
                currency,
                currencyRate,
              }),
            ),
            pageInfo: {
              offset: listings.page,
              total: listings.nbHits,
              pages: listings.nbPages,
              limit: listings.hitsPerPage,
              isExhaustive: Boolean(listings.exhaustiveNbHits),
            },
            listingsCount: listings.nbHits,
            currencyRates,
            priceMin,
            priceMax,
            priceAverage,
            priceDistribution,
            trimmedPriceMin,
            trimmedPriceMax,
            trimmedPriceDistribution,
            listingsSummary: {
              organic: get(listings, ['facets', 'isPartner', 'false'], 0),
              partner: get(listings, ['facets', 'isPartner', 'true'], 0),
              isExhaustive: get(listings, ['exhaustiveFacetsCount'], true),
            },
          }),
        );
      })
      .catch(error => {
        reportError('Load search results failed', { metaData: { error } });
        dispatch(flow.error());
      });
  };
};
export { loadSearchResults };
