import { TranslateFunc } from '@ha/intl';

export enum DefaultTextType {
  Update = 'Update',
  ShowResults = 'Show Results',
}

const filterUpdateButtonText = (
  T: TranslateFunc,
  defaultText: DefaultTextType,
  numberOfListings?: number,
) => {
  const defaultTextTranslation =
    defaultText === DefaultTextType.Update ? T('Update') : T('Show results');

  return numberOfListings
    ? T('Show %(number)d results', { number: numberOfListings })
    : defaultTextTranslation;
};

export { filterUpdateButtonText };
