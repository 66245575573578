interface Query {
  startDate: string | undefined;
  endDate: string | undefined;
}

function isValidDate(date: Date) {
  return date instanceof Date && !Number.isNaN(date.valueOf());
}

export function isValidDateQuery(query: Query | undefined) {
  try {
    if (!query) {
      return true;
    }

    const { startDate, endDate } = query;

    if (startDate === undefined || endDate === undefined) {
      return true;
    }

    return isValidDate(new Date(startDate)) && isValidDate(new Date(endDate));
  } catch (err) {
    return false;
  }
}
