import { handleActions } from 'redux-actions';
import { Actions } from '../constants';

const initialState = [];

const universities = handleActions(
  {
    [Actions.LOAD_UNIVERSITIES_DONE]: (_, { payload }) => payload,
  },
  initialState,
);
export { universities };
