import React from 'react';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';

import { Select } from '@hbf/dsl/core';
import { SortAscending } from '@hbf/icons/brand-regular';

import { useIntl } from 'ha/i18n';
import { useSorting } from 'ha/pages/Search/hooks/useSorting';
import { SortingValue } from 'ha/types/SearchFilters';

export const SearchControlsSort = () => {
  const { T } = useIntl();

  const { currentSorting, onChange, onOpen, options } = useSorting();

  const theme = useTheme();
  const isLargerThanSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Select
      size="sm"
      labelPrefix={<SortAscending />}
      displayEmpty
      value={currentSorting}
      showExpandIcon={false}
      renderValue={(value: SortingValue) => {
        const selectedOption = options.find(option => option.value === value);

        if (!isLargerThanSm) return T('search.sort.title.mobile.sort');

        return selectedOption
          ? selectedOption.key
          : T('search.sort.title.sort_by');
      }}
      onOpen={onOpen}
      onChange={e => onChange(e.target.value as SortingValue)}
    >
      {options.map(option => (
        <Select.Item key={option.value} value={option.value}>
          {option.key}
        </Select.Item>
      ))}
    </Select>
  );
};
