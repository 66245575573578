import React from 'react';

import { useSearchParams, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import debounce from 'debounce';
import isEqual from 'lodash-es/isEqual';
import pick from 'lodash-es/pick';

import { addMonths, normalizeDate } from '@ha/date';

import { usePrevious } from 'ha/helpers/hooks/usePrevious';
import { useIntl } from 'ha/i18n';
import { useTrackEvent } from 'ha/modules/Analytics/helpers/TrackEvent';
import {
  DateFilterValue,
  TypeFilterValue,
  TypeValue,
  RoomsFilterValue,
  PriceFilterValue,
  FurnitureFilterValue,
  BillsFilterValue,
  SuitableForFilterValue,
  GenderFilterValue,
  RulesFilterValue,
  RegistrationFilterValue,
  RecentlyAddedFilterValue,
  ContractTypeFilterValue,
  SearchAreaFilterValue,
  PropertySizeFilterValue,
  FacilitiesFilterValue,
  AmenitiesFilterValue,
  RadiusFilterValue,
  AvailabilityFilterValue,
  AdvertiserRatingFilterValue,
} from 'ha/types/SearchFilters';
import { RequiredAnalyticsFilters } from 'ha/utils/filters/types';

import { changeLocation } from 'ha/modules/URLLocationHandler';

import { onChangeFilters } from './actions';
import { useSearchCity } from './hooks/useSearchCity';
import {
  SearchFiltersContextValue,
  SearchFiltersContext,
  LocalState,
  EmptyState,
} from './SearchFiltersContext';
import {
  getFilterValues,
  getPropertySizeStats,
} from './selectors/filterSelectors';
import { getSearchQuery } from './selectors/mapSelectors';
import { getConvertedPriceFilterParams } from './selectors/priceSelectors';
import { saveLocalState } from './utils';

interface Props {
  children: React.ReactNode;
  instantaneousFiltering?: boolean;
}

export const SearchFiltersProvider: React.FC<
  React.PropsWithChildren<Props>
> = ({ children, instantaneousFiltering = true }) => {
  const dispatch = useDispatch();
  const trackEvent = useTrackEvent();

  const globalFilters = useSelector(getFilterValues);
  const searchQuery = useSelector(getSearchQuery);
  const [searchParams] = useSearchParams();
  const params = useParams();
  const { urlResolver } = useIntl();
  const getSearchedCity = useSearchCity();

  const initialLocalState: LocalState = React.useMemo(
    () => ({
      ...globalFilters,
      place: searchQuery,
    }),
    [globalFilters, searchQuery],
  );

  const [localState, setLocalState] =
    React.useState<LocalState>(initialLocalState);

  const getPriceParamsSelector = React.useMemo(
    () => getConvertedPriceFilterParams(localState.currency),
    [localState.currency],
  );

  const {
    currencyRates,
    priceMin,
    priceMax,
    trimmedPriceMin,
    trimmedPriceMax,
    trimmedPriceDistribution,
  } = useSelector(getPriceParamsSelector);

  const { maxValue: sizeMax, minValue: sizeMin } =
    useSelector(getPropertySizeStats);

  React.useEffect(() => {
    saveLocalState(localState);
  }, [localState]);

  const trackFilter = React.useCallback(
    (
      attributes: {
        filterType: keyof typeof RequiredAnalyticsFilters;
      } & Record<string, unknown>,
    ) => {
      // do not track separate filter setting if instant updates are enabled
      // they fire their own filter applied event already
      if (instantaneousFiltering) return;

      trackEvent('Search filter clicked', {
        ...attributes,
      });
    },
    [instantaneousFiltering, trackEvent],
  );

  const onChangeDates = React.useCallback((dates: DateFilterValue) => {
    setLocalState(prevState => {
      return {
        ...prevState,
        dates,
      };
    });
  }, []);

  const onChangeAvailability = React.useCallback(
    (availability: AvailabilityFilterValue) => {
      const startDate = normalizeDate(
        new Date(availability.moveIn.value).toISOString(),
      );
      const endDate = availability.duration
        ? normalizeDate(addMonths(startDate, availability.duration))
        : null;

      onChangeDates({ startDate, endDate });
    },
    [onChangeDates],
  );

  const onChangeTypes = React.useCallback(
    (types: TypeFilterValue) => {
      setLocalState(prevState => ({
        ...prevState,
        types,
        // reset rooms if apartment type is not selected
        rooms:
          prevState.types.includes(TypeValue.APARTMENT) &&
          !types.includes(TypeValue.APARTMENT)
            ? { ...prevState.rooms, bedroomCount: [] }
            : prevState.rooms,
      }));

      trackFilter({
        filterType: 'categories',
        propertyType: types,
      });
    },
    [trackFilter],
  );

  const onChangeAdvertiserRatings = React.useCallback(
    (advRating: AdvertiserRatingFilterValue) => {
      setLocalState(prevState => ({
        ...prevState,
        advRating,
      }));

      trackFilter({ filterType: 'advRating', advRating });
    },
    [trackFilter],
  );

  const onChangeRooms = React.useCallback(
    (rooms: RoomsFilterValue) => {
      setLocalState(prevState => ({
        ...prevState,
        rooms: {
          ...prevState.rooms,
          ...rooms,
        },
        // add apartment type if it wasn't selected before
        types: prevState.types.includes(TypeValue.APARTMENT)
          ? prevState.types
          : [...prevState.types, TypeValue.APARTMENT],
      }));

      trackFilter({
        filterType: 'rooms',
        rooms: { bedroomCount: rooms },
      });
    },
    [trackFilter],
  );

  // handle currency change separately to correctly handle rate conversion for prices
  const onChangePrice = React.useCallback(
    (price: PriceFilterValue) => {
      setLocalState(prevState => ({
        ...prevState,
        price,
      }));

      const currencyRate = currencyRates[localState.currency];
      const normalizePrice = (amount: number) =>
        Math.round(amount / 100 / currencyRate);

      if (price.min !== null) {
        trackFilter({
          filterType: 'priceMin',
          priceMin: normalizePrice(price.min),
        });
      }
      if (price.max !== null) {
        trackFilter({
          filterType: 'priceMax',
          priceMax: normalizePrice(price.max),
        });
      }
    },
    [currencyRates, localState.currency, trackFilter],
  );

  const onChangeCurrency = React.useCallback(
    (currency: string) => {
      setLocalState(prevState => {
        const {
          price: { min, max },
          currency: prevCurrency,
        } = prevState;

        const rate = currencyRates[currency] / currencyRates[prevCurrency];

        return {
          ...prevState,
          currency,
          price: {
            min: min === null ? null : Math.floor(min * rate),
            max: max === null ? null : Math.floor(max * rate),
          },
        };
      });
    },
    [currencyRates],
  );

  const onChangePropertySize = React.useCallback(
    (propertySize: PropertySizeFilterValue) => {
      setLocalState(prevState => ({
        ...prevState,
        propertySize,
      }));

      if (propertySize.sizeMin !== undefined) {
        trackFilter({ filterType: 'sizeMin', sizeMin: propertySize.sizeMin });
      }
      if (propertySize.sizeMax !== undefined) {
        trackFilter({ filterType: 'sizeMax', sizeMax: propertySize.sizeMax });
      }
    },
    [trackFilter],
  );

  const onChangeFurniture = React.useCallback(
    (furniture: FurnitureFilterValue) => {
      setLocalState(prevState => ({
        ...prevState,
        furniture,
      }));

      trackFilter({ filterType: 'furniture', furniture });
    },
    [trackFilter],
  );

  const onChangeBills = React.useCallback(
    (bills: BillsFilterValue) => {
      setLocalState(prevState => ({
        ...prevState,
        bills,
      }));

      trackFilter({ filterType: 'bills', bills });
    },
    [trackFilter],
  );

  const onChangeSuitableFor = React.useCallback(
    (suitableFor: SuitableForFilterValue) => {
      setLocalState(prevState => ({
        ...prevState,
        suitableFor,
      }));

      trackFilter({ filterType: 'suitableFor', suitableFor });
    },
    [trackFilter],
  );

  const onChangeGender = React.useCallback(
    (gender: GenderFilterValue) => {
      setLocalState(prevState => ({
        ...prevState,
        gender,
      }));

      trackFilter({ filterType: 'gender', gender });
    },
    [trackFilter],
  );

  const onChangeRules = React.useCallback((rules: RulesFilterValue) => {
    setLocalState(prevState => ({
      ...prevState,
      rules,
    }));
  }, []);

  const onChangeRegistration = React.useCallback(
    (registration: RegistrationFilterValue) => {
      setLocalState(prevState => ({
        ...prevState,
        registration,
      }));

      trackFilter({ filterType: 'registration', registration });
    },
    [trackFilter],
  );

  const onChangeRecentlyAdded = React.useCallback(
    (recentlyAdded: RecentlyAddedFilterValue) => {
      setLocalState(prevState => ({
        ...prevState,
        recentlyAdded,
      }));
    },
    [],
  );

  const onChangeSearchAreaFilter = React.useCallback(
    (searchArea: SearchAreaFilterValue) => {
      setLocalState(prevState => ({
        ...prevState,
        searchArea,
      }));
    },
    [],
  );

  const onChangeRadius = React.useCallback((radius: RadiusFilterValue) => {
    setLocalState(prevState => ({
      ...prevState,
      radius,
    }));
  }, []);

  const getChangedPlaceQueryString = React.useCallback(() => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete('page');
    newSearchParams.delete('userId');
    newSearchParams.delete('searchArea');
    newSearchParams.delete('lLng');
    newSearchParams.delete('rLng');
    newSearchParams.delete('tLat');
    newSearchParams.delete('bLat');

    const queryString = newSearchParams.toString();
    return queryString === '' ? '' : `?${queryString}`;
  }, [searchParams]);

  const getChangedPlaceSearchUrl = React.useCallback(
    (cityName: string, countryName: string) => {
      const queryString = getChangedPlaceQueryString();

      return `${urlResolver.getSearchUrl(
        cityName,
        countryName,
        undefined,
        params.localizedKind,
      )}${queryString}`;
    },
    [getChangedPlaceQueryString, params.localizedKind, urlResolver],
  );

  const onChangePlace = React.useCallback(
    async (place: string) => {
      const { cityName, countryName } = (await getSearchedCity(place)) ?? {};
      if (!cityName || !countryName) return;

      const searchUrl = getChangedPlaceSearchUrl(cityName, countryName);
      dispatch(changeLocation(searchUrl));
    },
    [dispatch, getChangedPlaceSearchUrl, getSearchedCity],
  );

  const onChangeContractType = React.useCallback(
    (contractType: ContractTypeFilterValue) => {
      setLocalState(prevState => ({
        ...prevState,
        contractType,
      }));

      trackFilter({ filterType: 'contractType', contractType });
    },
    [trackFilter],
  );

  const onChangeFacilities = React.useCallback(
    (facilities: FacilitiesFilterValue) => {
      setLocalState(prevState => ({
        ...prevState,
        facilities,
      }));

      trackFilter({ filterType: 'facilities', facilities });
    },
    [trackFilter],
  );

  const onChangeAmenities = React.useCallback(
    (amenities: AmenitiesFilterValue) => {
      setLocalState(prevState => ({
        ...prevState,
        amenities,
      }));

      trackFilter({ filterType: 'amenities', amenities });
    },
    [trackFilter],
  );

  const clearFilters = React.useCallback(
    (filtersToKeep: Array<keyof LocalState> = []) => {
      setLocalState(prevState => {
        const filtersToRemove: Array<keyof LocalState> = [
          'place',
          'searchArea',
          ...filtersToKeep,
        ];

        return {
          ...EmptyState,
          // some filters should not be cleared
          ...pick(prevState, filtersToRemove),
        };
      });
    },
    [],
  );

  const isFilterEmpty = React.useCallback(
    (filterName: keyof LocalState) =>
      isEqual(localState[filterName], EmptyState[filterName]),
    [localState],
  );

  const snapshotState = React.useRef(localState);

  const revertFilters = React.useCallback(
    (revertKeys: (keyof LocalState)[]) => {
      const currentSnapshotState = snapshotState.current;

      setLocalState(prevState => {
        const next = {
          ...prevState,
          ...Object.fromEntries(
            Object.entries(currentSnapshotState).filter(([key]) =>
              revertKeys.includes(key as keyof LocalState),
            ),
          ),
        };

        snapshotState.current = next;

        return next;
      });
    },
    [],
  );

  const applyFilters = React.useCallback(
    (newState?: LocalState) => {
      const filterState = newState || localState;
      snapshotState.current = filterState;

      dispatch(
        onChangeFilters({
          ...filterState,
          // we operate with trimmed limits on filter level, untrim the values when applying to global state
          price: {
            min:
              filterState.price.min === trimmedPriceMin
                ? priceMin
                : filterState.price.min,
            max:
              filterState.price.max === trimmedPriceMax
                ? priceMax
                : filterState.price.max,
          },
        }),
      );
    },
    [
      localState,
      dispatch,
      trimmedPriceMin,
      priceMin,
      trimmedPriceMax,
      priceMax,
    ],
  );

  const prevLocalState = usePrevious(localState);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const applyFiltersDebounced = React.useCallback(
    debounce(applyFilters, 200),
    [],
  );

  React.useEffect(() => {
    // skip effect on first render or state didn't change
    if (
      !instantaneousFiltering ||
      prevLocalState === undefined ||
      prevLocalState === localState
    ) {
      return undefined;
    }
    applyFiltersDebounced(localState);

    return () => {
      applyFiltersDebounced.clear();
    };
  }, [
    instantaneousFiltering,
    localState,
    prevLocalState,
    applyFiltersDebounced,
  ]);

  const value = React.useMemo<SearchFiltersContextValue>(
    () => ({
      localState,
      priceParams: {
        priceMin: trimmedPriceMin,
        priceMax: trimmedPriceMax,
        priceDistribution: trimmedPriceDistribution,
      },
      sizeParams: {
        sizeMin,
        sizeMax,
      },
      onChangeDates,
      onChangeAvailability,
      onChangeTypes,
      onChangeAdvertiserRatings,
      onChangeRooms,
      onChangePrice,
      onChangeCurrency,
      onChangeFurniture,
      onChangeBills,
      onChangeSuitableFor,
      onChangeGender,
      onChangeRules,
      onChangeRegistration,
      onChangeRecentlyAdded,
      onChangeSearchAreaFilter,
      onChangeRadius,
      onChangePlace,
      onChangeContractType,
      onChangePropertySize,
      onChangeFacilities,
      onChangeAmenities,
      clearFilters,
      isFilterEmpty,
      applyFilters,
      revertFilters,
    }),
    [
      localState,
      trimmedPriceMin,
      trimmedPriceMax,
      trimmedPriceDistribution,
      sizeMin,
      sizeMax,
      onChangeDates,
      onChangeAvailability,
      onChangeTypes,
      onChangeAdvertiserRatings,
      onChangeRooms,
      onChangePrice,
      onChangeCurrency,
      onChangeFurniture,
      onChangeBills,
      onChangeSuitableFor,
      onChangeGender,
      onChangeRules,
      onChangeRegistration,
      onChangeRecentlyAdded,
      onChangeSearchAreaFilter,
      onChangeRadius,
      onChangePlace,
      onChangeContractType,
      onChangePropertySize,
      onChangeFacilities,
      onChangeAmenities,
      clearFilters,
      isFilterEmpty,
      applyFilters,
      revertFilters,
    ],
  );

  return (
    <SearchFiltersContext.Provider value={value}>
      {children}
    </SearchFiltersContext.Provider>
  );
};
