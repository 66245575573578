import { createSelector } from 'reselect';
import { getPagerData } from 'ha/utils/getPagerData';
import { getMediaQueryBreakpoints } from 'ha/modules/UI/selectors';
import { getPageInfo } from './getPageInfo';

const getPagerProps = createSelector(
  [getPageInfo, getMediaQueryBreakpoints],
  (pageInfo, { xs }) => ({
    pager: {
      total: pageInfo.total,
      offset: pageInfo.offset,
      quantity: pageInfo.limit,
    },
    pagerData: getPagerData(
      pageInfo.total,
      pageInfo.offset,
      pageInfo.limit,
      xs ? 6 : 9,
    ),
    withHeadLinks: true,
  }),
);

export { getPagerProps };
