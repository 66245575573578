import React from 'react';
import omit from 'lodash-es/omit';
import { Language } from '@ha/intl';
import { useIntl } from 'ha/i18n';
import { Link } from '@hbf/dsl/legacy';
import * as SearchFilters from 'ha/types/SearchFilters';

import {
  CanonicalCollections,
  LocaleToCanonicalCollections,
} from 'ha/constants/SearchPageCollections';

interface Category {
  localizedSlug: string;
  localizedText: string;
}

interface Categories {
  [key: string]: Category;
}

interface Props {
  place: string;
  canonicalCollection: SearchFilters.CanonicalCollection;
  langUrlList: { [key in Language]: string };
  isSearchByPartner: boolean;
}

const SearchCategories: React.FC<Props> = ({
  place,
  langUrlList,
  isSearchByPartner,
  canonicalCollection,
}) => {
  const { T, lang } = useIntl();

  const categories: Categories = {
    [CanonicalCollections.ApartmentForRent]: {
      localizedSlug:
        LocaleToCanonicalCollections[lang][
          CanonicalCollections.ApartmentForRent
        ],
      localizedText: T('seo_search_apartment_for_rent_link_label'),
    },
    [CanonicalCollections.PrivateRoomForRent]: {
      localizedSlug:
        LocaleToCanonicalCollections[lang][
          CanonicalCollections.PrivateRoomForRent
        ],
      localizedText: T('seo_search_private_room_for_rent_link_label'),
    },
    [CanonicalCollections.SharedRoomForRent]: {
      localizedSlug:
        LocaleToCanonicalCollections[lang][
          CanonicalCollections.SharedRoomForRent
        ],
      localizedText: T('seo_search_shared_room_for_rent_link_label'),
    },
    [CanonicalCollections.StudioForRent]: {
      localizedSlug:
        LocaleToCanonicalCollections[lang][CanonicalCollections.StudioForRent],
      localizedText: T('seo_search_studio_for_rent_link_label'),
    },
    [CanonicalCollections.CheapAccommodation]: {
      localizedSlug:
        LocaleToCanonicalCollections[lang][
          CanonicalCollections.CheapAccommodation
        ],
      localizedText: T('seo_search_cheap_accommodation_link_label'),
    },
    [CanonicalCollections.FurnishedApartments]: {
      localizedSlug:
        LocaleToCanonicalCollections[lang][
          CanonicalCollections.FurnishedApartments
        ],
      localizedText: T('seo_search_furnished_apartments_link_label'),
    },
    [CanonicalCollections.StudentAccommodation]: {
      localizedSlug:
        LocaleToCanonicalCollections[lang][
          CanonicalCollections.StudentAccommodation
        ],
      localizedText: T('seo_search_student_accommodation_link_label'),
    },
    [CanonicalCollections.LongTermRentals]: {
      localizedSlug:
        LocaleToCanonicalCollections[lang][
          CanonicalCollections.LongTermRentals
        ],
      localizedText: T('seo_search_long_term_rentals_link_label'),
    },
    [CanonicalCollections.OneBedroomApartments]: {
      localizedSlug:
        LocaleToCanonicalCollections[lang][
          CanonicalCollections.OneBedroomApartments
        ],
      localizedText: T('seo_search_one_bedroom_apartments_link_label'),
    },
    [CanonicalCollections.TwoBedroomApartments]: {
      localizedSlug:
        LocaleToCanonicalCollections[lang][
          CanonicalCollections.TwoBedroomApartments
        ],
      localizedText: T('seo_search_two_bedroom_apartments_link_label'),
    },
    [CanonicalCollections.StudentRoom]: {
      localizedSlug:
        LocaleToCanonicalCollections[lang][CanonicalCollections.StudentRoom],
      localizedText: T('seo_search_student_room_link_label'),
    },
  };

  const categoryList: Category[] = Object.values(
    omit(categories, [canonicalCollection]),
  );

  return (
    <div>
      {isSearchByPartner
        ? T('Property types for rent near %(place)s:', { place })
        : T('Property types for rent in %(place)s:', { place })}{' '}
      {categoryList.map(({ localizedSlug, localizedText }, index) => (
        <React.Fragment key={localizedSlug}>
          <Link
            to={`${langUrlList[lang]}/${localizedSlug}`}
            kind="mint-underline"
            data-test-locator={`category-${localizedSlug}`}
            title={
              isSearchByPartner
                ? T('%(type)s for rent near %(place)s:', {
                    type: localizedText,
                    place,
                  })
                : T('%(type)s for rent in %(place)s:', {
                    type: localizedText,
                    place,
                  })
            }
          >
            {localizedText}
          </Link>

          {index + 1 < categoryList.length ? ', ' : null}
        </React.Fragment>
      ))}
    </div>
  );
};

export { SearchCategories };
