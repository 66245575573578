import React from 'react';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { useSelector } from 'react-redux';

import { Chip } from '@hbf/dsl/core';
import { Notifications } from '@hbf/icons/brand-regular';

import { useIntl } from 'ha/i18n';
import { getIsAuthenticated } from 'ha/modules/AuthLogic/selectors';
import { isEnabled, useFeatureFlags } from 'ha/modules/FeatureFlags';

import { SmartTrack as Track } from 'ha/components/track/SmartTrack';

import {
  getNumberOfListings,
  getCityCanonical,
} from '../../../Search/selectors';
import {
  isSearchAlertDisabled,
  hasSearchAlert,
} from '../../../Search/selectors/searchAlertSelectors';

import { SearchAlertContext } from './SearchControlsAlertContext';

export const SearchControlsAlertChip = () => {
  const { T } = useIntl();

  const isAuthenticated = useSelector(getIsAuthenticated);
  const { enableSearchAlertsWithoutAuth } = useFeatureFlags();

  const theme = useTheme();
  const isLargerThanSm = useMediaQuery(theme.breakpoints.up('sm'));

  const totalNumberOfListings = useSelector(getNumberOfListings);
  const cityCanonical = useSelector(getCityCanonical);
  // prevent the chip from being clicked when alerts cannot be created
  const isDisabled = Boolean(useSelector(isSearchAlertDisabled));
  const searchAlertExists = useSelector(hasSearchAlert);

  const { setSearchAlertOpen } = SearchAlertContext.useContext();

  const getTextContent = () => {
    if (isEnabled(enableSearchAlertsWithoutAuth) && !isAuthenticated) {
      return T('unauthorised_alerts.cta');
    }

    if (isLargerThanSm) {
      return searchAlertExists
        ? T('search.alerts.modal.title.update_alert')
        : T('search.alerts.modal.title.create_alert');
    }

    return searchAlertExists
      ? T('cta.update')
      : T('search.alerts.modal.title.mobile.alert');
  };

  return (
    <Track
      name="Clicked create search alert"
      type="button"
      getDynamicData={() => ({
        resultCount: totalNumberOfListings,
        listingcity: cityCanonical,
        originated: 'Search filters',
      })}
    >
      <Chip
        variant="contained"
        color="secondary"
        size="lg"
        disabled={isDisabled}
        onClick={() => setSearchAlertOpen(true)}
        startIcon={<Notifications />}
        data-test-locator="SearchControls/AlertButton"
      >
        {getTextContent()}
      </Chip>
    </Track>
  );
};
