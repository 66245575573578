import { NormalizedListing } from '@ha/algolia';
import { ListingCancellationPolicy } from '@ha/api/v2/types/Listing';

import { Currency } from 'ha/constants/Currencies';

import { isAlgoliaListingFurnished } from '../ListingFacilities/isListingFurnished';

import { NormalizedAlgoliaListing } from './types';

type RequiredAlgoliaListingParams = Pick<
  NormalizedListing,
  | 'id'
  | 'advertiserId'
  | 'isPartner'
  | 'price'
  | 'currency'
  | 'street'
  | 'city'
  | 'country'
  | 'isNew'
  | 'propertyType'
  | 'previewImage'
  | 'photos'
  | 'photoCount'
  | 'floorPlanCount'
  | 'videoCount'
  | 'minimalRentalPeriod'
  | 'maximumStay'
  | 'listingPath'
  | 'exclusions'
  | 'currentOccupancy'
  | 'freePlaces'
  | 'cPolicy'
  | 'utilities'
  | 'facility_bedroom_furnished'
  | 'facility_bed'
  | 'facility_bedroom_count'
  | 'facility_total_size'
  | 'facility_bedroom_size'
  | 'facility_registration_possible'
  | 'facility_student_housing'
  | 'facility_co_living'
  | 'minPrice'
  | 'maxPrice'
  | 'priceType'
  | 'isMultiUnit'
  | 'unitTypePath'
  | 'unitTypeInternalID'
  | 'rentalPeriodType'
  | 'advertiserFirstName'
  | 'advertiserMoveInOverallRating'
  | 'advertiserMoveInReviewCount'
  | 'isExcellentAdvertiser'
  | 'pricingValues'
  | 'pricingValuesEUR'
  | 'landlordType'
  | 'has3DTour'
  | 'photo360Count'
  | 'rank_withOrpheus'
  | 'rank_withOrpheusExperiment'
>;

export function normalizeAlgoliaListing(
  listing: RequiredAlgoliaListingParams,
): NormalizedAlgoliaListing {
  return {
    id: listing.id,
    advertiserId: listing.advertiserId,
    isPartner: listing.isPartner,
    price: listing.price,
    currency: listing.currency as Currency,
    street: listing.street,
    city: listing.city,
    country: listing.country,
    isNew: listing.isNew,
    cPolicy: listing.cPolicy as ListingCancellationPolicy,
    previewImage: listing.previewImage,
    photos: listing.photos,
    photoCount: listing.photoCount,
    floorPlanCount: listing.floorPlanCount ?? 0,
    videoCount: listing.videoCount ?? 0,
    minimalRentalPeriod: listing.minimalRentalPeriod,
    maximumStay: listing.maximumStay,
    listingPath: listing.listingPath,
    propertyType: listing.propertyType,
    currentOccupancy: listing.currentOccupancy,
    freePlaces: listing.freePlaces,
    exclusions: listing.exclusions,
    bedroomFurnished: isAlgoliaListingFurnished(listing) ? 'yes' : 'no',
    bedroomCount: listing.facility_bedroom_count,
    totalSize: listing.facility_total_size,
    bedroomSize: listing.facility_bedroom_size,
    studentHousing: listing.facility_student_housing,
    coLiving: listing.facility_co_living,
    registrationPossible: listing.facility_registration_possible,
    utilities: listing.utilities,
    minPrice: listing.minPrice,
    maxPrice: listing.maxPrice,
    priceType: listing.priceType,
    isMultiUnit: listing.isMultiUnit ?? false,
    unitTypePath: listing.unitTypePath ?? '',
    unitTypeId: listing.unitTypeInternalID,
    rentalPeriodType: listing.rentalPeriodType,
    advertiserFirstName: listing.advertiserFirstName,
    advertiserMoveInOverallRating: listing.advertiserMoveInOverallRating,
    advertiserMoveInReviewCount: listing.advertiserMoveInReviewCount,
    isExcellentAdvertiser: listing.isExcellentAdvertiser,
    pricingValues: listing.pricingValues,
    pricingValuesEUR: listing.pricingValuesEUR,
    landlordType: listing.landlordType,
    has3DTour: listing.has3DTour,
    photo360Count: listing.photo360Count,
    rankWithOrpheus: listing.rank_withOrpheus,
    rankWithOrpheusExperiment: listing.rank_withOrpheusExperiment,
  };
}
