import React from 'react';

import { useSearchParams } from 'react-router-dom';

import { useMediaQuery, useTheme } from '@mui/material';

import { createRequiredContext } from 'ha/core/RequiredContext';

export const SearchFiltersDrawerContextInternal = createRequiredContext<{
  isOpen: boolean;
  onClose: () => void;
}>('SearchFiltersDrawerContextInternal');

export const SearchFiltersDrawerContext = createRequiredContext<{
  setDrawerOpen: (value: boolean) => void;
}>('SearchFiltersDrawerContext');

export const SearchFiltersDrawerProvider = ({
  children = null,
}: React.PropsWithChildren<unknown>) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const theme = useTheme();
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  const [isOpen, setIsOpen] = React.useState(
    !isLargerThanMd && searchParams.get('updateSearchAlert') === 'true',
  );

  const onClose = React.useCallback(() => setIsOpen(false), [setIsOpen]);

  const setDrawerOpen = React.useCallback((value: boolean) => {
    setIsOpen(value);
  }, []);

  React.useEffect(() => {
    if (!isOpen && searchParams.get('updateSearchAlert')) {
      setSearchParams(previous => {
        previous.delete('updateSearchAlert');

        return previous;
      });
    }
  }, [isOpen, searchParams, setSearchParams]);

  const internalValue = React.useMemo(
    () => ({ isOpen, onClose }),
    [isOpen, onClose],
  );

  const value = React.useMemo(() => ({ setDrawerOpen }), [setDrawerOpen]);

  return (
    <SearchFiltersDrawerContext.Provider value={value}>
      <SearchFiltersDrawerContextInternal.Provider value={internalValue}>
        {children}
      </SearchFiltersDrawerContextInternal.Provider>
    </SearchFiltersDrawerContext.Provider>
  );
};
