import { createAction } from '@housinganywhere/safe-redux';
import { Thunk } from 'ha/myredux/types';
import { GlobalState } from 'ha/types/store';
import { getFromIndexedDB, setInIndexedDB } from 'ha/utils/indexedDb';

import { Actions, STORAGE_KEY } from '../constants';
import { getMapVisitedRooms, getLocation } from '../selectors';
import { VisitedRooms } from '../reducer/types';

const actions = {
  setVisitedRooms: (visitedRooms: VisitedRooms) =>
    createAction(Actions.SET_VISITED_ROOMS, { visitedRooms }),
};

export const initVisitedRooms =
  (): Thunk<GlobalState> => (dispatch, getState) => {
    const state = getState();
    const city = getLocation(state);

    getFromIndexedDB(`${STORAGE_KEY}_${city}`)
      .then((visitedRooms: VisitedRooms = {}) => {
        dispatch(actions.setVisitedRooms(visitedRooms));
      })
      .catch(() => dispatch(actions.setVisitedRooms({})));
  };

export const setVisitedRoom =
  (roomId: number): Thunk<GlobalState> =>
  (dispatch, getState) => {
    const state = getState();
    const city = getLocation(state);

    const visitedRooms = getMapVisitedRooms(state);

    if (visitedRooms[roomId]) {
      return;
    }

    const newVisitedRooms = { ...visitedRooms, [roomId]: true };

    dispatch(actions.setVisitedRooms(newVisitedRooms));
    setInIndexedDB(`${STORAGE_KEY}_${city}`, newVisitedRooms);
  };
