import { handleActions } from 'redux-actions';
import { Actions } from '../constants';

const initialState = {
  startDate: null,
  endDate: null,
  flexDays: undefined,

  // https://housinganywhere.atlassian.net/browse/TX-1663
  withRelevantAvailability: true,
};

const dateFilter = handleActions(
  {
    [Actions.UPDATE_SEARCH_PARAMS]: (
      state,
      { payload: { startDate, endDate, flexDays } },
    ) => ({
      ...state,
      startDate,
      endDate,
      flexDays,

      // reset the withRelevantAvailability when is disabled and the startDate is modified
      withRelevantAvailability:
        state.withRelevantAvailability || state.startDate !== startDate,
    }),
    [Actions.DISABLE_RESULTS_WITH_RELEVANT_AVAILABILITY]: state => ({
      ...state,
      withRelevantAvailability: false,
    }),
  },
  initialState,
);
export { dateFilter };
