import React from 'react';

import { defineKey } from '@ha/intl';

import { reportError } from 'ha/helpers/bugReporter/reportError';
import { Enum } from '@ha/core/utilities';
import { useIntl } from 'ha/i18n';
import { RoomsValue, TypeValue } from 'ha/types/SearchFilters';

import {
  SEARCH_FILTER_PROPERTY_TYPES,
  SEARCH_FILTER_ROOMS,
} from 'ha/modules/Search/translations/propertyType';
import {
  LocalState,
  SearchFiltersContext,
} from 'ha/pages/Search/SearchFiltersContext';

import { PageHighlightedFilter } from '../PageHighlightedFilter/PageHighlightedFilter';
import { SearchFilter } from '../SearchFilter/SearchFilter';
import { LoadableSearchFilterPropertyType } from '../SearchFilterPropertyType/SearchFilterPropertyType.lazy';

/**
 * The maximum number of characters to show in the label. Label items start being added in the order defined in the filter up to this limit.
 * If more cannot fit in the label, they will be replace with an ellipsis.
 * At least 1 label item will always be added even if it itself exceeds this limit (it will not be truncated mid text).
 */
const CHIP_LABEL_FIT_CHARACTERS = 35;

const ROOMS_TRANSLATIONS = {
  uppercase: {
    all: defineKey('search.page.chip.property_type.filter.selected.apartments'),
    singular: defineKey(
      'search.page.chip.property_type.filter.selected.apartments.bedrooms_1',
    ),
    plural: defineKey(
      'search.page.chip.property_type.filter.selected.apartments.bedrooms_%s',
    ),
    sequence: defineKey(
      'search.page.chip.property_type.filter.selected.apartments.bedrooms_%d_to_%d',
    ),
    setOfTwo: defineKey(
      'search.page.chip.property_type.filter.selected.apartments.bedrooms_%d_%s',
    ),
    setOfThree: defineKey(
      'search.page.chip.property_type.filter.selected.apartments.bedrooms_%d_%d_%s',
    ),
  },
  lowercase: {
    all: defineKey(
      'search.page.chip.property_type.filter.selected.apartments_lowercase',
    ),
    singular: defineKey(
      'search.page.chip.property_type.filter.selected.apartments.bedrooms_1_lowercase',
    ),
    plural: defineKey(
      'search.page.chip.property_type.filter.selected.apartments.bedrooms_%s_lowercase',
    ),
    sequence: defineKey(
      'search.page.chip.property_type.filter.selected.apartments.bedrooms_%d_to_%d_lowercase',
    ),
    setOfTwo: defineKey(
      'search.page.chip.property_type.filter.selected.apartments.bedrooms_%d_%s_lowercase',
    ),
    setOfThree: defineKey(
      'search.page.chip.property_type.filter.selected.apartments.bedrooms_%d_%d_%s_lowercase',
    ),
  },
};

const PROPERTY_TYPE_TRANSLATIONS = {
  uppercase: {
    [TypeValue.STUDIO]: defineKey(
      'search.page.chip.property_type.filter.selected.studio',
    ),
    [TypeValue.PRIVATE_ROOM]: defineKey(
      'search.page.chip.property_type.filter.selected.private_room',
    ),
    [TypeValue.SHARED_ROOM]: defineKey(
      'search.page.chip.property_type.filter.selected.shared_room',
    ),
    [TypeValue.STUDENT_HOUSING]: defineKey(
      'search.page.chip.property_type.filter.selected.student_residence',
    ),
  },
  lowercase: {
    [TypeValue.STUDIO]: defineKey(
      'search.page.chip.property_type.filter.selected.studio_lowercase',
    ),
    [TypeValue.PRIVATE_ROOM]: defineKey(
      'search.page.chip.property_type.filter.selected.private_room_lowercase',
    ),
    [TypeValue.SHARED_ROOM]: defineKey(
      'search.page.chip.property_type.filter.selected.shared_room_lowercase',
    ),
    [TypeValue.STUDENT_HOUSING]: defineKey(
      'search.page.chip.property_type.filter.selected.student_residence_lowercase',
    ),
  },
};

/**
 * Creates the label based the following rules:
 * - Labels come in order of items as passed in
 * - Capitalize first item, do not capitalize rest of items
 * - At least 1 item label will be added
 * - If adding another item exceeds the defined character limit it (and subsequent items) will not be added. Instead add an ellipsis
 *
 * Special rules for rooms apply:
 * - a single room type will appear alone
 * - 2 room types will appear as a comma delimited tuple
 * - 3 room types will appear as a range as long they are consecutive and do not include the rest (4+) room type
 *    - otherwise the room types will appear as a comma delimited triple
 * - 4 room types (all) are equivalent to none (implicit all)
 */
function usePropertyTypeLabel({
  propertyTypes,
  propertyRooms,
}: {
  propertyTypes: TypeValue[];
  propertyRooms: RoomsValue[];
}) {
  const { T } = useIntl();

  const label = React.useMemo(() => {
    if (propertyTypes.length === 0) return T('search.page.chip.property_type');

    const translations = propertyTypes.map((type, index) => {
      // apartment has special handling to include rooms
      if (type === TypeValue.APARTMENT) {
        // only 1st label item is capitalized, rest are lowercased (following translation convention)
        const apartmentTranslations =
          index === 0
            ? ROOMS_TRANSLATIONS.uppercase
            : ROOMS_TRANSLATIONS.lowercase;

        // no rooms are equivalent to all selected and vice versa. Neither shows a list
        if (
          propertyRooms.length === 0 ||
          propertyRooms.length >= Enum.keys(RoomsValue).length
        ) {
          return T(apartmentTranslations.all);
        }

        // a single room type appears by itself
        if (propertyRooms.length === 1) {
          // use singular version for 1 room
          if (propertyRooms[0] === RoomsValue.ONE) {
            return T(apartmentTranslations.singular);
          }

          // other room types use plural
          return T(apartmentTranslations.plural, propertyRooms[0]);
        }

        // 2 room types use a comma delimited tuple
        if (propertyRooms.length === 2) {
          const [first, second] = propertyRooms;

          return T(apartmentTranslations.setOfTwo, first, second);
        }

        if (propertyRooms.length === 3) {
          // 3 room types use a range if values are consecutive and do not contain the rest type
          if (
            propertyRooms.includes(RoomsValue.TWO) &&
            propertyRooms.includes(RoomsValue.THREE) &&
            !propertyRooms.includes(RoomsValue.FOUR_AND_MORE)
          ) {
            const [first] = propertyRooms[0];
            const last = propertyRooms[propertyRooms.length - 1];

            return T(apartmentTranslations.sequence, first, last);
          }

          // otherwise list them as a comma delimited triple
          return T(apartmentTranslations.setOfThree, ...propertyRooms);
        }

        // other combinations are unhandled
        reportError(
          new Error(
            'usePropertyTypeLabel failed to render -> the selected rooms do not match any handled scenario -> perhaps a room type was added as an option but not handled?',
          ),
          {
            metaData: {
              propertyTypes,
              propertyRooms,
            },
          },
        );

        return undefined;
      }

      // only 1st label item is capitalized, rest are lowercased (following translation convention)
      const otherTypeTranslations =
        index === 0
          ? PROPERTY_TYPE_TRANSLATIONS.uppercase
          : PROPERTY_TYPE_TRANSLATIONS.lowercase;

      return T(otherTypeTranslations[type]);
    });

    const [first, ...rest] = translations.filter(
      translation => translation !== undefined,
    );

    return rest.reduce(
      (step, translation) => {
        if (step.break) return step;

        const next = `${step.result}, ${translation}`;

        return next.length < CHIP_LABEL_FIT_CHARACTERS
          ? // as long as the result fits within the character limit keep adding more labels
            { ...step, result: next }
          : // otherwise add an ellipsis and break the loop
            { result: `${step.result}, …`, break: true };
      },
      { result: first, break: false },
    ).result;
  }, [T, propertyRooms, propertyTypes]);

  return label;
}

export const SearchControlsHighlightedFilterPropertyType = () => {
  const { localState, onChangeTypes, onChangeRooms, clearFilters } =
    React.useContext(SearchFiltersContext);

  const filterPropertyType = localState.types;
  const updatePropertyType = onChangeTypes;

  const filterPropertyRooms = localState.rooms.bedroomCount;
  const updatePropertyRooms = (value: RoomsValue[]) =>
    onChangeRooms({ bedroomCount: value });

  const handleReset = () => {
    clearFilters(
      Object.keys(localState).filter(
        (key: keyof LocalState) => !['types', 'rooms'].includes(key),
      ) as (keyof LocalState)[],
    );
  };

  const orderedPropertyTypes = filterPropertyType.sort((a, b) => {
    const positionA = SEARCH_FILTER_PROPERTY_TYPES.indexOf(a);
    const positionB = SEARCH_FILTER_PROPERTY_TYPES.indexOf(b);

    return positionA - positionB;
  });

  const orderedPropertyRooms = filterPropertyRooms.sort((a, b) => {
    const positionA = SEARCH_FILTER_ROOMS.indexOf(a);
    const positionB = SEARCH_FILTER_ROOMS.indexOf(b);

    return positionA - positionB;
  });

  const propertyTypeSelected = filterPropertyType.length > 0;

  const label = usePropertyTypeLabel({
    propertyTypes: orderedPropertyTypes,
    propertyRooms: orderedPropertyRooms,
  });

  return (
    <PageHighlightedFilter
      slots={{
        chip: (
          <PageHighlightedFilter.Chip highlighted={propertyTypeSelected}>
            {label}
          </PageHighlightedFilter.Chip>
        ),
      }}
    >
      <PageHighlightedFilter.Content>
        <SearchFilter>
          <LoadableSearchFilterPropertyType
            valueRooms={filterPropertyRooms}
            valueTypes={filterPropertyType}
            onChangeRooms={updatePropertyRooms}
            onChangeTypes={updatePropertyType}
          />
        </SearchFilter>
      </PageHighlightedFilter.Content>

      <PageHighlightedFilter.Footer onReset={handleReset} />
    </PageHighlightedFilter>
  );
};
