import { connect } from 'react-redux';

import { GlobalState } from 'ha/types/store';
import {
  getCollectionFilter,
  getCityName,
  getCountryName,
  getCountryCode,
  getAdvertiserName,
  getPageInfo,
  getPriceAverage,
} from '../selectors';
import {
  getPriceFilterMin,
  getPriceFilterMax,
  getPriceFilterCurrency,
} from '../selectors/priceSelectors';
import {
  getInitialLatitude,
  getInitialLongitude,
} from '../selectors/mapSelectors';
import { SearchMeta } from '../components/SearchMeta';

const mapStateToProps = (state: GlobalState) => {
  const latitude = getInitialLatitude(state);
  const longitude = getInitialLongitude(state);
  const priceMin = getPriceFilterMin(state);
  const priceMax = getPriceFilterMax(state);
  const currency = getPriceFilterCurrency(state);
  const priceAverage = getPriceAverage(state);

  return {
    canonicalCollection: getCollectionFilter(state),
    priceMax,
    priceMin,
    priceAverage,
    currency,
    cityName: getCityName(state),
    countryName: getCountryName(state),
    countryCode: getCountryCode(state),
    advertiserName: getAdvertiserName(state),
    total: getPageInfo(state).total,
    latitude,
    longitude,
  };
};

const SearchMetaContainer = connect(mapStateToProps)(SearchMeta);
export { SearchMetaContainer };
