import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { MobileMap } from '../components/MobileMap';
import { isMobileMapOpen } from '../selectors';
import { toggleMobileMap } from '../actions';

const enhance = connect(
  createStructuredSelector({
    isMobileMapOpen,
  }),
  {
    toggleMobileMap,
  },
);

const MobileMapContainer = enhance(MobileMap);
export { MobileMapContainer };
