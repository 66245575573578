import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'grid',
    gap: theme.utils.spacing('ref/spacing/2'),

    '@container filter (min-width: 320px)': {
      gridTemplateColumns: '[left] 1fr [right] 1fr',
    },

    '@container filter (min-width: 480px)': {
      gap: theme.utils.spacing('ref/spacing/4'),
    },
  },
}));

export const PageToggleButtonContainer = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const { classes } = useStyles();

  return <div className={classes.container}>{children}</div>;
};
