import React from 'react';

import { useSelector } from 'react-redux';

import { Helmet } from 'react-helmet-async';
import { makeStyles } from 'tss-react/mui';

import { Typography, Container, Grid, units } from '@hbf/dsl/legacy';

import { useMediaQuery } from 'ha/helpers/MediaQuery';
import { useIntl } from 'ha/i18n';

import { sidebarWidth } from '../../constants';
import { getCityCanonical } from '../../selectors';

export const useStyles = makeStyles()(theme => ({
  sidePadding: {
    width: units.pxToRem(sidebarWidth),
    marginRight: units.rem(1.5),
  },
  faqHeader: {
    [theme.breakpoints.down('md')]: {
      fontSize: units.pxToRem(20),
    },
  },
  faqSection: {
    marginBottom: units.pxToRem(50),
  },
  faqContainer: {
    padding: units.pxToRem(24),
    marginTop: units.pxToRem(16),
    border: `1px solid ${theme.palette.grey['200']}`,
    borderRadius: units.pxToRem(4),

    [theme.breakpoints.down('md')]: {
      padding: 0,
      border: 'none',
    },
  },
  faq: {
    padding: units.pxToRem(8),
    [theme.breakpoints.down('md')]: {
      padding: 0,
      marginBottom: units.pxToRem(24),
    },
  },
  faqTitle: { marginBottom: units.pxToRem(12) },
}));

export const SearchCityFAQ: React.FC = () => {
  const city = useSelector(getCityCanonical);
  const { classes } = useStyles();
  const { md } = useMediaQuery();

  const { T } = useIntl();

  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: T('seo.faq.average_rent.question', city),
        acceptedAnswer: {
          '@type': 'Answer',
          text: `<p>${T('seo.faq.why_expensive.answer', city, city)}</p>`,
        },
      },
    ],
  };

  return (
    <div className={classes.faqSection}>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <Container>
        <Grid container wrap="nowrap">
          {md && <div className={classes.sidePadding} />}
          <Grid container item xs>
            <Typography.H3 className={classes.faqHeader} fontWeight="bold">
              {T('FAQ')}
            </Typography.H3>

            <div className={classes.faqContainer}>
              <div className={classes.faq}>
                <Typography.ParagraphTitle className={classes.faqTitle}>
                  {T('seo.faq.average_rent.question', city)}
                </Typography.ParagraphTitle>
                <Typography.Paragraph>
                  {T('seo.faq.why_expensive.answer', city, city)}
                </Typography.Paragraph>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
