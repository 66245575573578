import { createSelector } from 'reselect';

import { filterBuilder } from '@ha/algolia';

import { CreateSearchAlertRequest } from 'ha/services/bell';

import { GlobalState } from 'ha/types/store';

import { getFilterBuilderContext } from './algoliaSelectors';
import {
  getCityCanonical,
  getCountryCanonical,
  getCountryCode,
} from './loadStateSelectors';
import { getFilterValues } from './filterSelectors';
import { getCurrencyRates } from './priceSelectors';
import {
  getInitialLatitude,
  getInitialLongitude,
  getRadius,
} from './mapSelectors';

import { getGeoBoundaryFilter } from '../utils/algoliaQueries/getGeoBoundaryFilter';
import { transformFiltersToSearchParamsSubset } from '../utils/transformFiltersToSearchParamsSubset';

export const getSearchAlert = (state: GlobalState) =>
  state.search.searchAlert.searchAlert;

export const getIsErrorSearchAlert = (state: GlobalState) =>
  state.search.searchAlert.error;
export const getIsLoadingSearchAlert = (state: GlobalState) =>
  state.search.searchAlert.loading;

export const getSearchAlertParams = createSelector(
  [
    getCityCanonical,
    getCountryCanonical,
    getCountryCode,
    getInitialLatitude,
    getInitialLongitude,
    getRadius,
    getFilterValues,
    getCurrencyRates,
  ],
  (
    city,
    country,
    countryCode,
    latitude,
    longitude,
    radius,
    filters,
    currencyRates,
  ): Pick<
    CreateSearchAlertRequest,
    'city' | 'countryCode' | 'alias' | 'filters' | 'boundingBox'
    // eslint-disable-next-line max-params
  > => {
    // use lat/lng/radius instead of map boundaries to not depend on screen size
    const boundary = getGeoBoundaryFilter(
      { radius: filters.radius },
      {
        latitude,
        longitude,
        radius,
      },
    )[0];

    const rate = currencyRates[filters.currency] ?? 1;

    // convert price to EUR, since algolia requires price be in EUR, and bell don't do conversions
    const price: (typeof filters)['price'] = {
      min: filters.price.min
        ? Math.floor(filters.price.min / rate)
        : filters.price.min,
      max: filters.price.max
        ? Math.floor(filters.price.max / rate)
        : filters.price.max,
    };

    return {
      city,
      countryCode,
      alias: `${city}, ${country}`,
      boundingBox: {
        x1: boundary[0],
        y1: boundary[1],
        x2: boundary[2],
        y2: boundary[3],
      },
      filters: transformFiltersToSearchParamsSubset({
        ...filters,
        price,
      }),
    };
  },
);

export const hasSearchAlert = createSelector([getSearchAlert], searchAlert =>
  Boolean(searchAlert),
);

const queryFilterBuilder = filterBuilder();

const getFilterBuilderListingsContext = (state: GlobalState) =>
  getFilterBuilderContext(state, 'listings');

const getIsSameAlert = createSelector(
  [getSearchAlertParams, getSearchAlert, getFilterBuilderListingsContext],
  (
    searchAlertParams,
    searchAlert,

    context,
  ) => {
    if (!searchAlert) {
      return false;
    }

    if (searchAlert.channel === 'whatsapp') {
      // This is relevant for WhatsApp v2 experiment, where we manually add recentlyAdded filter
      return (
        queryFilterBuilder(
          {
            ...searchAlertParams.filters,
            recentlyAdded: '1-day',
          },
          context,
        ) === queryFilterBuilder(searchAlert.filters, context)
      );
    }

    return (
      queryFilterBuilder(searchAlertParams.filters, context) ===
      queryFilterBuilder(searchAlert.filters, context)
    );
  },
);

export const isSearchAlertDisabled = createSelector(
  [getIsErrorSearchAlert, getIsLoadingSearchAlert, getIsSameAlert],
  (isError, isLoading, isSame) => isError || isLoading || isSame,
);
