import {
  TypeValue,
  FurnitureValue,
  BillsValue,
  SuitableForValue,
  RoomsValue,
} from 'ha/types/SearchFilters';
import { CanonicalCollections } from 'ha/constants/SearchPageCollections';

import { diffDay } from '@ha/date';

import { FilterQueryParams } from './types';

const isFiltersEmpty = ({
  categories,
  furniture,
  bills,
  registration,
  rules,
  recentlyAdded,
  bedroomCount,
  gender,
  suitableFor,
}: Partial<FilterQueryParams>): boolean => {
  if (categories && categories.length !== 0) {
    return false;
  }

  if (furniture && furniture.length !== 0) {
    return false;
  }

  if (bills && bills.length !== 0) {
    return false;
  }

  if (registration && registration.length !== 0) {
    return false;
  }

  if (recentlyAdded) {
    return false;
  }

  if (suitableFor && suitableFor.length !== 0) {
    return false;
  }

  if (rules && rules.length !== 0) {
    return false;
  }

  if (gender && gender.length !== 0) {
    return false;
  }

  if (bedroomCount && bedroomCount.length !== 0) {
    return false;
  }

  return true;
};

function isOnlyValue<T>(filter: Array<T> | undefined, value: T) {
  return Boolean(filter?.length === 1 && filter.includes(value));
}

const isPrivateRoomForRent = ({
  categories,
  ...rest
}: FilterQueryParams): boolean =>
  isOnlyValue(categories, TypeValue.PRIVATE_ROOM) && isFiltersEmpty(rest);

const isStudioForRent = ({ categories, ...rest }: FilterQueryParams): boolean =>
  isOnlyValue(categories, TypeValue.STUDIO) && isFiltersEmpty(rest);

const isSharedRoomForRent = ({
  categories,
  ...rest
}: FilterQueryParams): boolean =>
  isOnlyValue(categories, TypeValue.SHARED_ROOM) && isFiltersEmpty(rest);

const isApartmentForRent = ({
  categories,
  bedroomCount,
  ...rest
}: FilterQueryParams): boolean => {
  const withoutRooms = !bedroomCount || bedroomCount.length === 0;

  return (
    isOnlyValue(categories, TypeValue.APARTMENT) &&
    withoutRooms &&
    isFiltersEmpty(rest)
  );
};

const isFurnishedApartments = ({
  categories,
  bedroomCount,
  furniture,
  ...rest
}: FilterQueryParams): boolean => {
  const withoutRooms = !bedroomCount || bedroomCount.length === 0;

  return (
    isOnlyValue(categories, TypeValue.APARTMENT) &&
    withoutRooms &&
    isOnlyValue(furniture, FurnitureValue.FURNISHED) &&
    isFiltersEmpty(rest)
  );
};

const isOneBedroomApartments = ({
  categories,
  bedroomCount,
  ...rest
}: FilterQueryParams): boolean => {
  return (
    isOnlyValue(categories, TypeValue.APARTMENT) &&
    isOnlyValue(bedroomCount, RoomsValue.ONE) &&
    isFiltersEmpty(rest)
  );
};

const isTwoBedroomApartments = ({
  categories,
  bedroomCount,
  ...rest
}: FilterQueryParams): boolean => {
  return (
    isOnlyValue(categories, TypeValue.APARTMENT) &&
    isOnlyValue(bedroomCount, RoomsValue.TWO) &&
    isFiltersEmpty(rest)
  );
};

const isCheapAccommodation = ({
  priceMin,
  priceMax,
  currency,
  ...rest
}: FilterQueryParams): boolean =>
  priceMin === 0 && priceMax === 0 && !currency && isFiltersEmpty(rest);

const isLongTermRentals = ({
  startDate,
  endDate,
  ...rest
}: FilterQueryParams): boolean =>
  !endDate &&
  !!startDate &&
  diffDay(startDate, new Date().toISOString()) === 0 &&
  isFiltersEmpty(rest);

const isStudentAccomodation = ({
  furniture,
  bills,
  suitableFor,
  ...rest
}: Partial<FilterQueryParams>): boolean => {
  if (!furniture || !bills || !suitableFor) {
    return false;
  }

  return (
    isOnlyValue(furniture, FurnitureValue.FURNISHED) &&
    isOnlyValue(bills, BillsValue.INCLUDED) &&
    isOnlyValue(suitableFor, SuitableForValue.STUDENTS) &&
    isFiltersEmpty(rest)
  );
};

const isStudentRoom = ({ categories, ...rest }: FilterQueryParams): boolean => {
  return (
    !!categories &&
    categories.length === 2 &&
    categories.includes(TypeValue.PRIVATE_ROOM) &&
    categories.includes(TypeValue.SHARED_ROOM) &&
    isStudentAccomodation(rest)
  );
};

export function matchCategory(
  queryParams: FilterQueryParams,
): string | undefined {
  if (isPrivateRoomForRent(queryParams)) {
    return CanonicalCollections.PrivateRoomForRent;
  }

  if (isStudioForRent(queryParams)) {
    return CanonicalCollections.StudioForRent;
  }

  if (isSharedRoomForRent(queryParams)) {
    return CanonicalCollections.SharedRoomForRent;
  }

  if (isApartmentForRent(queryParams)) {
    return CanonicalCollections.ApartmentForRent;
  }

  if (isFurnishedApartments(queryParams)) {
    return CanonicalCollections.FurnishedApartments;
  }

  if (isOneBedroomApartments(queryParams)) {
    return CanonicalCollections.OneBedroomApartments;
  }

  if (isTwoBedroomApartments(queryParams)) {
    return CanonicalCollections.TwoBedroomApartments;
  }

  if (isCheapAccommodation(queryParams)) {
    return CanonicalCollections.CheapAccommodation;
  }

  if (isLongTermRentals(queryParams)) {
    return CanonicalCollections.LongTermRentals;
  }
  if (isStudentAccomodation(queryParams)) {
    return CanonicalCollections.StudentAccommodation;
  }

  if (isStudentRoom(queryParams)) {
    return CanonicalCollections.StudentRoom;
  }

  return undefined;
}
