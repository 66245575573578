import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Modal, { ModalProps } from '@mui/material/Modal';

const useStyles = makeStyles()({
  scrollable: {
    overflow: 'scroll',
  },
});

interface OwnProps {
  scrollable?: boolean;
}

type Props = OwnProps & ModalProps;

export const ModalNext: React.FC<Props> = ({
  children,
  open,
  onClose,
  scrollable = false,
  ...props
}) => {
  const { classes } = useStyles();
  return (
    <Modal
      open={open}
      onClose={onClose}
      className={scrollable ? classes.scrollable : ''}
      {...props}
    >
      {children}
    </Modal>
  );
};
