import React from 'react';

import { useTheme } from '@mui/system';
import { useMediaQuery } from '@mui/material';

import { PromotedBlogData, SearchPageData } from '@ha/contentful';

import { useOptionalHermes } from 'ha/modules/Hermes';

interface SearchResultsContentCardHook {
  contentCard: PromotedBlogData | null;
  contentCardIndex: number;
}

const useSearchResultsContentCard = (
  resultsCount: number,
): SearchResultsContentCardHook => {
  const theme = useTheme();
  const isLargerThanSm = useMediaQuery(theme.breakpoints.up('sm'));

  const [contentCard, setContentCard] = React.useState<PromotedBlogData | null>(
    null,
  );
  const { data } = useOptionalHermes<SearchPageData>();

  const getRandomInt = (max: number) =>
    Math.floor(Math.random() * Math.floor(max));

  React.useEffect(() => {
    if (data?.promotedContent?.length) {
      const index = getRandomInt(data?.promotedContent?.length || 0);
      setContentCard(data.promotedContent[index]);
    }
  }, [data]);

  const contentCardIndex = React.useMemo(
    () => Math.min(isLargerThanSm ? 8 : 3, resultsCount),
    [isLargerThanSm, resultsCount],
  );

  return {
    contentCard,
    contentCardIndex,
  };
};

export { useSearchResultsContentCard };
