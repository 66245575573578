import React from 'react';

import { Typography } from '@hbf/dsl/core';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  sizeContainer: {
    container: 'filter / inline-size',
  },

  filter: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.utils.spacing('ref/spacing/4'),
  },

  filterTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const SearchFilterTitle = ({
  slots = {},
  children = null,
}: React.PropsWithChildren<{ slots?: { end?: React.ReactNode } }>) => {
  const { classes } = useStyles();

  return (
    <div className={classes.filterTitle}>
      <Typography variant="heading/mobile/h4-semibold">{children}</Typography>

      {slots.end && <div>{slots.end}</div>}
    </div>
  );
};

const SearchFilterDescription = ({
  children = null,
}: React.PropsWithChildren<unknown>) => {
  return <Typography variant="caption/regular">{children}</Typography>;
};

const SearchFilterSizeContainer = ({
  children = null,
  className,
  ...rest
}: React.PropsWithChildren<React.ComponentProps<'div'>>) => {
  const { classes, cx } = useStyles();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...rest} className={cx(classes.sizeContainer, className)}>
      {children}
    </div>
  );
};

export const SearchFilter = ({
  children = null,
  'data-test-locator': dataTestLocator,
  components: { sizeContainer: Container = SearchFilterSizeContainer } = {},
}: React.PropsWithChildren<{
  'data-test-locator'?: string;
  components?: {
    sizeContainer?: React.FunctionComponent<
      React.PropsWithChildren<unknown>
    > | null;
  };
}>) => {
  const { classes } = useStyles();

  if (Container === null) {
    return (
      <div className={classes.filter} data-test-locator={dataTestLocator}>
        {children}
      </div>
    );
  }

  return (
    <Container data-test-locator={dataTestLocator}>
      <div className={classes.filter}>{children}</div>
    </Container>
  );
};

SearchFilter.Title = SearchFilterTitle;
SearchFilter.Description = SearchFilterDescription;
SearchFilter.SizeContainer = SearchFilterSizeContainer;
