import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isEqual from 'lodash-es/isEqual';
import pick from 'lodash-es/pick';

import { createSavedSearch } from 'ha/modules/SavedSearches/actions/createSavedSearch';
import { savedSearchLocalStore } from 'ha/modules/SavedSearches/savedSearchLocalStore';

import { getSearchParams, getPreviousParams } from '../selectors';
import { getSearchAlertParams } from '../selectors/searchAlertSelectors';
import { getKindsFromCategories } from '../utils';

dayjs.extend(utc);

const queryParams = [
  'citySearch',
  'priceMin',
  'priceMax',
  'categories',
  'startDate',
  'endDate',
  'types',
  'currency',
  'furniture',
  'rooms',
  'bills',
  'facilities',
  'amenities',
  'occupation',
  'contractType',
  'registration',
  'suitableFor',
  'sizeMin',
  'sizeMax',
  'advRating',
];

const shouldSaveSearch = (currentParams, previousParams) =>
  !isEqual(pick(previousParams, queryParams), pick(currentParams, queryParams));

const normalizeSearchParams = ({
  citySearch,
  priceMin,
  priceMax,
  categories,
  startDate,
  endDate,
  currency,
}) => {
  const [city, country] = citySearch.split('--');

  const kinds = getKindsFromCategories(categories);

  return {
    minPrice: priceMin,
    maxPrice: priceMax,
    city,
    country,
    startDate,
    endDate,
    currency,
  };
};

const saveSearch = () => (dispatch, getState) => {
  const state = getState();

  const searchParams = getSearchParams(state);
  const previousParams = getPreviousParams(state);
  const { filters, countryCode } = getSearchAlertParams(state);

  if (Array.isArray(filters?.advRating)) {
    filters.advRating = filters.advRating[0] ? filters.advRating[0] : undefined;
  }

  if (shouldSaveSearch(searchParams, previousParams)) {
    const normalizedSearchParams = normalizeSearchParams(searchParams);

    return dispatch(
      createSavedSearch({
        ...normalizedSearchParams,
        countryCode,
        filters,
        updatedAt: dayjs().utc().format(),
      }),
    );
  }

  return Promise.resolve();
};
export { saveSearch };
