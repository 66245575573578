import { redirect } from 'react-router-dom';

import isNil from 'lodash-es/isNil';
import { ParsedQuery } from 'query-string';

import { HttpStatus } from '@ha/api/v2/handleApiV2Response';
import { Language } from '@ha/intl';

import {
  FlexibleDaysValues,
  DEFAULT_SEARCH_FLEXIBILITY,
} from 'ha/constants/FlexibleSearchDates';

import { reportError } from 'ha/helpers/bugReporter/reportError';
import { Enum } from '@ha/core/utilities';
import { getUserId } from 'ha/modules/AuthLogic/selectors';
import { PageLoaderFunction, InjectAsyncReducer } from 'ha/types/routing';
import { collectionToFilters } from 'ha/utils/filters/collectionToFilters';
import { searchParamsToObject } from 'ha/utils/routes/searchParamsToObject';

import { loadHermesData } from 'ha/modules/Hermes';

import {
  load,
  getAveragePriceForCheapAccommodation,
  loadAdvertiser,
  updateSearch,
  loadCurrencyRates,
  notFound,
  unsupportedCountry,
} from './actions';
import { STATE_NAME } from './constants';
import { reducer } from './reducer';
import { getCityCanonical, getCountryCanonical } from './selectors';
import { validateUrl } from './utils/validateUrl';

export { SearchRoot as default } from './containers/SearchRoot';

export const loader: PageLoaderFunction = async args => {
  await validateUrl(args);

  const {
    params,
    request,
    context: { store, intl, services },
  } = args;

  const localizedKind = params.localizedKind
    ? encodeURIComponent(params.localizedKind)
    : undefined;

  const url = new URL(request.url);
  const userId = url.searchParams.get('userId');

  let query: ParsedQuery | undefined;

  // to query the city pages regardless of categories.
  const pathName = `${
    intl.lang === Language.en ? '' : '/'.concat(intl.lang)
  }/s/${params.location}`;

  /*
    1. we fetch information for the city and the Euro rate conversions
    2. Only for cheep accommodation we make a call to Algolia to find the average
       price of the listings for the given city, this average price will be use as
       max price filter for the following algolia calls
    3. we query algolia for the 24 results and to retrieve the counts of listings
       next to the filters (facets)
  */
  try {
    const [
      cityInfo,
      {
        data: { rates },
      },
    ] = await Promise.all([
      store.dispatch(updateSearch(params.location || '')),
      store.dispatch(loadCurrencyRates()),
    ]);

    const stats = await store.dispatch(
      getAveragePriceForCheapAccommodation(
        localizedKind || '',
        cityInfo.city,
        cityInfo.country,
      ),
    );

    const predefinedCategoryFilters = collectionToFilters(localizedKind, stats);

    const parsedBedroomCount = url.searchParams.get('bedroomCount')?.split(',');

    query = {
      ...searchParamsToObject(url.searchParams),
      // manually parse bedroom count as query string parser incorrectly parses all bedroomCount items as numbers - including "4+" (they must all be strings)
      bedroomCount:
        parsedBedroomCount ?? predefinedCategoryFilters.bedroomCount ?? [],
    };

    await Promise.all([
      store.dispatch(
        load(
          {
            ...predefinedCategoryFilters,
            ...params,
            ...query,

            // dependent on query.startDate
            flexDays:
              !isNil(query.flexDays) &&
              Enum.values(FlexibleDaysValues).includes(Number(query.flexDays))
                ? Number(query.flexDays)
                : DEFAULT_SEARCH_FLEXIBILITY.Search,

            localizedKind,
            priceAverage: stats.averagePrice,
          },
          cityInfo,
          rates,
        ),
      ),
      store.dispatch(loadHermesData(pathName)),
      userId ? store.dispatch(loadAdvertiser(userId)) : Promise.resolve(),
    ]);
  } catch (error) {
    switch (error.status) {
      case HttpStatus.NOT_FOUND: {
        store.dispatch(notFound());
        break;
      }
      case HttpStatus.GONE: {
        store.dispatch(unsupportedCountry());
        break;
      }
      case HttpStatus.PERMANENT_REDIRECTION: {
        const redirectUrl = error.response.redirectTo;
        reportError('Search Page Redirect', {
          metaData: {
            redirectUrl,
            currentPath: pathName,
          },
          severity: 'warning',
        });

        return redirect(
          encodeURI(
            `${redirectUrl}${localizedKind ? `/${localizedKind}` : ''}`,
          ),
          HttpStatus.PERMANENT_REDIRECTION,
        );
      }
      default: {
        reportError('Search Page Load Error', {
          metaData: { error, params, pathname: url.pathname, query },
        });
      }
    }
  }

  const userIdFromRedux = getUserId(store.getState());
  const city = getCityCanonical(store.getState());
  const country = getCountryCanonical(store.getState());
  const universityId = url.searchParams.get('universityId');
  const startDate = url.searchParams.get('startDate');

  // Update user's university
  if (universityId && userIdFromRedux && city && country && startDate) {
    await Promise.all([
      services.apiV2.updateUser(userIdFromRedux, {
        uniId: Number(universityId),
      }),
      services.apiV2.addSavedSearch(userIdFromRedux, {
        city,
        country,
        startDate,
      }),
    ]);

    const urlParams = url.searchParams;
    urlParams.delete('universityId');
    const newUrl = `${url.pathname}${url.search}`;

    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw redirect(encodeURI(newUrl), HttpStatus.FOUND_REDIRECTION);
  }
  return null;
};

export const injectAsyncReducer: InjectAsyncReducer = store => {
  store.injectAsyncReducer(STATE_NAME, reducer);
};
