import React from 'react';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  checkboxText: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    gap: theme.utils.spacing('ref/spacing/2'),
    marginBlock: theme.utils.spacing('ref/spacing/2'),

    '@container filter (min-width: 480px)': {
      justifyContent: 'unset',
    },
  },
}));

export const PageCheckboxText = ({
  children,
  className,
}: React.PropsWithChildren<{ className?: string }>) => {
  const { classes, cx } = useStyles();

  return <div className={cx(classes.checkboxText, className)}>{children}</div>;
};
