import React from 'react';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';

import { makeStyles } from 'tss-react/mui';

import { track } from '@hbf/analytics';
import { Popover, Typography } from '@hbf/dsl/core';
import { NativeTypography } from '@hbf/dsl/legacy';
import { Search } from '@hbf/icons/brand-bold';

import { Cookies } from 'ha/constants/Cookies';

import { useIntl } from 'ha/i18n';
import { isEnabled, useFeatureFlags } from 'ha/modules/FeatureFlags';

import { usePage } from 'ha/modules/Page/contexts';
import { RebrandThemeProvider } from 'ha/modules/ThemeProvider/RebrandThemeProvider';
import { useCookie } from 'ha/pages/Search/hooks/useCookie';

const useStyles = makeStyles()(theme => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'left',
    marginInline: theme.utils.spacing('ref/spacing/3'),
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.up('xs')]: {
      marginInline: theme.utils.spacing('ref/spacing/5'),
    },
  },
  button: {
    cursor: 'pointer',
    background: theme.palette.neutral[100],
    border: `1px solid ${theme.palette.mono.dark}`,
    borderRadius: theme.utils.spacing('ref/spacing/1'),
    paddingInline: theme.utils.spacing('ref/spacing/2'),
    height: theme.utils.spacing('ref/spacing/10'),
    display: 'flex',
    alignItems: 'center',
    gap: theme.utils.spacing('ref/spacing/2'),
    width: '100%',
    maxWidth: '100%',
    [theme.breakpoints.up('md')]: {
      width: '300px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '400px',
    },
    '&:hover': {
      boxShadow: `0 0 0 1px ${theme.palette.mono.dark}`,
    },
  },
  focused: {
    boxShadow: `0 0 0 1px ${theme.palette.mono.dark}`,
    outline: `1px solid ${theme.palette.tertiary.main}`,
    outlineOffset: '2px',
  },
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  popover: {
    zIndex: 1100,
  },
}));

const SearchInputButton = (
  {
    children,
    onClick,
    isDatesSelected = false,
    showTooltip = false,
  }: React.PropsWithChildren<{
    onClick?: () => void;
    isDatesSelected?: boolean;
    showTooltip?: boolean;
  }>,
  ref: React.ForwardedRef<React.ComponentRef<'button'>>,
) => {
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const { T } = useIntl();
  const [cookie, updateCookie] = useCookie(Cookies.USE_DATES_FILTER);
  const [isHintVisible, setIsHintVisible] = React.useState<boolean>(false);
  const isLargerThanSm = useMediaQuery(theme.breakpoints.up('sm'));
  const { rebrandNewDateOnboarding } = useFeatureFlags();
  const { name: pageName } = usePage();

  React.useEffect(() => {
    // Delay the showing of the popover in order to avoid
    // cumulative layout shift (CLS) on the page load.
    const timer = setTimeout(() => {
      if (
        isEnabled(rebrandNewDateOnboarding) &&
        showTooltip &&
        !isDatesSelected &&
        !cookie
      ) {
        setIsHintVisible(true);
      }
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rebrandNewDateOnboarding, showTooltip]);

  const onHintClose = React.useCallback(() => {
    updateCookie('hint closed');
    setIsHintVisible(false);
  }, [updateCookie]);

  const onClickAway = React.useCallback(() => {
    setIsHintVisible(false);
  }, [setIsHintVisible]);

  const onTooltipClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if ((event.target as HTMLElement).tagName !== 'svg') {
        track('Engagement Prompt Clicked', {
          type: 'Add dates',
          page: pageName,
        });
      }
    },
    [pageName],
  );

  return (
    <RebrandThemeProvider>
      <div className={classes.buttonContainer} ref={containerRef}>
        <Popover
          showArrow
          placement="bottom"
          variant="secondary"
          open={isHintVisible}
          className={classes.popover}
          onClose={onHintClose}
          onClickAway={onClickAway}
          onClick={onTooltipClick}
          container={containerRef.current}
          anchor={
            <button
              data-test-locator="SearchInputButton"
              id="search-header-button"
              className={cx(classes.button, {
                [classes.focused]: isHintVisible,
              })}
              onClick={onClick}
              ref={ref}
              type="button"
            >
              <Search fontSize="small" color="secondary" />
              <NativeTypography
                className={classes.text}
                variant={
                  isLargerThanSm ? 'body/md-semibold' : 'body/sm-semibold'
                }
                color="secondary"
                data-test-locator="Search Button Text"
              >
                {children}
              </NativeTypography>
            </button>
          }
        >
          <Typography variant="body/sm-regular">
            {T('search.page.tooltip.body.add_dates')}
          </Typography>
        </Popover>
      </div>
    </RebrandThemeProvider>
  );
};

const SearchInputButtonForwarded = React.forwardRef(SearchInputButton);

export { SearchInputButtonForwarded as SearchInputButton };
