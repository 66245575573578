import React from 'react';

import { Skeleton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  inputContainer: {
    display: 'flex',
    gap: theme.utils.spacing('ref/spacing/2'),
  },
}));

export const PageInputFieldFallback = () => {
  return <Skeleton height="48px" width="100%" variant="rounded" />;
};

export const PageInputFieldFallbackInlineContainer = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const { classes } = useStyles();

  return <div className={classes.inputContainer}>{children}</div>;
};
