import { createAction, ActionsUnion } from '@housinganywhere/safe-redux';

import { Thunk } from 'ha/myredux/types';
import { Listing } from '@ha/api/v2/types/Listing';

import {
  getLazyListing,
  isLazyListingLoading,
} from '../selectors/lazyListingSelectors';

export enum ActionTypes {
  START = 'Search.LAZY_LOAD_LISTING_START',
  DONE = 'Search.LAZY_LOAD_LISTING_DONE',
  ERROR = 'Search.LAZY_LOAD_LISTING_ERROR',
}

export const Actions = {
  start: (id: number) => createAction(ActionTypes.START, id),
  done: (listing: Listing) => createAction(ActionTypes.DONE, listing),
  error: (id: number) => createAction(ActionTypes.ERROR, id),
};

export type LazyListingActions = ActionsUnion<typeof Actions>;

export const lazyLoadListing: (id: number) => Thunk =
  id => async (dispatch, getState, services) => {
    const state = getState();

    if (getLazyListing(state, id) || isLazyListingLoading(state, id)) {
      return;
    }

    dispatch(Actions.start(id));

    try {
      const { data } = await services.apiV2.getUnitTypeById(id, {
        expand: 'photos,exclusions,costs',
      });
      const listing = data as unknown as Listing;

      dispatch(Actions.done(listing));
    } catch {
      dispatch(Actions.error(id));
    }
  };
