import { handleActions } from 'redux-actions';
import { Actions } from '../constants';

export const initialState = {
  loading: false,
  error: false,
  notFound: false,
  unsupportedCountry: false,
  cityCanonical: '',
  countryCode: '',
  countryCanonical: '',
};

const loadState = handleActions(
  {
    [Actions.LOAD_NOT_FOUND]: state => ({
      ...state,
      notFound: true,
    }),
    [Actions.LOAD_UNSUPPORTED_COUNTRY]: state => ({
      ...state,
      unsupportedCountry: true,
    }),
    [Actions.LOAD_START]: state => ({
      ...state,
      loading: true,
    }),
    [Actions.UPDATE_SEARCH_PARAMS]: (state, { payload }) => ({
      ...state,
      citySearch: payload.location,
    }),
    [Actions.LOAD_DONE]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: false,
      notFound: false,
      unsupportedCountry: false,
      countryCanonical: payload.country,
      countryCode: payload.countryCode,
      cityCanonical: payload.city,
      cityLocalized: payload.cityLocalized,
      countryLocalized: payload.countryLocalized,
      priceAverage: payload.priceAverage,
    }),
    [Actions.LOAD_ERROR]: state => ({
      ...state,
      loading: false,
      error: true,
    }),
  },
  initialState,
);

export const getCountryCode = state => state.countryCode;
export { loadState };
