import { countryToFlag } from 'ha/utils/countryToFlag';
import React from 'react';

import { Country } from './types';

interface Props {
  option: Country;
}

const CountryCodeOptionInternal: React.FC<Props> = ({ option }) => (
  <React.Fragment>
    <span data-test-locator={`Flag ${option.code}`}>
      {countryToFlag(option.code)}
    </span>
    {option.code} {option.dialCode}
  </React.Fragment>
);

const CountryCodeOption = React.memo(CountryCodeOptionInternal);
export { CountryCodeOption };
