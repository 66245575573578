import React from 'react';

import { Pagination } from '@hbf/dsl/core';
import { RebrandThemeProvider } from 'ha/modules/ThemeProvider/RebrandThemeProvider';
import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import { getPagerProps } from '../../selectors';
import { changePage } from '../../actions';

const useStyles = makeStyles()(() => ({
  container: {
    padding: '40px 0',
  },
}));

const PaginationContainer: React.FC = () => {
  const pagerProps = useSelector(getPagerProps);

  const dispatch = useDispatch();

  const pageCount = Math.ceil(
    pagerProps.pager.total / pagerProps.pager.quantity,
  );

  const onPageChange = React.useCallback(
    (_: React.ChangeEvent<unknown>, value: number) => {
      dispatch(changePage({ num: value }));

      window.scrollTo(0, 0);
    },
    [dispatch],
  );

  const { classes } = useStyles();

  if (pageCount < 2) return null;

  return (
    <Grid container justifyContent="center" className={classes.container}>
      <Pagination
        count={pageCount}
        page={pagerProps.pagerData.pageNum}
        onChange={onPageChange}
      />
    </Grid>
  );
};

const PaginationContainerWrapper: React.FC = () => (
  <RebrandThemeProvider>
    <PaginationContainer />
  </RebrandThemeProvider>
);

export { PaginationContainerWrapper as PaginationContainer };
