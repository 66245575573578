import { createAction } from 'redux-actions';

import { ApiError } from '@ha/api/v2/errors';
import { HttpStatus } from '@ha/api/v2/handleApiV2Response';
import { SearchCityData } from '@ha/api/v2/searchCity';
import { Language } from '@ha/intl';

import { SupportedCountries } from 'ha/constants/SupportedCountries';

import { Thunk } from 'ha/myredux/types';
import { encodeSearchQuery } from 'ha/utils/encodeSearchQuery';

import { getCurrentLanguageCode } from 'ha/modules/LanguageSwitcher/selectors';

import { Actions } from '../constants';
import { getPreviousParams, getCachedUpdateSearch } from '../selectors';

export const flow = {
  start: createAction(Actions.UPDATE_SEARCH_START),
  done: createAction(Actions.UPDATE_SEARCH_DONE),
  error: createAction(Actions.UPDATE_SEARCH_ERROR),
};

const formatResponse = (
  resData: SearchCityData,
  currentLanguageCode: Language,
) => {
  const langUrlList = Object.values(Language).reduce(
    (result, languageCode) => ({
      ...result,
      ...{
        [languageCode]:
          resData[languageCode]?.searchUrl ?? resData[Language.en].searchUrl,
      },
    }),
    {},
  );

  return {
    city: resData[currentLanguageCode].cityCanonical,
    cityLocalized: resData[currentLanguageCode].cityName,
    country: resData[currentLanguageCode].countryCanonical,
    countryCode: resData[currentLanguageCode].countryCode.toLowerCase(),
    countryLocalized: resData[currentLanguageCode].countryName,
    langURLList: langUrlList,
    latitude: resData[currentLanguageCode].latitude,
    longitude: resData[currentLanguageCode].longitude,
    minZoomLevel: resData[currentLanguageCode].mapMinZoom,
    radius: resData[currentLanguageCode].mapRadius,
    zoomLevel: resData[currentLanguageCode].mapZoom,
    geonameId: resData[currentLanguageCode].geonameId,
    currency: resData[currentLanguageCode].currency,
  };
};

const getCitySearch = (city: string, country: string) =>
  encodeSearchQuery(`${city}, ${country}`);

const updateSearch =
  (citySearch: string): Thunk =>
  (dispatch, getState, services) => {
    const state = getState();
    const { citySearch: prevCitySearch } = getPreviousParams(state);

    if (citySearch === prevCitySearch) {
      return Promise.resolve(getCachedUpdateSearch(state));
    }

    const currentLanguageCode = getCurrentLanguageCode(getState());

    // @ts-ignore
    dispatch(flow.start());

    return services.apiV2
      .searchCity({
        query: citySearch,
        languages: Object.values(Language),
      })
      .then(response => {
        const formattedResponse = formatResponse(
          response.data,
          currentLanguageCode,
        );

        // @ts-ignore
        dispatch(flow.done(formattedResponse));

        const { cityName, countryName } = response.data[currentLanguageCode];
        const searchUrlFound = getCitySearch(cityName, countryName);

        const currentLocalizedCountry = citySearch.split('--')?.pop();
        const foundLocalizedCountry = searchUrlFound.split('--')?.pop();

        if (
          citySearch !== searchUrlFound &&
          currentLocalizedCountry === foundLocalizedCountry
        ) {
          return Promise.reject(
            new ApiError(
              HttpStatus.PERMANENT_REDIRECTION,
              'Geocoder city Mismatch',
              {
                redirectTo: formattedResponse.langURLList[currentLanguageCode],
              },
              new Headers(),
            ),
          );
        }

        if (
          citySearch !== searchUrlFound &&
          currentLocalizedCountry !== foundLocalizedCountry
        ) {
          return Promise.reject(
            new ApiError(
              HttpStatus.NOT_FOUND,
              'Mismatched City Country',
              {},
              new Headers(),
            ),
          );
        }

        if (!SupportedCountries[formattedResponse.countryCode.toUpperCase()]) {
          throw new ApiError(
            HttpStatus.GONE,
            'Unsupported Country',
            {},
            new Headers(),
          );
        }

        return formattedResponse;
      })
      .catch(errors => {
        if (
          errors.status !== HttpStatus.NOT_FOUND &&
          errors.status !== HttpStatus.GONE
        ) {
          // @ts-ignore
          dispatch(flow.error(errors));
        }

        return Promise.reject(errors);
      });
  };

export { updateSearch };
