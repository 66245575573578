import React from 'react';

import { useTheme } from '@mui/system';
import { useMediaQuery } from '@mui/material';

import { PromotedBlogData } from '@ha/contentful';

import {
  BannerCardPlaceholder,
  BannerCard,
} from 'ha/components/Redesign/BannerCard';

import { useIntl } from 'ha/i18n';
import { LazyComponent } from 'ha/components.legacy/LazyComponent';
import { normalizeAlgoliaListing } from 'ha/models/Listing/normalizeAlgoliaListing';
import { ListingCardLocations } from 'ha/types/ListingCard';

import { SearchListing } from '../../types';
import { useSearchResultsContentCard } from '../../components/SearchResultsContentCard';
import { ListingPreviewCardTrackedContainer as ListingPreviewCardTracked } from '../../containers/ListingPreviewCardTracked';

type Props = {
  items: SearchListing[];
};

type BannerProps = {
  contentCard: PromotedBlogData | null;
};

const AVG_LISTING_CARD_HEIGHT_SM_MOBILE = 470; // px
const AVG_LISTING_CARD_HEIGHT_LG_MOBILE = 490; // px

const Banner: React.FC<BannerProps> = ({ contentCard }) => {
  const { T, urlResolver } = useIntl();

  if (contentCard) {
    return <BannerCard {...contentCard} />;
  }

  return (
    <BannerCardPlaceholder
      pathname={urlResolver.getSecurePaymentUrl()}
      title={T('search.page.blog_post_title.48_hour_guarantee')}
      buttonLabel={T('search.page.blog_post_cta.how_it_works')}
    />
  );
};

const SearchResultsListWithBannerCard: React.FC<Props> = ({ items }) => {
  const { contentCard, contentCardIndex } = useSearchResultsContentCard(
    items.length,
  );

  const listingCardPlaceholderHeight =
    typeof window !== 'undefined' && window.screen.width < 400
      ? AVG_LISTING_CARD_HEIGHT_SM_MOBILE
      : AVG_LISTING_CARD_HEIGHT_LG_MOBILE;

  const theme = useTheme();

  const isLargerThanSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  const serverLoadedCards = React.useMemo(() => {
    if (isLargerThanMd) return 8;
    if (isLargerThanSm) return 4;
    return 2;
  }, [isLargerThanMd, isLargerThanSm]);

  const renderContentCard = React.useCallback(() => {
    return contentCardIndex >= serverLoadedCards ? (
      <LazyComponent
        bottomOffset="-30%"
        scrollableAncestor="window"
        placeholder={
          <div
            style={{
              height: listingCardPlaceholderHeight,
            }}
          />
        }
      >
        <Banner contentCard={contentCard} />
      </LazyComponent>
    ) : (
      <Banner contentCard={contentCard} />
    );
  }, [
    contentCard,
    contentCardIndex,
    serverLoadedCards,
    listingCardPlaceholderHeight,
  ]);

  return (
    <React.Fragment>
      {items.map((room, index) => {
        const ssrRender = index >= serverLoadedCards;
        const Card = (
          <ListingPreviewCardTracked
            listingId={room.id}
            room={normalizeAlgoliaListing(room)}
            isLazy={ssrRender}
            withFavorites
            trackLocation={ListingCardLocations.search}
            rentalPeriodType={room.rentalPeriodType}
          />
        );

        return (
          <React.Fragment key={room.id}>
            {ssrRender ? (
              <LazyComponent
                bottomOffset="-30%"
                scrollableAncestor="window"
                placeholder={
                  <div
                    style={{
                      height: listingCardPlaceholderHeight,
                    }}
                  />
                }
              >
                {Card}
              </LazyComponent>
            ) : (
              Card
            )}
            {index + 1 === contentCardIndex && renderContentCard()}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

export { SearchResultsListWithBannerCard };
