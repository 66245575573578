import { handleActions } from '@housinganywhere/safe-redux';

import { Listing } from '@ha/api/v2/types/Listing';

import { LazyListingActions, ActionTypes } from '../actions/lazyListingActions';

interface ListingState {
  listing?: Listing;
  loading: boolean;
  error: boolean;
}

export type State = { [key: number]: ListingState };

const initialState: State = {};

const lazyListing = handleActions<State, ActionTypes, LazyListingActions>(
  {
    [ActionTypes.START]: (state, { payload: listingId }) => ({
      ...state,
      [listingId]: {
        loading: true,
        error: false,
      },
    }),
    [ActionTypes.DONE]: (state, { payload: listing }) => ({
      ...state,
      [listing.id]: {
        loading: false,
        error: false,
        listing,
      },
    }),
    [ActionTypes.ERROR]: (state, { payload: listingId }) => ({
      ...state,
      [listingId]: {
        loading: false,
        error: true,
      },
    }),
  },
  initialState,
);
export { lazyListing };
