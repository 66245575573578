import React from 'react';

import { Skeleton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { PageCheckboxText } from './PageCheckboxText';

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.utils.spacing('ref/spacing/3'),
  },
  checkbox: {
    display: 'flex',
    gap: theme.utils.spacing('ref/spacing/2'),
    alignItems: 'center',
  },
  checkboxText: {
    flexGrow: 2,
    alignItems: 'center',
  },
}));

export const PageCheckboxFallback = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.checkbox}>
      <Skeleton height="20px" width="20px" variant="rounded" />

      <PageCheckboxText className={classes.checkboxText}>
        <Skeleton height="24px" width="min(max(60%, 80px), 300px)" />
        <Skeleton height="28px" width="30px" />
      </PageCheckboxText>
    </div>
  );
};

export const PageCheckboxFallbackList = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const { classes } = useStyles();

  return <div className={classes.container}>{children}</div>;
};
