import React from 'react';

import { Divider, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { useSelector } from 'react-redux';

import { makeStyles } from 'tss-react/mui';

import { Chip } from '@hbf/dsl/core';
import { Sliders } from '@hbf/icons/brand-regular';

import { useIntl } from 'ha/i18n';

import { SmartTrack } from 'ha/components/track/SmartTrack';
import {
  LocalState,
  SearchFiltersContext,
} from 'ha/pages/Search/SearchFiltersContext';
import { getListings, getMapViewState } from 'ha/pages/Search/selectors';
import { MapViewState } from 'ha/pages/Search/types';

import { AVAILABLE_SEARCH_FILTERS } from '../../types';
import { reduceExplicitSearchFiltersCount } from '../../utilities/getExplicitSearchFiltersCount/getExplicitSearchFiltersCount';
import { SearchControlsAlertChip } from '../SearchControlsAlert/SearchControlsAlertChip';
import { SearchControlsHighlightedFilterFurniture } from '../SearchControlsHighlightedFilters/SearchControlsHighlightedFilterFurniture';
import { SearchControlsHighlightedFilterPricing } from '../SearchControlsHighlightedFilters/SearchControlsHighlightedFilterPricing';
import { SearchControlsHighlightedFilterPropertyType } from '../SearchControlsHighlightedFilters/SearchControlsHighlightedFilterPropertyType';
import { SearchMapButtonToggle } from '../SearchControlsMapToggle/SearchControlsMapButtonToggle';
import { SearchMapChipToggle } from '../SearchControlsMapToggle/SearchControlsMapChipToggle';
import { SearchControlsQuickFilters } from '../SearchControlsQuickFilters/SearchControlsQuickFilters';
import { SearchControlsSort } from '../SearchControlsSort/SearchControlsSort';
import { SearchFiltersDrawer } from '../SearchFiltersDrawer/SearchFiltersDrawer';
import { SearchFiltersDrawerContext } from '../SearchFiltersDrawer/SearchFiltersDrawerContext';
import { SearchResultsInfo } from '../SearchResultsInfo/SearchResultsInfo';
import { SearchResultsMapNotification } from '../SearchResultsMapNotification/SearchResultsMapNotification';

const useStyles = makeStyles()(theme => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.utils.spacing('ref/spacing/4'),

    paddingBlock: theme.utils.spacing('ref/spacing/3'),
  },

  headerSticky: {
    paddingBlock: theme.utils.spacing('ref/spacing/4'),
  },

  controlsContainer: {
    display: 'grid',
    gridAutoColumns: 'auto',
    gridTemplateRows: 'auto auto',
    gap: theme.utils.spacing('ref/spacing/4'),
    gridTemplateAreas: `
      "quickFilters"
      "predefinedFilters"
    `,

    marginInline: 'calc(var(--x-inline-padding) * -1)',
    '--x-controls-inline-padding': 'var(--x-inline-padding)',

    [theme.breakpoints.up('md')]: {
      '--x-controls-inline-padding': 0,
      gridTemplateColumns: 'auto min-content',
      marginInline: 0,
      gridTemplateAreas: `
        "quickFilters mapButton"
        "predefinedFilters mapButton"
      `,
    },
  },

  controlsContainerSticky: {
    [theme.breakpoints.up('md')]: {
      gridTemplateAreas: `
      "quickFilters mapButton"
    `,
      alignItems: 'center',
      gap: theme.utils.spacing('ref/spacing/0'),
    },
  },

  highlightedFiltersContainer: {
    gridArea: 'quickFilters',
    display: 'flex',
    gap: theme.utils.spacing('ref/spacing/2'),
    paddingInline: 'var(--x-controls-inline-padding)',
    flexWrap: 'wrap',
  },

  highlightedFiltersContainerSticky: {
    [theme.breakpoints.up('md')]: {
      gridArea: 'quickFilters ',
    },
  },

  quickFiltersContainer: {
    gridArea: 'predefinedFilters',
    display: 'inline-flex',

    maxWidth: '100%',
    overflow: 'auto',

    flexDirection: 'column',
    gap: theme.utils.spacing('ref/spacing/2'),
    paddingInline: 'var(--x-controls-inline-padding)',
  },

  mapContainer: {
    gridArea: 'mapButton',
  },

  compactMapButton: {
    height: '56px',
  },

  infoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.utils.spacing('ref/spacing/2'),
  },

  controlsSortContainer: {
    '> div': {
      width: 'max-content',
    },
  },

  divider: {
    borderColor: theme.palette.mono.dark,
    marginBlock: theme.utils.spacing('ref/spacing/2'),
    marginInline: theme.utils.spacing('ref/spacing/3'),
  },
}));

export const SearchResultsHeader = ({
  sticky = false,
}: {
  sticky?: boolean;
}) => {
  const { T } = useIntl();

  const { cx, classes } = useStyles();

  const currentView = useSelector(getMapViewState);
  const listingsCount = useSelector(getListings).length;

  const { localState, clearFilters, isFilterEmpty } =
    React.useContext(SearchFiltersContext);

  const { setDrawerOpen } = SearchFiltersDrawerContext.useContext();

  const theme = useTheme();

  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('md'));
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));
  const isLargerThanLg = useMediaQuery(theme.breakpoints.up('lg'));

  const handleClear = () => {
    clearFilters(
      Object.keys(localState).filter(
        (key: keyof LocalState) => !AVAILABLE_SEARCH_FILTERS.includes(key),
      ) as (keyof LocalState)[],
    );
  };

  const showHighlightedFilters = isLargerThanLg;
  const totalFilterCount = AVAILABLE_SEARCH_FILTERS.reduce(
    reduceExplicitSearchFiltersCount({ isFilterEmpty }),
    0,
  );

  const shouldRenderQuickFilter =
    isSmallerThanMd || (isLargerThanMd && !sticky);

  return (
    <React.Fragment>
      <div className={cx(classes.header, { [classes.headerSticky]: sticky })}>
        <div
          className={cx(classes.controlsContainer, {
            [classes.controlsContainerSticky]: sticky,
          })}
        >
          <div className={classes.highlightedFiltersContainer}>
            {showHighlightedFilters && (
              <React.Fragment>
                <SearchControlsHighlightedFilterPricing />

                <SearchControlsHighlightedFilterPropertyType />

                <SearchControlsHighlightedFilterFurniture />
              </React.Fragment>
            )}

            <SmartTrack name="Clicked Filters button">
              <Chip
                variant="outlined"
                color="neutral"
                size="lg"
                badge={totalFilterCount}
                startIcon={<Sliders />}
                data-test-locator="SearchControls/AllFiltersButton"
                onClick={() => setDrawerOpen(true)}
              >
                {isLargerThanMd
                  ? T('search.page.chip.filters')
                  : T('search.page.chip.mobile.filters')}
              </Chip>
            </SmartTrack>

            {showHighlightedFilters && (
              <Divider
                orientation="vertical"
                flexItem
                className={classes.divider}
              />
            )}

            {!isLargerThanMd && <SearchMapChipToggle />}

            <SearchControlsAlertChip />
          </div>

          {shouldRenderQuickFilter && (
            <div className={classes.quickFiltersContainer}>
              <SearchControlsQuickFilters />
            </div>
          )}

          {isLargerThanMd && (
            <div className={classes.mapContainer}>
              <SearchMapButtonToggle
                className={cx({ [classes.compactMapButton]: sticky })}
              />
            </div>
          )}
        </div>

        {!sticky && currentView === MapViewState.noMap && (
          <React.Fragment>
            <SearchResultsMapNotification />

            <div className={classes.infoContainer}>
              <div>
                <SearchResultsInfo />
              </div>

              {listingsCount > 0 && (
                <div className={classes.controlsSortContainer}>
                  <SearchControlsSort />
                </div>
              )}
            </div>
          </React.Fragment>
        )}
      </div>

      <SearchFiltersDrawer onClear={handleClear} />
    </React.Fragment>
  );
};
