import { useCallback, useEffect } from 'react';

import { useSelector } from 'react-redux';

import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';
import pick from 'lodash-es/pick';

import { track } from '@hbf/analytics';

import { usePrevious } from 'ha/helpers/hooks/usePrevious';
import { RequiredAnalyticsFilters } from 'ha/utils/filters/types';

import {
  getAnalyticsForFilters,
  getSearchParams,
  getMapParams,
  getCityCanonical,
} from '../selectors';
import { areCoordsEqual } from '../utils/areCoordsEqual';

const pickRequiredAnalytics = (value?: {}) =>
  pick(value, Object.keys(RequiredAnalyticsFilters));

const useFiltersAppliedAnalytics = () => {
  const filtersAnalytics = useSelector(getAnalyticsForFilters);
  const searchParams = useSelector(getSearchParams);
  const mapParams = useSelector(getMapParams);
  const cityCanonical = useSelector(getCityCanonical);
  const prevSearchParams = usePrevious(searchParams);
  const prevMapParams = usePrevious(mapParams);
  const prevCityCanonical = usePrevious(cityCanonical);

  const mapParamsAreEqual = useCallback(
    (mapState: typeof mapParams, prevMapState: typeof mapParams) =>
      mapState.zoomLevel === prevMapState.zoomLevel &&
      areCoordsEqual(prevMapState.latitude, mapState.latitude) &&
      areCoordsEqual(prevMapState.longitude, mapState.longitude),
    [],
  );

  useEffect(() => {
    const prevFilters = pickRequiredAnalytics(prevSearchParams);
    const filters = pickRequiredAnalytics(searchParams);

    const cityChanged =
      prevCityCanonical && prevCityCanonical !== cityCanonical;

    if (
      (!isEmpty(prevFilters) && !isEqual(prevFilters, filters)) ||
      (!isEmpty(prevMapParams) &&
        !mapParamsAreEqual(prevMapParams, mapParams)) ||
      cityChanged
    ) {
      const changedFilters = Object.keys(filters).filter(
        key => !isEqual(prevFilters[key], filters[key]),
      );
      const changedMapParams = Object.keys(mapParams).filter(
        key => prevMapParams?.[key] !== mapParams[key],
      );

      for (const key of Object.keys(filtersAnalytics)) {
        const el = filtersAnalytics[key];

        if (Array.isArray(el) || el === '') {
          if (el.length === 0) {
            delete filtersAnalytics[key];
          }
        }

        if (el === null) {
          delete filtersAnalytics[key];
        }
      }

      const eventPayload = {
        ...filtersAnalytics,
        searchChanges: [
          ...changedFilters,
          ...changedMapParams,
          ...(cityChanged ? ['listingcity'] : []),
        ],
      };

      track('Search filter applied', eventPayload);
    }
  }, [
    filtersAnalytics,
    searchParams,
    prevSearchParams,
    mapParams,
    prevMapParams,
    mapParamsAreEqual,
    cityCanonical,
    prevCityCanonical,
  ]);
};

export { useFiltersAppliedAnalytics };
