import React from 'react';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.utils.spacing('ref/spacing/6'),
  },
}));

export const SearchFilterPricingBlock = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const { classes } = useStyles();

  return <div className={classes.wrapper}>{children}</div>;
};
