import { redirect } from 'react-router-dom';
import { PageLoaderFunction } from 'ha/types/routing';
import { getCurrentLanguageCode } from 'ha/modules/LanguageSwitcher/selectors';
import { getTranslatedCollection } from 'ha/utils/urls/getTranslatedCollection';
import { removeTrailingSlash } from 'ha/utils/urls/removeTrailingSlash';
import { isValidDateQuery } from 'ha/utils/urls/isValidDateQuery';
import {
  ROOM_FOR_RENT,
  SHARED_ROOMS,
  CHEAP_ACCOMODATION,
} from 'ha/utils/urls/deprecatedRoutes';
import { throwNotFoundError } from 'ha/utils/routes/throwNotFoundError';

export const validateUrl: PageLoaderFunction = async args => {
  const { params, request, context } = args;
  const { store } = context;
  const collection = params.localizedKind ?? '';
  const url = new URL(request.url);

  const throwRedirect = (newPathname: string) => {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw redirect(`${newPathname}${url.search}`, 301);
  };

  let pathname = removeTrailingSlash(url.pathname);
  if (params.location && params.location.indexOf('~') > -1) {
    throwRedirect(pathname.replaceAll('~', '-'));
  }

  if (params.location && params.location.indexOf('.') > -1) {
    throwRedirect(pathname.replaceAll('.', ''));
  }

  const language = getCurrentLanguageCode(store.getState());

  if (ROOM_FOR_RENT.includes(collection)) {
    const currentCollection = getTranslatedCollection(
      language,
      'private-rooms',
    );

    throwRedirect(pathname.replace(collection, currentCollection));
  }

  if (SHARED_ROOMS.includes(collection)) {
    const currentCollection = getTranslatedCollection(language, 'shared-rooms');

    throwRedirect(pathname.replace(collection, currentCollection));
  }

  if (CHEAP_ACCOMODATION.includes(collection)) {
    const currentCollection = getTranslatedCollection(
      language,
      'cheap-accommodation',
    );

    throwRedirect(pathname.replace(collection, currentCollection));
  }

  if (collection) {
    const translatedCollection = getTranslatedCollection(
      language,
      encodeURIComponent(collection),
    );

    if (translatedCollection) {
      pathname = pathname.replace(collection, translatedCollection);
    }
  }

  if (url.pathname !== pathname) {
    throwRedirect(pathname);
  }

  if (
    !isValidDateQuery({
      startDate: url.searchParams.get('startDate') ?? undefined,
      endDate: url.searchParams.get('endDate') ?? undefined,
    })
  ) {
    url.searchParams.delete('startDate');
    url.searchParams.delete('endDate');

    // remove dates from query
    throwRedirect(url.pathname);
  }

  if (collection) {
    const translatedCollection = getTranslatedCollection(
      language,
      encodeURIComponent(collection),
    );

    if (!translatedCollection) {
      await throwNotFoundError(args);
    }
  }
};
