import { defineKey as T } from '@ha/intl';
import { LayoutModule, StyleTwoPinMarker } from '@hbf/icons/streamline-bold';

import { MapViewState } from '../types';

export const mapControlsArray = [
  {
    Icon: LayoutModule,
    text: T('mapControls.gallery'),
    value: MapViewState.noMap,
  },
  {
    Icon: StyleTwoPinMarker,
    text: T('mapControls.map'),
    value: MapViewState.fullMap,
  },
];
