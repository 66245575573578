import { Close } from '@hbf/icons/streamline-light';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { IconButton, units } from '@hbf/dsl/legacy';

export const useStyles = makeStyles()(theme => ({
  common: {
    stroke: theme.palette.grey[500],
    strokeWidth: '3px',
    fontSize: '14px',
  },
  button: {
    padding: '8px',
  },
  default: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  fixed: {
    position: 'relative',
  },
  mobileMap: {
    position: 'absolute',
    top: units.rem(0.75),
    left: units.rem(0.75),
  },
  'bg-mobileMap': {
    backgroundColor: 'white',
  },
}));

export type Kind = 'default' | 'fixed' | 'mobileMap';

interface Props {
  kind?: Kind;
  onClose: () => void;
}
export const CloseBtn: React.FC<Props> = ({ onClose, kind = 'default' }) => {
  const { classes, cx } = useStyles();
  return (
    <div className={classes[kind]} role="presentation">
      <IconButton
        onClick={onClose}
        className={cx(classes.button, classes[`bg-${kind}`])}
        data-test-locator="Modal Close Button"
        size="large"
      >
        <Close className={cx(classes.common, classes[`icon-${kind}`])} />
      </IconButton>
    </div>
  );
};
