import { useEffect, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { initVisitedRooms } from '../actions';
import { getMapViewState } from '../selectors/mapSelectors';
import { MapViewState } from '../types';

const useVisitedMapPins = () => {
  const dispatch = useDispatch();
  const mapViewState = useSelector(getMapViewState);

  const initialized = useRef(false);

  useEffect(() => {
    if (mapViewState === MapViewState.fullMap && !initialized.current) {
      initialized.current = true;
      dispatch(initVisitedRooms());
    }
  }, [dispatch, mapViewState]);
};

export { useVisitedMapPins };
