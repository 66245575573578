import { GlobalState } from 'ha/types/store';

export const getLazyListing = (state: GlobalState, id: number) =>
  state.search.lazyListing[id]?.listing;

export const isLazyListingLoading = (state: GlobalState, id: number) =>
  state.search.lazyListing[id]?.loading;

export const getLazyListingError = (state: GlobalState, id: number) =>
  state.search.lazyListing[id]?.error;
