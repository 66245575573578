import React from 'react';

import { useMediaQuery, useTheme } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import { SEARCH_PAGE_MAX_WIDTH } from 'ha/constants/search';

import { useScrollObserver } from 'ha/helpers/hooks/useScrollObserver';
import { useVerticalScrollDirection } from 'ha/helpers/hooks/useVerticalScrollDirection';

import { SearchResultsHeader } from './SearchResultsHeader';

const useStyles = makeStyles()(theme => ({
  containerOuter: {
    position: 'relative',
  },
  primaryFiltersContainer: {
    position: 'relative',
    zIndex: 1001,
  },

  // copy of the layout container to achieve the same side padding
  container: {
    maxWidth: SEARCH_PAGE_MAX_WIDTH,
    margin: 'auto',
    background: theme.palette.neutral[100],
    width: '100%',

    padding: '0px 20px',

    [theme.breakpoints.up('md')]: {
      padding: '0px 40px',
    },

    [theme.breakpoints.up('lg')]: {
      padding: '0px 80px',
    },
  },

  stickyFiltersContainer: {
    position: 'fixed',
    top: 0,
    left: '50%',
    zIndex: 1000,
    transform: 'translate(-50%, -100%)',
    transition: 'transform 0.5s ease-in-out',
    borderBottom: `1px solid ${theme.palette.mono.main}`,
    opacity: 0,
  },

  showStickyFiltersContainer: {
    opacity: 1,
    transform: 'translate(-50%, 0)',
  },
}));

export const SearchResultsHeaderSticky = () => {
  const theme = useTheme();
  const { classes, cx } = useStyles();
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('md'));

  const {
    sectionRef: primaryFiltersContainerRef,
    isSectionVisible: isPrimaryFiltersContainerVisible,
    createEventListener,
  } = useScrollObserver();

  const { scrollDirection } = useVerticalScrollDirection();

  const [isStickyFiltersContainerVisible, setShowStickyFilters] =
    React.useState(false);

  React.useLayoutEffect(() => {
    if (isPrimaryFiltersContainerVisible) {
      setShowStickyFilters(false);
    }

    const handleScroll = () => {
      if (isSmallerThanMd) {
        setShowStickyFilters(
          !isPrimaryFiltersContainerVisible && scrollDirection === 'up',
        );
      } else {
        setShowStickyFilters(!isPrimaryFiltersContainerVisible);
      }
    };

    const { removeEventListener } = createEventListener(handleScroll);

    return () => removeEventListener();
  }, [
    isPrimaryFiltersContainerVisible,
    isSmallerThanMd,
    scrollDirection,
    createEventListener,
  ]);

  return (
    <div className={classes.containerOuter}>
      <div
        ref={primaryFiltersContainerRef}
        className={cx(classes.container, classes.primaryFiltersContainer)}
      >
        <SearchResultsHeader />
      </div>

      <div
        className={cx(classes.container, classes.stickyFiltersContainer, {
          [classes.showStickyFiltersContainer]: isStickyFiltersContainerVisible,
        })}
      >
        <SearchResultsHeader sticky />
      </div>
    </div>
  );
};
