import React from 'react';

import { useSelector } from 'react-redux';
import { getIsAdvertiser } from 'ha/modules/AuthLogic/selectors';
import { HousingAnywhere } from '@hbf/icons/custom';
import { Breadcrumbs } from '@hbf/dsl/core';
import { Helmet } from 'react-helmet-async';
import { getJsonLdBreadcrumbs } from 'ha/components/Breadcrumbs/Breadcrumbs';
import { isSearchByPartner as getIsSearchByPartner } from 'ha/pages/Search/selectors';
import { useIntl } from 'ha/i18n';

// todo: refactor this data structure once legacy breadcrumbs are replaced
interface Breadcrumb {
  name: string;
  path?: string;
}

export const SearchPageBreadcrumbs = ({
  breadcrumbs,
}: {
  breadcrumbs: Breadcrumb[];
}) => {
  const { urlResolver, T } = useIntl();

  const isAdvertiser = useSelector(getIsAdvertiser);
  const isSearchByPartner = useSelector(getIsSearchByPartner);

  return (
    <React.Fragment>
      {!isSearchByPartner && (
        // todo: move outside of this component into some generic?
        <Helmet>
          <script type="application/ld+json" id="structuredDataBreadcrumbs">
            {getJsonLdBreadcrumbs([
              { name: T('Home'), path: urlResolver.getBaseUrl() },
              ...breadcrumbs,
            ])}
          </script>
        </Helmet>
      )}

      <Breadcrumbs>
        <Breadcrumbs.Link
          href={urlResolver.getHomeUrl({ explicitNoRedirect: isAdvertiser })}
          startIcon={<HousingAnywhere />}
        >
          {T('Home')}
        </Breadcrumbs.Link>

        {breadcrumbs.map(breadcrumb =>
          breadcrumb.path ? (
            <Breadcrumbs.Link
              underline="always"
              key={breadcrumb.name}
              href={breadcrumb.path}
            >
              {breadcrumb.name}
            </Breadcrumbs.Link>
          ) : (
            <Breadcrumbs.Text key={breadcrumb.name}>
              {breadcrumb.name}
            </Breadcrumbs.Text>
          ),
        )}
      </Breadcrumbs>
    </React.Fragment>
  );
};
