import React from 'react';

import { track } from '@hbf/analytics';

import {
  CUSTOM_EVENT_NAMES,
  RudderstackEventNames,
} from 'ha/modules/Analytics/constants';

import { ConversionTracker, ListingId, SearchItem } from './types';

export function useConversionTracker(): ConversionTracker {
  const trackAddToCart = React.useCallback((listingId: ListingId) => {
    track(RudderstackEventNames.ProductAdded, {
      quantity: 1,
      product_id: listingId,
    });
  }, []);

  const trackCheckout = React.useCallback(
    (listingId: ListingId, price: number, currency: string) => {
      const baseProperties = {
        currency,
        value: price,
        products: [
          {
            price,
            quantity: 1,
            product_id: listingId,
          },
        ],
      };

      const customProperties = {
        nonInteraction: 1, // FB Pixel Standard Events custom properties
      };

      track(RudderstackEventNames.OrderCompleted, {
        ...baseProperties,
        ...customProperties,
      });
    },
    [],
  );

  const trackSubscribe = React.useCallback(
    (currency: string, value: number) => {
      track(CUSTOM_EVENT_NAMES.SNAP.SUBSCRIBE, {
        value,
        currency,
        price: value,
        revenue: value,
      });
    },
    [],
  );

  const trackContentView = React.useCallback(
    (
      listingId: ListingId,
      userUUID: string,
      value?: number,
      currency?: string,
    ) => {
      const baseProperties = {
        product_id: listingId,
        user_uuid: userUUID,
        quantity: 1,
      };

      const customProperties: Record<string, any> = {
        nonInteraction: 1, // FB Pixel Standard Events custom properties
      };

      if (value !== undefined) {
        // track item view for Google Dynamic Remarketing
        customProperties.value = value;
        customProperties.currency = currency;
        customProperties.items = [
          {
            id: listingId,
            google_business_vertical: 'hotel_rental',
          },
        ];
      }

      track(RudderstackEventNames.ProductViewed, {
        ...baseProperties,
        ...customProperties,
      });
    },
    [],
  );

  const trackSearchView = React.useCallback(
    (listings: SearchItem[], countryCode: string) => {
      const listingsIds = listings.map(({ internalID }) => internalID);

      const baseProperties = {
        item_ids: listingsIds,
        query: countryCode,
        products: listingsIds.map(listingId => ({
          product_id: listingId,
          quantity: 1,
        })),
      };

      const customProperties = {
        content_ids: listingsIds, // FB Pixel Standard Events custom properties
        nonInteraction: 1, // FB Pixel Standard Events custom properties
      };

      track(RudderstackEventNames.ProductsSearched, {
        ...baseProperties,
        ...customProperties,
      });
    },
    [],
  );

  const trackSignUp = React.useCallback((email: string) => {
    track(CUSTOM_EVENT_NAMES.PINTEREST.SIGN_UP, { email });
  }, []);

  return {
    trackAddToCart,
    trackCheckout,
    trackSubscribe,
    trackContentView,
    trackSearchView,
    trackSignUp,
  };
}
