import { Language } from '@ha/intl';
import { encodeSearchQuery } from 'ha/utils/encodeSearchQuery';
import { DEFAULT_CURRENCY } from 'ha/constants/Currencies';
import { RadiusValue, SortingValue } from 'ha/types/SearchFilters';
import { formatHrefURL } from 'ha/utils/formatHrefURL';
import { LocaleToCanonicalCollections } from 'ha/constants/SearchPageCollections';
import { stringify } from 'query-string';

import { DEFAULT_SEARCH_FLEXIBILITY } from 'ha/constants/FlexibleSearchDates';
import { FilterQueryParams } from './types';
import { matchCategory } from './matchCategory';

interface Params {
  location: { city: string; country: string } | string;
  lang: Language;
  queryParams: FilterQueryParams;
  cityDefaultCurrency?: string;
}

const getSearchUrl = ({
  location,
  lang,
  queryParams: initialQueryParams,
  cityDefaultCurrency = DEFAULT_CURRENCY,
}: Params) => {
  const queryParams = {
    ...initialQueryParams,
  };

  if (queryParams.currency === cityDefaultCurrency) {
    queryParams.currency = undefined;
  }

  if (queryParams.sorting === SortingValue.BestMatch) {
    queryParams.sorting = undefined;
  }

  if (queryParams.radius === RadiusValue.DEFAULT) {
    queryParams.radius = undefined;
  }

  if (queryParams.flexDays === DEFAULT_SEARCH_FLEXIBILITY.Search) {
    queryParams.flexDays = undefined;
  }

  const category = matchCategory(queryParams);

  let pathname = formatHrefURL(
    `/s/${
      typeof location === 'string'
        ? location
        : encodeSearchQuery([location.city, location.country].join(', '))
    }`,
    lang,
  );

  if (category) {
    pathname = `${pathname}/${LocaleToCanonicalCollections[lang][category]}`;

    // clear all query params related to categories
    queryParams.bedroomCount = undefined;
    queryParams.categories = undefined;
    queryParams.bills = undefined;
    queryParams.furniture = undefined;
    queryParams.registration = undefined;
    queryParams.suitableFor = undefined;
  }

  const query = stringify(queryParams, {
    skipNull: true,
    arrayFormat: 'comma',
  });

  return {
    pathname,
    queryParams,
    search: query.length > 0 ? `?${query}` : '',
  };
};

export { getSearchUrl };
