import { handleActions } from 'redux-actions';

import { GenderValue } from 'ha/types/SearchFilters';

import { Actions } from '../constants';

import { GenderFilterState } from './types';

const initialState = {
  value: [],
};

interface Payload {
  gender: GenderValue;
}

const genderFilter = handleActions<GenderFilterState, Payload>(
  {
    [Actions.UPDATE_SEARCH_PARAMS]: (state, { payload: { gender = [] } }) => ({
      ...state,
      value: Array.isArray(gender) ? gender : [gender],
    }),
  },
  initialState,
);

export const getGenderFilter = (state: GenderFilterState) => state.value;
export { genderFilter };
