import React from 'react';

import {
  FurnitureValue,
  RoomsValue,
  SuitableForValue,
  TypeValue,
} from 'ha/types/SearchFilters';
import {
  LocalState,
  SearchFiltersContext,
} from 'ha/pages/Search/SearchFiltersContext';
import { defineKey } from '@ha/intl';
import isEqual from 'lodash-es/isEqual';
import sortBy from 'lodash-es/sortBy';

export enum QuickFilter {
  YourSearch = 'your-search',
  Anyone = 'anyone',
  Students = 'students',
  Professionals = 'professionals',
  Families = 'families',
}

export const QUICK_FILTER_ANALYTICS_EVENT = 'Quick Filter Applied';

export const QUICK_FILTER_ANALYTICS_NAME = {
  [QuickFilter.Anyone]: undefined,
  [QuickFilter.Families]: 'Good for families',
  [QuickFilter.Professionals]: 'Great for professionals',
  [QuickFilter.Students]: 'Suitable for students',
};

export const QUICK_FILTER_TRANSLATIONS = {
  [QuickFilter.YourSearch]: defineKey('search.page.quick_filters.your_search'),
  [QuickFilter.Anyone]: defineKey('search.page.quick_filters.anyone'),
  [QuickFilter.Families]: defineKey('search.page.quick_filters.families'),
  [QuickFilter.Professionals]: defineKey(
    'search.filters.popup.label.professionals',
  ),
  [QuickFilter.Students]: defineKey('search.filters.popup.label.students'),
};

function isSameArray(arr1: unknown[], arr2: unknown[]) {
  const sortedArr1 = sortBy(arr1);
  const sortedArr2 = sortBy(arr2);

  return isEqual(sortedArr1, sortedArr2);
}

/** Returns all matching predefined filters based on the current filter state */
export function getQuickFilterMatch({
  filters,
  isFilterEmpty,
}: {
  filters: LocalState;
  isFilterEmpty: (filter: keyof LocalState) => boolean;
}) {
  const matchesAnyoneQuickFilter =
    isFilterEmpty('suitableFor') &&
    isFilterEmpty('types') &&
    isFilterEmpty('furniture');

  // bedroomCount gets incorrectly parsed as numbers somewhere - for comparison we need it as strings
  const amendedBedroomCount = filters.rooms.bedroomCount.map(
    count => `${count}`,
  ) as RoomsValue[];

  const matchesFamiliesQuickFilter = isSameArray(filters.types, [
    TypeValue.APARTMENT,
  ]);

  const matchesStudentsQuickFilter =
    isSameArray(filters.suitableFor, [SuitableForValue.STUDENTS]) &&
    isSameArray(filters.types, [
      TypeValue.PRIVATE_ROOM,
      TypeValue.STUDENT_HOUSING,
      TypeValue.SHARED_ROOM,
      TypeValue.STUDIO,
    ]);

  const matchesProfessionalsQuickFilter =
    isSameArray(filters.types, [TypeValue.STUDIO, TypeValue.APARTMENT]) &&
    isSameArray(amendedBedroomCount, [RoomsValue.ONE]) &&
    isSameArray(filters.furniture, [FurnitureValue.FURNISHED]);

  const matchRecord = {
    [QuickFilter.Anyone]: matchesAnyoneQuickFilter,
    [QuickFilter.Families]: matchesFamiliesQuickFilter,
    [QuickFilter.Professionals]: matchesProfessionalsQuickFilter,
    [QuickFilter.Students]: matchesStudentsQuickFilter,
  };

  const matches = Object.fromEntries(
    Object.entries(matchRecord).filter(([, value]) => value === true),
  );

  if (Object.keys(matches).length !== 1) {
    return undefined;
  }

  return Object.keys(matches) as QuickFilter[];
}

/** Returns a function `onChangeQuickFilter` which given a new quick filter will adjust the filter state to match that of the predefined filter */
export function useQuickFilters() {
  const {
    onChangeTypes,
    onChangePropertySize,
    onChangeFurniture,
    onChangeRooms,
    onChangeSuitableFor,
    clearFilters,
  } = React.useContext(SearchFiltersContext);

  const onChangeQuickFilter = React.useCallback(
    (filter: QuickFilter) => {
      if (filter === QuickFilter.Anyone) {
        onChangeTypes([]);
        onChangeSuitableFor([]);
        onChangeFurniture([]);
        return;
      }

      if (filter === QuickFilter.Families) {
        onChangeSuitableFor([]);
        onChangeTypes([TypeValue.APARTMENT]);
        return;
      }

      if (filter === QuickFilter.Professionals) {
        onChangeSuitableFor([]);
        onChangeTypes([TypeValue.STUDIO, TypeValue.APARTMENT]);
        onChangeRooms({ bedroomCount: [RoomsValue.ONE] });
        onChangeFurniture([FurnitureValue.FURNISHED]);

        return;
      }

      if (filter === QuickFilter.Students) {
        onChangeSuitableFor([SuitableForValue.STUDENTS]);
        onChangeTypes([
          TypeValue.PRIVATE_ROOM,
          TypeValue.STUDENT_HOUSING,
          TypeValue.SHARED_ROOM,
          TypeValue.STUDIO,
        ]);
      }
    },
    [
      clearFilters,
      onChangeFurniture,
      onChangePropertySize,
      onChangeRooms,
      onChangeSuitableFor,
      onChangeTypes,
    ],
  );

  return { onChangeQuickFilter };
}
