import { handleActions } from 'redux-actions';

import { ContractTypeFilterValue } from 'ha/types/SearchFilters';

import { Actions } from '../constants';

import { ContractTypeFilterState } from './types';

const initialState = {
  value: [],
};

interface Payload {
  contractType: ContractTypeFilterValue;
}

const contractTypeFilter = handleActions<ContractTypeFilterState, Payload>(
  {
    [Actions.UPDATE_SEARCH_PARAMS]: (
      state,
      { payload: { contractType = [] } },
    ) => ({
      ...state,
      value: Array.isArray(contractType) ? contractType : [contractType],
    }),
  },
  initialState,
);
export { contractTypeFilter };
