import { handleActions } from 'redux-actions';

import { AdvertiserRatingValue } from 'ha/types/SearchFilters';

import { Actions } from '../constants';

import { AdvertiserRatingFilterState } from './types';

const initialState = {
  value: [],
};

interface Payload {
  advRating: AdvertiserRatingValue;
}

const advertiserRatingFilter = handleActions<
  AdvertiserRatingFilterState,
  Payload
>(
  {
    [Actions.UPDATE_SEARCH_PARAMS]: (
      state,
      { payload: { advRating = [] } },
    ) => ({
      ...state,
      value: Array.isArray(advRating) ? advRating : [advRating],
    }),
  },
  initialState,
);

export const getAdvertiserRatingFilter = (state: AdvertiserRatingFilterState) =>
  state.value;
export { advertiserRatingFilter };
