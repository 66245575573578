import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Button, Typography } from '@hbf/dsl/core';

import { useIntl } from 'ha/i18n';

import { SearchFiltersContext } from 'ha/pages/Search/SearchFiltersContext';

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.utils.spacing('ref/spacing/3'),

    paddingBlock: theme.utils.spacing('ref/spacing/10'),
  },

  buttons: {
    display: 'flex',
    gap: theme.utils.spacing('ref/spacing/4'),
  },
}));

const SearchResultsGuideBaseTitle = ({ children }: React.PropsWithChildren) => {
  return (
    <Typography variant="heading/mobile/h3-semibold">{children}</Typography>
  );
};

const SearchResultsGuideBaseDescription = ({
  children,
}: React.PropsWithChildren) => {
  return <Typography variant="body/sm-regular">{children}</Typography>;
};

interface Props {
  slots: {
    header: React.ReactElement;
    createAlertButton: React.ReactElement;
  };
}

const SearchResultsGuideBase = ({ slots }: Props) => {
  const { classes } = useStyles();
  const { T } = useIntl();

  const [applied, setApplied] = React.useState(false);

  const { clearFilters, applyFilters } = React.useContext(SearchFiltersContext);

  const handleClearFilters = () => {
    clearFilters();
    setApplied(true);
  };

  React.useEffect(() => {
    if (applied) {
      applyFilters();
      setApplied(false);
    }
  }, [applied, applyFilters]);

  return (
    <div className={classes.container}>
      {slots.header}

      <div className={classes.buttons}>
        <Button
          onClick={handleClearFilters}
          variant="outlined"
          color="neutral"
          size="medium"
        >
          {T('search.page.cta.no_results.clear_filters')}
        </Button>

        {slots.createAlertButton}
      </div>
    </div>
  );
};

SearchResultsGuideBase.Title = SearchResultsGuideBaseTitle;
SearchResultsGuideBase.Description = SearchResultsGuideBaseDescription;

export { SearchResultsGuideBase };
