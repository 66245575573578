import { FlexibleDaysValues } from '@ha/marketplace/feature-date-picker';
import { TranslateFunc } from '@ha/intl';

export const getFlexDatesString = (T: TranslateFunc, flexDays?: number) => {
  if (flexDays === FlexibleDaysValues.ONE_WEEK) {
    return `(± ${T('flexible_search_dates.options.1_week')})`;
  }
  if (flexDays === FlexibleDaysValues.TWO_WEEKS) {
    return `(± ${T('flexible_search_dates.options.2_weeks')})`;
  }
  return '';
};
