import { handleActions } from 'redux-actions';

import {
  PropertySizeFilterValue,
  FacilitiesValue,
  AmenitiesValue,
  RadiusValue,
  RadiusValueInMiles,
} from 'ha/types/SearchFilters';

import { Actions } from '../constants';

import { FiltersState } from './types';

const initialState = {
  propertySize: {
    value: {},
    stats: {
      minValue: 0,
      maxValue: 0,
    },
  },
  facilities: {
    value: [],
  },
  amenities: {
    value: [],
  },
  radius: {
    value: RadiusValue.DEFAULT,
  },
};

interface Payload {
  sizeMin: PropertySizeFilterValue['sizeMin'];
  sizeMax: PropertySizeFilterValue['sizeMax'];
  facilities: FacilitiesValue;
  amenities: AmenitiesValue;
  radius: RadiusValue | RadiusValueInMiles | undefined;
}

const filters = handleActions<FiltersState, Payload>(
  {
    [Actions.UPDATE_SEARCH_PARAMS]: (
      state,
      {
        payload: { sizeMin, sizeMax, facilities = [], amenities = [], radius },
      },
    ) => {
      const allRadiusValues = [
        ...Object.values(RadiusValue),
        ...Object.values(RadiusValueInMiles),
      ];

      return {
        ...state,

        propertySize: {
          ...state.propertySize,
          value: {
            sizeMin: Number(sizeMin) || undefined,
            sizeMax: Number(sizeMax) || undefined,
          },
        },

        facilities: {
          ...state.facilities,
          value: Array.isArray(facilities) ? facilities : [facilities],
        },

        amenities: {
          ...state.amenities,
          value: Array.isArray(amenities) ? amenities : [amenities],
        },

        radius: {
          ...state.radius,
          value:
            radius && allRadiusValues.includes(radius)
              ? radius
              : RadiusValue.DEFAULT,
        },
      };
    },
  },
  initialState,
);
export { filters };
