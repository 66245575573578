import { createAction, ActionsUnion } from '@housinganywhere/safe-redux';

import { SearchAlert } from 'ha/services/bell';

export enum UpsertActionTypes {
  START = 'Search.CREATE_SEARCH_ALERT_START',
  DONE = 'Search.CREATE_SEARCH_ALERT_DONE',
  ERROR = 'Search.CREATE_SEARCH_ALERT_ERROR',
}

export const UpsertActions = {
  start: () => createAction(UpsertActionTypes.START),
  done: (payload: { searchAlert: SearchAlert | null }) =>
    createAction(UpsertActionTypes.DONE, payload),
  error: () => createAction(UpsertActionTypes.ERROR),
};

export type UpsertActionsType = ActionsUnion<typeof UpsertActions>;

export enum GetActionTypes {
  START = 'Search.GET_SEARCH_ALERT_START',
  DONE = 'Search.GET_SEARCH_ALERT_DONE',
  ERROR = 'Search.GET_SEARCH_ALERT_ERROR',
}

export const GetActions = {
  start: () => createAction(GetActionTypes.START),
  done: (payload: { searchAlert: SearchAlert | null }) =>
    createAction(GetActionTypes.DONE, payload),
  error: () => createAction(GetActionTypes.ERROR),
};

export type GetActionsType = ActionsUnion<typeof GetActions>;
