import { useState, useEffect, useRef } from 'react';

export function useVerticalScrollDirection() {
  const prevScrollY = useRef(0);

  const [isAtTop, setIsAtTop] = useState(true);

  const [scrollDirection, setScrollDirection] = useState<'up' | 'down'>('down');

  useEffect(() => {
    const handleScroll = () => {
      // Checks if previous scrollY is less than latest scrollY
      // If true, we are scrolling downwards, else scrolling upwards
      const direction = prevScrollY.current < window.scrollY ? 'down' : 'up';

      // Updates the previous scroll variable AFTER the direction is set.
      // The order of events is key to making this work, as assigning
      // the previous scroll before checking the direction will result
      // in the direction always being 'up'.
      prevScrollY.current = window.scrollY;

      // Set the state to trigger re-rendering
      setScrollDirection(direction);
      setIsAtTop(window.scrollY === 0);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return { scrollDirection, isAtTop };
}
