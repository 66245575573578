import { createSelector } from 'reselect';

import { GlobalState } from 'ha/types/store';
import { Currency } from 'ha/constants/Currencies';
import { getFeatureFlags } from 'ha/modules/FeatureFlags';

type PriceDistribution = {
  range: number;
  value: number;
};

export type GlobalPriceFilter = {
  currency: Currency | '';
  currencyRates: { [key: string]: number };
  trimmedPriceDistribution: PriceDistribution[];
  trimmedPriceMax: number | null;
  trimmedPriceMin: number | null;
  priceDistribution: PriceDistribution[];
  priceMax: number | null;
  priceMin: number | null;
  selectedMin: number | null;
  selectedMax: number | null;
};

export const getCurrencyRates = (state: GlobalState) =>
  state.search.currencyRates;

export const getPriceFilterCurrency = (state: GlobalState) =>
  state.search.priceFilter.currency;
export const getPriceFilterDefaultCurrency = (state: GlobalState) =>
  state.search.priceFilter.defaultCurrency;
export const getPriceFilterMin = (state: GlobalState) =>
  state.search.priceFilter.priceMin;
export const getPriceFilterMax = (state: GlobalState) =>
  state.search.priceFilter.priceMax;
export const getPriceFilterTrimmedMin = (state: GlobalState) =>
  state.search.priceFilter.trimmedPriceMin;
export const getPriceFilterTrimmedMax = (state: GlobalState) =>
  state.search.priceFilter.trimmedPriceMax;
export const getPriceFilterTrimmedDistribution = (state: GlobalState) =>
  state.search.priceFilter.trimmedPriceDistribution;

/* eslint-disable max-params */
export const getConvertedPriceFilterParams = (currency: string) =>
  createSelector(
    [
      getPriceFilterCurrency,
      getCurrencyRates,
      getPriceFilterMax,
      getPriceFilterTrimmedMax,
      getPriceFilterTrimmedDistribution,
      getFeatureFlags,
    ],
    (
      globalCurrency,
      currencyRates,
      priceMax,
      trimmedPriceMax,
      trimmedPriceDistribution,
    ) => {
      const rate = currencyRates[currency] / currencyRates[globalCurrency];

      const convert = (price: number) => Math.floor(price * rate);

      return {
        priceMin: 0,
        priceMax: convert(priceMax),
        trimmedPriceMin: 0,
        trimmedPriceMax: convert(trimmedPriceMax),
        trimmedPriceDistribution: trimmedPriceDistribution.map(step => ({
          ...step,
          range: convert(step.range),
        })),
        currencyRates,
      };
    },
  );
/* eslint-enable max-params */
