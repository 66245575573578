import React from 'react';

import { makeStyles } from 'tss-react/mui';
import { Text, TextProps, units } from '@hbf/dsl/legacy';
import { CopyText } from '@ha/contentful';

interface Props {
  text: CopyText;
}

export const useStyles = makeStyles()({
  header: {
    marginTop: units.rem(2),
  },
});

const mapTypeToVariant: { [type: string]: TextProps['variant'] } = {
  h2: 'h6',
  h3: 'subtitle1',
  p: 'body1',
  subtitle: 'body1',
};

export const CityInfo: React.FC<Props> = ({ text }) => {
  const { classes } = useStyles();
  return (
    <div>
      {text.map((part, index) => {
        if (part.type === 'h2' || part.type === 'h3') {
          return (
            <Text
              key={index}
              className={classes.header}
              variant={mapTypeToVariant[part.type]}
              // for seo purposes
              component={part.type === 'h3' ? 'h2' : undefined}
              gutterBottom
              fw="bold"
            >
              {part.text}
            </Text>
          );
        }

        return (
          <Text
            key={index}
            variant={mapTypeToVariant[part.type]}
            paragraph
            italic={part.type === 'p' && part.style === 'italic'}
            kind={part.type === 'subtitle' ? 'price' : undefined}
          >
            {part.text}
          </Text>
        );
      })}
    </div>
  );
};
