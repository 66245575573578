import { handleActions } from 'redux-actions';

import { CityResponse } from 'ha/services/almanac';

import { Actions } from '../constants';

interface CityState {
  isLoading: boolean;
  hasError: boolean;
  categories: string[];
}

const initialState = {
  isLoading: false,
  hasError: false,
  categories: [],
};

const city = handleActions<CityState, CityResponse>(
  {
    [Actions.LOAD_CITY_START]: state => ({
      ...state,
      isLoading: true,
      hasError: false,
    }),
    [Actions.LOAD_CITY_DONE]: (state, { payload }) => ({
      ...state,
      categories: payload.categories.filter(category => category !== 'all'),
      isLoading: false,
      hasError: false,
    }),
    [Actions.LOAD_CITY_ERROR]: state => ({
      ...state,
      isLoading: false,
      hasError: true,
    }),
  },
  initialState,
);
export { city };
