import React from 'react';
import { CURRENCIES } from '@ha/api/v2/types/Currencies';

export type { Currency } from '@ha/api/v2/types/Currencies';

export const DEFAULT_CURRENCY = 'EUR';

export { CURRENCIES };

export const CURRENCY_OPTIONS = CURRENCIES.map(item => (
  <option key={item} value={item}>
    {item}
  </option>
));
