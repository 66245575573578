import React from 'react';

import { useMediaQuery, Divider } from '@mui/material';
import { useTheme } from '@mui/system';

import { makeStyles } from 'tss-react/mui';

import { Drawer, IconButton, Typography } from '@hbf/dsl/core';
import { X } from '@hbf/icons/brand-bold';

import { createRequiredContext } from 'ha/core/RequiredContext';

const useStyles = makeStyles()(theme => ({
  drawerRoot: {
    // TODO: remove once mobile map view is refactored - it currently uses a very high z index so drawer has to increase its default one to appear on top
    zIndex: 1300,
  },
  drawerTitle: {
    display: 'flex',
    flexDirection: 'column',

    position: 'sticky',
    zIndex: 10,
    top: 0,
    background: theme.palette.neutral[100],
  },

  drawerTitleContent: {
    paddingInline: theme.utils.spacing('ref/spacing/5'),
    paddingBlock: theme.utils.spacing('ref/spacing/3'),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  divider: {
    borderColor: theme.palette.mono.main,
  },

  drawer: {
    height: '85vh',
    [theme.breakpoints.up('md')]: {
      height: '100%',
      width: 600,
    },
  },

  drawerBody: {
    paddingInline: theme.utils.spacing('ref/spacing/5'),
    paddingBlock: theme.utils.spacing('ref/spacing/6'),

    display: 'flex',
    flexDirection: 'column',
    gap: theme.utils.spacing('ref/spacing/10'),
  },

  drawerFooter: {
    display: 'flex',
    flexDirection: 'column',

    position: 'sticky',
    zIndex: 10,
    bottom: 0,
    background: theme.palette.neutral[100],
  },

  drawerFooterContent: {
    padding: theme.utils.spacing('ref/spacing/5'),
    display: 'flex',
    gap: theme.utils.spacing('ref/spacing/4'),

    // if there are is more than 1 button align right
    '&:has(* + *)': {
      justifyContent: 'flex-end',
    },
  },
}));

const PageDrawerContext = createRequiredContext<{
  onClose: () => void;
}>('PageDrawer');

const PageDrawerHeader = ({ children }: React.PropsWithChildren<unknown>) => {
  const { classes } = useStyles();

  const { onClose } = PageDrawerContext.useContext();

  return (
    <div className={classes.drawerTitle}>
      <div className={classes.drawerTitleContent}>
        <Typography variant="heading/mobile/h3-semibold">{children}</Typography>

        <IconButton onClick={onClose} data-test-locator="PageDrawer/Close">
          <X />
        </IconButton>
      </div>

      <Divider className={classes.divider} />
    </div>
  );
};

const PageDrawerContent = ({ children }: React.PropsWithChildren<unknown>) => {
  const { classes } = useStyles();

  return <div className={classes.drawerBody}>{children}</div>;
};

const PageDrawerFooter = ({ children }: React.PropsWithChildren<unknown>) => {
  const { classes } = useStyles();

  return (
    <div className={classes.drawerFooter}>
      <Divider className={classes.divider} />

      <div className={classes.drawerFooterContent}>{children}</div>
    </div>
  );
};

interface PageDrawerProps {
  open: boolean;
  onClose: () => void;
  testLocator?: string;
}

export const PageDrawer = ({
  open,
  onClose,
  children = null,
  testLocator = undefined,
}: React.PropsWithChildren<PageDrawerProps>) => {
  const firstTime = React.useRef(true);

  const theme = useTheme();
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  const { classes } = useStyles();

  if (open) {
    firstTime.current = false;
  }

  const value = React.useMemo(
    () => ({
      onClose,
    }),
    [onClose],
  );

  return (
    <Drawer
      open={open}
      anchor={isLargerThanMd ? 'right' : 'bottom'}
      onClose={onClose}
      className={classes.drawerRoot}
      classes={{ paper: classes.drawer }}
      data-test-locator={testLocator}
    >
      <PageDrawerContext.Provider value={value}>
        {(open || !firstTime.current) && children}
      </PageDrawerContext.Provider>
    </Drawer>
  );
};

PageDrawer.Header = PageDrawerHeader;
PageDrawer.Content = PageDrawerContent;
PageDrawer.Footer = PageDrawerFooter;
