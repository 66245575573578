import { filterBuilder, BuilderContext } from '@ha/algolia';
import { AlgoliaQuery } from 'ha/services/algolia';
import { getGeoBoundaryFilter } from './getGeoBoundaryFilter';
import { getFilterParams } from './getFilterParams';
import { ReqParams, SearchParams, CurrencyEuroRates } from './types';

const queryFilterBuilder = filterBuilder();

const getAlgoliaFacetQuery = (
  facets: string[],
  reqParams: ReqParams,
  searchParams: SearchParams,
  currencyEuroRates: CurrencyEuroRates,
  context: BuilderContext,
): AlgoliaQuery => {
  const geoBoundaryFilter = getGeoBoundaryFilter(reqParams, searchParams);
  const filterParams = getFilterParams(reqParams, currencyEuroRates);
  const filters = queryFilterBuilder(filterParams, context);

  return {
    query: '',
    params: {
      distinct: true,
      facetingAfterDistinct: true,
      facets,
      insideBoundingBox: geoBoundaryFilter,
      filters,
      hitsPerPage: 0,
    },
  };
};

export { getAlgoliaFacetQuery };
