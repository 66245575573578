import { createSelector } from 'reselect';

import { GlobalState } from 'ha/types/store';
import { getMediaQueryBreakpoints } from 'ha/modules/UI/selectors';
import { isFavorite } from 'ha/modules/FavoriteListing/selectors';

import { areCoordsEqual } from '../utils/areCoordsEqual';
import {
  DefaultZoomLevelDesktop,
  DefaultZoomLevelMobile,
  DefaultMinZoomLevelDesktop,
  DefaultMinZoomLevelMobile,
} from '../constants';

export const getLocation = (state: GlobalState) => state.search.map.location;

export const getUniId = (state: GlobalState) => state.search.map.uniID;

export const getCityLocalized = (state: GlobalState) =>
  state.search.map.cityLocalized;

export const getCountryLocalized = (state: GlobalState) =>
  state.search.map.countryLocalized;

export const getSearchQuery = createSelector(
  [getCityLocalized, getCountryLocalized],
  (city, country) => [city, country].join(', '),
);

export const getLatitude = (state: GlobalState) => state.search.map.latitude;

export const getLongitude = (state: GlobalState) => state.search.map.longitude;

export const getRadius = (state: GlobalState) => state.search.map.radius;

const getZoomLevelValue = (state: GlobalState) => state.search.map.zoomLevel;

const getInitialZoomLevelValue = (state: GlobalState) =>
  state.search.map?.initialZoomLevel;

export const getInitialZoomLevel = createSelector(
  [getMediaQueryBreakpoints, getInitialZoomLevelValue],
  (breakpoints, initialZoomLevel) => {
    if (initialZoomLevel) {
      return initialZoomLevel;
    }

    if (breakpoints.md) {
      return DefaultZoomLevelDesktop;
    }

    return DefaultZoomLevelMobile;
  },
);

export const getZoomLevel = createSelector(
  [getZoomLevelValue, getInitialZoomLevel],
  (zoomLevel, initialZoomLevel) => {
    return zoomLevel ?? initialZoomLevel;
  },
);

export const getInitialLatitude = (state: GlobalState) =>
  state.search.map.initialLatitude;

export const getInitialLongitude = (state: GlobalState) =>
  state.search.map.initialLongitude;

export const getMinZoomLevel = createSelector(
  [getMediaQueryBreakpoints],
  breakpoints => {
    if (breakpoints.md) {
      return DefaultMinZoomLevelDesktop;
    }

    return DefaultMinZoomLevelMobile;
  },
);

const getIsMobileMapOpenValue = (state: GlobalState) =>
  state.search.map.isMobileMapOpen;

export const getIsMobileMapOpen = createSelector(
  [getIsMobileMapOpenValue, getMediaQueryBreakpoints],
  (isMobileMapOpen, breakpoints) => {
    if (breakpoints.md) {
      return false;
    }

    return isMobileMapOpen;
  },
);

export const getMapViewState = (state: GlobalState) =>
  state.search.map?.mapViewState;

export const getMapVisitedRooms = (state: GlobalState) =>
  state.search.map.visitedRooms;

export const isMarkerVisited = (
  state: GlobalState,
  roomIds: number[],
): boolean => {
  const visitedRooms = getMapVisitedRooms(state);

  return roomIds.every(roomId => visitedRooms[roomId]);
};

export const isMarkerFavorite = (
  state: GlobalState,
  roomIds: number[],
): boolean => roomIds.some(roomId => isFavorite(state, roomId));

export const isInitialState = createSelector(
  [
    getLatitude,
    getLongitude,
    getZoomLevel,
    getInitialLatitude,
    getInitialLongitude,
    getInitialZoomLevel,
  ],
  (lat, lng, zoom, initialLat, initialLng, initialZoom) =>
    !!initialLat &&
    areCoordsEqual(lat, initialLat) &&
    !!initialLng &&
    areCoordsEqual(lng, initialLng) &&
    zoom === initialZoom,
);

export const getUseMapAsAFilter = (state: GlobalState) =>
  state.search.map.useMapAsAFilter;

export const getBounds = (state: GlobalState) => state.search.map.bounds;
