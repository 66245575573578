// list with the supported countries:
// https://housinganywhere.atlassian.net/wiki/spaces/PayT/pages/2583691274
export const SupportedCountries = {
  AT: true, // Austria
  BE: true, // Belgium
  FI: true, // Finland
  DE: true, // Germany
  FR: true, // France
  DK: true, // Denmark
  IE: true, // Ireland
  IT: true, // Italy
  LU: true, // Luxembourg
  MT: true, // Malta
  NL: true, // Netherlands
  NO: true, // Norway
  PL: true, // Poland
  PT: true, // Portugal
  ES: true, // Spain
  SE: true, // Sweden
  CH: true, // Switzerland
  GB: true, // United Kingdom
  BG: true, // Bulgaria
  CZ: true, // Czech Republic
  EE: true, // Estonia
  GR: true, // Greece
  HU: true, // Hungary
  LV: true, // Latvia
  LT: true, // Lithuania
  RO: true, // Romania
  SK: true, // Slovakia
  SI: true, // Slovenia
  CY: true, // Cyprus
  IS: true, // Iceland
  US: true, // USA
  CA: true, // Canada
};
