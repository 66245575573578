import React from 'react';

import loadable from '@loadable/component';
import { Skeleton } from '@mui/material';

const FallbackSkeleton = () => {
  return <Skeleton height="85px" width="100%" />;
};

export const LoadableSearchFilterSize = loadable(
  () =>
    import(/* webpackChunkName: "DrawerSearchFilters" */ './SearchFilterSize'),
  { fallback: <FallbackSkeleton /> },
);
