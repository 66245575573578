export const ROOM_FOR_RENT = [
  /** en, zh */ 'room-for-rent',
  /** de */
  'zimmer-mieten',
  'privates-zimmer',
  /** es */ 'habitacion-para-alquilar',
  /** fr */ 'chambre-a-louer',
  /** it */ 'stanza-in-affitto',
  /** nl */ 'kamer-te-huur',
  /** pt */ 'quarto-para-alugar',
];

export const SHARED_ROOMS = [/** de */ 'geteiltes-zimmer'];

export const CHEAP_ACCOMODATION = [/** nl */ 'goedkope-accommodatie'];
