import { filterBuilder, BuilderContext } from '@ha/algolia';

import { convertSquareFootToSquareMeter } from 'ha/helpers/unitConverter';
import { AlgoliaQuery } from 'ha/services/algolia';

import { FacetQueryAttributes } from '../../constants';
import { SearchAlgoliaListingParams } from '../../types';

import { getFilterParams } from './getFilterParams';
import { getGeoBoundaryFilter } from './getGeoBoundaryFilter';
import {
  ReqParams,
  SearchParams,
  SearchOptions,
  CurrencyEuroRates,
} from './types';

const queryFilterBuilder = filterBuilder();

const convertSize = (size: number | undefined) => {
  if (!size) {
    return size;
  }
  return convertSquareFootToSquareMeter(size);
};

const getAlgoliaListingsQuery = (
  reqParams: ReqParams,
  searchParams: SearchParams,
  currencyEuroRates: CurrencyEuroRates,
  context: BuilderContext,
  isImperialSystem: boolean,
  searchOptions: SearchOptions = {},
): AlgoliaQuery => {
  // to make sure all required attributes are retrieved
  const attributeNames: {
    [key in keyof Required<SearchAlgoliaListingParams>]: true;
  } = {
    id: true,
    internalID: true,
    _geoloc: true,
    advertiserAvatar: true,
    propertyType: true,
    creationDateTS: true,
    dateFrom: true,
    dateTo: true,
    minimumStayMonths: true,
    priceEUR: true,
    utilities: true,
    facility_bedroom_count: true,
    facility_bedroom_furnished: true,
    facility_bedroom_size: true,
    facility_total_size: true,
    facility_registration_possible: true,
    facility_student_housing: true,
    facility_co_living: true,
    facility_bed: true,
    path: true,
    thumbnailURL: true,
    city: true,
    advertiserId: true,
    street: true,
    photos: true,
    photoCount: true,
    floorPlanCount: true,
    videoCount: true,
    currentOccupancy: true,
    freePlaces: true,
    cPolicy: true,
    contractType: true,
    minPrice: true,
    maxPrice: true,
    priceType: true,
    country: true,
    maximumStay: true,
    isPartner: true,
    isMultiUnit: true,
    unitTypePath: true,
    unitTypeInternalID: true,
    rentalPeriodType: true,
    bookableDateFromStart: true,
    bookableDateFromEnd: true,
    bookableDateToStart: true,
    bookableDateToEnd: true,
    advertiserFirstName: true,
    advertiserMoveInOverallRating: true,
    advertiserMoveInReviewCount: true,
    isExcellentAdvertiser: true,
    pricingValues: true,
    pricingValuesEUR: true,
    landlordType: true,
    has3DTour: true,
    photo360Count: true,
    rank_withOrpheus: true,
    rank_withOrpheusExperiment: true,
  };

  const geoBoundaryFilter = getGeoBoundaryFilter(reqParams, searchParams);
  const filterParams = getFilterParams(reqParams, currencyEuroRates);
  if (isImperialSystem) {
    filterParams.sizeMin = convertSize(filterParams.sizeMin);
    filterParams.sizeMax = convertSize(filterParams.sizeMax);
  }
  const filters = queryFilterBuilder(filterParams, context);

  const page = searchOptions.withoutData
    ? undefined
    : reqParams.offset / reqParams.limit;

  const requiredFacets = [
    'isPartner', // used in analytics
  ];

  const algoliaQuery: AlgoliaQuery = {
    query: '',
    params: {
      distinct: true,
      facetingAfterDistinct: true,
      insideBoundingBox: geoBoundaryFilter,
      facets: searchOptions.withoutFacets
        ? [...requiredFacets]
        : [...requiredFacets, ...FacetQueryAttributes],
      attributesToHighlight: [],
      attributesToRetrieve: Object.keys(attributeNames),
      hitsPerPage: searchOptions.withoutData ? 0 : reqParams.limit,
      filters,
    },
  };

  if (typeof page !== 'undefined') {
    algoliaQuery.params = { ...algoliaQuery.params, page };
  }

  return algoliaQuery;
};

export { getAlgoliaListingsQuery };
