import React from 'react';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';

import { tss } from 'tss-react/mui';

import { PromotedBlogData } from '@ha/contentful';
import { Highlight, Typography } from '@hbf/dsl/core';
import { Link } from '@hbf/dsl/legacy';
import { ArrowRight as ArrowRightIcon } from '@hbf/icons/brand-regular';

import { Imgix } from 'ha/modules/Image/Imgix';
import { CTA_QUERY_PARAM } from 'ha/pages/Blog/constants';

import { Card } from '../Card';
import { BANNER_CARD_IMAGE_SIZE } from '../ListingCard/constants';

const ANIMATION_EFFECT = '400ms cubic-bezier(0, 0, 0, 1)';
const TITLE_LINE_COUNTS_LIMIT = 3;

const useStyles = tss
  .withName('BannerCard')
  .withNestedSelectors<'button'>()
  .create(({ theme, classes }) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',

      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.mono.light,
      borderRadius: theme.utils.borderRadius('ref/radius/xs'),

      overflow: 'hidden',
      transition: `background-color ${ANIMATION_EFFECT}`,
      ':hover': {
        backgroundColor: theme.palette.mono.accent,

        [`.${classes.button}`]: {
          gap: theme.spacing(5),
        },
      },
    },
    contentSection: {
      padding: theme.spacing(6),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: theme.spacing(4),
    },

    imageSection: {},

    title: {
      /*
       * Adding this code to limit the height of the element in case -webkit-line-clamp is not supported by the browser.
       */
      overflow: 'hidden',
      '--line-height':
        theme.typography['heading/mobile/h4-semibold'].lineHeight,
      '--title-lines-limit': TITLE_LINE_COUNTS_LIMIT,
      maxHeight: 'calc(var(--line-height) * var(--title-lines-limit))',
      [theme.breakpoints.up('md')]: {
        '--line-height':
          theme.typography['heading/desktop/h4-semibold'].lineHeight,
      },

      display: '-webkit-box',
      textOverflow: 'ellipsis',

      /*
       * In the future we intend to move to `line-clamp` (which is not standardized yet) and stop using the deprecated
       * `box-orient` (which is required by the legacy `-webkit-line-clamp`)
       */
      WebkitLineClamp: `${TITLE_LINE_COUNTS_LIMIT}`,
      WebkitBoxOrient: 'vertical',
    },

    button: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),

      transition: `gap ${ANIMATION_EFFECT}`,
    },

    image: {
      width: '100%',
      height: '100%',
    },

    icon: {
      color: theme.palette.secondary.main,
    },
  }));

const BannerCardBaseImage = ({
  imgSrc,
  isLazy,
}: {
  imgSrc: string;
  isLazy?: boolean;
}) => {
  const { classes } = useStyles();

  const theme = useTheme();
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  const imgSize = isLargerThanMd
    ? BANNER_CARD_IMAGE_SIZE.lg
    : BANNER_CARD_IMAGE_SIZE.sm;

  return (
    <div className={classes.imageSection}>
      <Imgix
        src={imgSrc}
        htmlAttributes={{
          role: 'presentation',
          'data-test-locator': 'BannerCard/Image',
        }}
        imgixParams={{ ar: '3:2', fit: 'crop', auto: 'format' }}
        className={classes.image}
        width={imgSize.width}
        height={imgSize.height}
        isLazy={isLazy}
      />
    </div>
  );
};

const BannerCardBaseTitle = ({ children }: React.PropsWithChildren) => {
  const theme = useTheme();
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  const { classes } = useStyles();

  return (
    <Typography
      variant={
        isLargerThanMd
          ? 'heading/desktop/h4-semibold'
          : 'heading/mobile/h4-semibold'
      }
      className={classes.title}
      data-test-locator="BannerCard/Title"
    >
      {children}
    </Typography>
  );
};

const BannerCardBaseHighlightedText = ({
  children,
}: React.PropsWithChildren) => {
  return (
    <Highlight color="neutral" data-test-locator="BannerCard/HighlightedText">
      {children}
    </Highlight>
  );
};

const BannerCardBaseButton = ({ children }: React.PropsWithChildren) => {
  const { classes } = useStyles();

  return (
    <div className={classes.button}>
      <Typography
        variant="heading/mobile/h4-regular"
        data-test-locator="BannerCard/ButtonLabel"
      >
        {children}
      </Typography>
      <ArrowRightIcon className={classes.icon} />
    </div>
  );
};

const BannerCardBaseContent = ({ children }: React.PropsWithChildren) => {
  const { classes } = useStyles();

  return <div className={classes.contentSection}>{children}</div>;
};

export interface BannerCardBaseProps
  extends Pick<PromotedBlogData, 'pathname'> {
  onClick: React.MouseEventHandler<HTMLAnchorElement> | undefined;
}

export const BannerCardBase = ({
  pathname,
  onClick,
  children,
}: React.PropsWithChildren<BannerCardBaseProps>) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  const blogPath = `${pathname}?${CTA_QUERY_PARAM}=1`;

  return (
    <Card>
      <Link
        to={blogPath}
        onClick={onClick}
        target={isLargerThanMd ? '_blank' : '_self'}
        data-test-locator="BannerCard/Link"
      >
        <div className={classes.container}>{children}</div>
      </Link>
    </Card>
  );
};

BannerCardBase.Image = BannerCardBaseImage;
BannerCardBase.Title = BannerCardBaseTitle;
BannerCardBase.HighlightedText = BannerCardBaseHighlightedText;
BannerCardBase.Button = BannerCardBaseButton;
BannerCardBase.Content = BannerCardBaseContent;
