import { handleActions } from 'redux-actions';
import { Actions } from '../constants';

const initialState = {};

const langURLListReducer = handleActions(
  {
    [Actions.LOAD_DONE]: (state, { payload: { langURLList } }) => ({
      ...state,
      ...langURLList,
    }),
  },
  initialState,
);
export { langURLListReducer };
