import React from 'react';

import { useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/system';

import { ListingCardSkeleton } from 'ha/components/Redesign/ListingCard/components/ListingCardSkeleton';

import { SearchResultListItemGrid } from './SearchResultListItemGrid';
import { getIsLoading } from '../../selectors';

const SearchResultsListSkeleton: React.FC = () => {
  const loading = useSelector(getIsLoading);

  const theme = useTheme();

  const isLargerThanSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  const SkeletonComponent = React.useMemo(() => {
    let numberOfCards = 2;
    if (isLargerThanMd) {
      numberOfCards = 8;
    } else if (isLargerThanSm) {
      numberOfCards = 4;
    }

    return Array(numberOfCards)
      .fill(true)
      .map((_, index) => (
        <SearchResultListItemGrid
          key={index}
          data-test-locator="Search Results Item: Skeleton"
        >
          <ListingCardSkeleton />
        </SearchResultListItemGrid>
      ));
  }, [isLargerThanSm, isLargerThanMd]);

  if (!loading) return null;

  return SkeletonComponent;
};

export { SearchResultsListSkeleton };
