import React from 'react';

import { useIntl } from 'ha/i18n';

import {
  SEARCH_FILTER_FURNITURE_LOWERCASED_TRANSLATIONS,
  SEARCH_FILTER_FURNITURE_TRANSLATIONS,
} from 'ha/modules/Search/translations/furniture';
import {
  LocalState,
  SearchFiltersContext,
} from 'ha/pages/Search/SearchFiltersContext';

import { PageHighlightedFilter } from '../PageHighlightedFilter/PageHighlightedFilter';
import { SearchFilter } from '../SearchFilter/SearchFilter';
import { LoadableSearchFilterFurniture } from '../SearchFilterFurniture/SearchFilterFurniture.lazy';

export const SearchControlsHighlightedFilterFurniture = () => {
  const { T } = useIntl();

  const { localState, onChangeFurniture, clearFilters } =
    React.useContext(SearchFiltersContext);

  const filterFurniture = localState.furniture;
  const updateFurniture = onChangeFurniture;

  const handleReset = () => {
    clearFilters(
      Object.keys(localState).filter(
        (key: keyof LocalState) => !['furniture'].includes(key),
      ) as (keyof LocalState)[],
    );
  };

  const furnitureSelected = filterFurniture.length > 0;

  const label = furnitureSelected
    ? filterFurniture
        .map(
          (furniture, index) =>
            (index === 0
              ? SEARCH_FILTER_FURNITURE_TRANSLATIONS
              : SEARCH_FILTER_FURNITURE_LOWERCASED_TRANSLATIONS)[furniture],
        )
        .join(', ')
    : T('search.page.chip.furniture');

  return (
    <PageHighlightedFilter
      slots={{
        chip: (
          <PageHighlightedFilter.Chip highlighted={furnitureSelected}>
            {label}
          </PageHighlightedFilter.Chip>
        ),
      }}
    >
      <PageHighlightedFilter.Content>
        <SearchFilter>
          <LoadableSearchFilterFurniture
            value={filterFurniture}
            onChange={updateFurniture}
          />
        </SearchFilter>
      </PageHighlightedFilter.Content>

      <PageHighlightedFilter.Footer onReset={handleReset} />
    </PageHighlightedFilter>
  );
};
