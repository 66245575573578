import { STATE_NAME } from '../constants';

const getRequestCache = state => state[STATE_NAME].requestCache;

const getRequestId = state => getRequestCache(state).requestId;

export const getSearchRequestId = state => getRequestId(state).search;
export const getUniversitiesRequestId = state =>
  getRequestId(state).universities;
export const getCitiesNearbyRequestId = state =>
  getRequestId(state).citiesNearby;
export const getPreviousParams = state => getRequestCache(state).previousParams;

const getCachedResponses = state => getRequestCache(state).responses;

export const getCachedUpdateSearch = state =>
  getCachedResponses(state).updateSearch;
export const getCachedCurrencyEuroRates = state =>
  getCachedResponses(state).currencyEuroRates;
