import { handleActions } from 'redux-actions';

import { SuitableForValue } from 'ha/types/SearchFilters';

import { Actions } from '../constants';

import { SuitableForFilterState } from './types';

const initialState = {
  value: [],
};

interface Payload {
  suitableFor: SuitableForValue;
}

const suitableForFilter = handleActions<SuitableForFilterState, Payload>(
  {
    [Actions.UPDATE_SEARCH_PARAMS]: (
      state,
      { payload: { suitableFor = [] } },
    ) => ({
      ...state,
      value: Array.isArray(suitableFor) ? suitableFor : [suitableFor],
    }),
  },
  initialState,
);

export const getSuitableForFilter = (state: SuitableForFilterState) =>
  state.value;
export { suitableForFilter };
