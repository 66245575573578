import { normalizeCoordinate, getBoundaryCoordinates } from '@ha/algolia';
import {
  RadiusValue,
  radiusValueToMeters,
  radiusMilesValueToMeters,
  RadiusValueInMiles,
} from 'ha/types/SearchFilters';

import { Bounds } from '../../reducer/types';
import { ReqParams } from './types';

interface MapParams {
  latitude: number;
  longitude: number;
  radius: number;
}

const getGeoBoundaryFilter = (
  reqParams: Partial<ReqParams & Bounds>,
  mapParams: MapParams,
) => {
  const isRadiusInMiles = reqParams.radius?.split('mi')[1] === '';

  const radius = !isRadiusInMiles
    ? radiusValueToMeters(reqParams.radius as RadiusValue)
    : radiusMilesValueToMeters(reqParams.radius as RadiusValueInMiles);

  return reqParams.topLat &&
    reqParams.rightLng &&
    reqParams.bottomLat &&
    reqParams.leftLng
    ? [
        [
          normalizeCoordinate(reqParams.topLat),
          normalizeCoordinate(reqParams.rightLng),
          normalizeCoordinate(reqParams.bottomLat),
          normalizeCoordinate(reqParams.leftLng),
        ],
      ]
    : [
        getBoundaryCoordinates(
          mapParams.latitude,
          mapParams.longitude,
          (reqParams.radius && reqParams.radius !== RadiusValue.DEFAULT
            ? radius
            : mapParams.radius) / 1000,
        ),
      ];
};
export { getGeoBoundaryFilter };
