import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import dayjs from 'dayjs';

import { getToday } from '@ha/date';
import { track } from '@hbf/analytics';

import {
  disableResultsWithRelevantAvailability,
  onChangeFilters,
} from '../actions';
import {
  getNumberOfListings,
  getCityCanonical,
  getCountryCanonical,
} from '../selectors';
import {
  getDateFilter,
  getIsWithRelevantAvailabilityEnabled,
} from '../selectors/filterSelectors';

const DEFAULT_LISTINGS_LIMIT = 25;
const CUSTOM_LIMIT_PER_CITY = {
  'Slovenia-Ljubljana': 60,
};

// This is an improvement over this feature: https://housinganywhere.atlassian.net/browse/TX-1663,
// with this fix we relax the date filter constraint when the user searches for
// accommodation on the near future in cities with low inventory.
const useRelevantResults = () => {
  const dispatch = useDispatch();

  const totalNumberOfListings = useSelector(getNumberOfListings);
  const cityCanonical = useSelector(getCityCanonical);
  const countryCanonical = useSelector(getCountryCanonical);
  const isWithRelevantAvailabilityEnabled = useSelector(
    getIsWithRelevantAvailabilityEnabled,
  );
  const currentDates = useSelector(getDateFilter);

  const resultLimit = Number(
    CUSTOM_LIMIT_PER_CITY[`${countryCanonical}-${cityCanonical}`] ??
      DEFAULT_LISTINGS_LIMIT,
  );

  useEffect(() => {
    if (
      !isWithRelevantAvailabilityEnabled ||
      !currentDates?.startDate ||
      totalNumberOfListings >= resultLimit
    ) {
      return;
    }

    const monthDiff = dayjs(currentDates.startDate).diff(
      getToday(),
      'month',
      true,
    );

    if (monthDiff < 6 && monthDiff > 1) {
      track('Search page - Disable relevant availability filtering rule', {
        numberOfListings: totalNumberOfListings,
        ruleLimit: resultLimit,
        listingcity: cityCanonical,
        listingcountry: countryCanonical,
        startDate: currentDates?.startDate,
        endDate: currentDates?.endDate,
      });

      dispatch(disableResultsWithRelevantAvailability());
      dispatch(onChangeFilters({}));
    }

    // 'currentDates.endDate' is used for mixpanel tracking changing it should not trigger the hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    cityCanonical,
    countryCanonical,
    totalNumberOfListings,
    currentDates.startDate,
    isWithRelevantAvailabilityEnabled,
    resultLimit,
  ]);
};

export { useRelevantResults };
