import flattenDeep from 'lodash-es/flattenDeep';
import uniq from 'lodash-es/uniq';

import * as SearchFilters from 'ha/types/SearchFilters';
import { KindsForSavedSearch } from '../constants';

const getKindsFromCategories = (
  categories: SearchFilters.TypeFilterValue,
): string[] => {
  const list =
    categories.length === 0
      ? Object.values(KindsForSavedSearch)
      : categories.map(category => KindsForSavedSearch[category]);

  return uniq(flattenDeep<string>(list)).filter(
    category => category !== 'student housing',
  );
};
export { getKindsFromCategories };
