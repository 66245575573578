import { getSearchParams, shouldUseMapBounds, getBounds } from '../selectors';

import { updateSearchParams, setPreviousParams } from './actions';
import { loadSearchResults } from './loadSearchResults';
import { saveSearch } from './saveSearch';

const load = (reqParams, cityInfo, rates) => (dispatch, getState) => {
  dispatch(updateSearchParams(reqParams));

  const state = getState();
  const searchParams = getSearchParams(state);

  const useMapBounds = shouldUseMapBounds(state);
  const mapBounds = getBounds(state);
  const searchParamsWithMapBounds = {
    ...searchParams,
    ...(useMapBounds && {
      ...mapBounds,
    }),
  };

  return dispatch(
    loadSearchResults(
      searchParamsWithMapBounds,
      {
        localizedKind: reqParams.localizedKind,
        priceAverage: reqParams.priceAverage,
      },
      cityInfo,
      rates,
    ),
  )
    .then(() => {
      dispatch(saveSearch());
      dispatch(setPreviousParams(searchParamsWithMapBounds));
    })
    .catch(error => Promise.reject(error));
};

export { load };
