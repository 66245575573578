import React from 'react';

import { useSelector } from 'react-redux';

import { makeStyles } from 'tss-react/mui';

import { useConversionTracker } from 'ha/modules/Analytics/hooks';
import { isEnabled, useFeatureFlags } from 'ha/modules/FeatureFlags';
import { useServices } from 'ha/services';

import { RebrandThemeProvider } from 'ha/modules/ThemeProvider/RebrandThemeProvider';
import {
  getListings,
  getCityCanonical,
  getCityName,
  getCountryCode,
} from 'ha/pages/Search/selectors';
import {
  SearchResultsEmpty,
  SearchResultsFewerThanExpected,
} from 'ha/pages/SearchRedesign/components/SearchResultsGuide';

import { SearchResultsNotificationToAddDatesOnFilter as SearchDatesFilterNotification } from '../../components/SearchDatesFilterNotification';
import { SearchResultsBottom } from '../../components/SearchResultsBottom';

import { SearchResultsListSkeleton } from './SearchResultsListSkeleton';
import { SearchResultsListWithBannerCard } from './SearchResultsListWithBannerCard';

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
    gap: theme.spacing(6),

    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    },

    [theme.breakpoints.up('md')]: {
      gap: '40px',
      gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    },

    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    },
  },
}));

const SearchResultsLayout = ({ children }: React.PropsWithChildren) => {
  const { classes } = useStyles();

  return <div className={classes.container}>{children}</div>;
};

const SearchResults: React.FC = () => {
  const listings = useSelector(getListings);
  const cityCanonical = useSelector(getCityCanonical);
  const countryCode = useSelector(getCountryCode);
  const cityLocalizedName = useSelector(getCityName);

  const { analytics } = useServices();
  const { trackSearchView } = useConversionTracker();
  const { rebrandNewDateOnboarding } = useFeatureFlags();
  React.useEffect(() => {
    analytics.onUserIdentified(() =>
      trackSearchView(listings, countryCode || ''),
    );
  }, [trackSearchView, listings, countryCode, analytics]);

  return (
    <React.Fragment>
      {listings.length > 0 ? (
        <React.Fragment>
          {!isEnabled(rebrandNewDateOnboarding) && (
            <SearchDatesFilterNotification />
          )}
          <RebrandThemeProvider>
            <SearchResultsLayout>
              <SearchResultsListSkeleton />
              <SearchResultsListWithBannerCard items={listings} />
            </SearchResultsLayout>
          </RebrandThemeProvider>
        </React.Fragment>
      ) : (
        <RebrandThemeProvider>
          <SearchResultsEmpty />
        </RebrandThemeProvider>
      )}

      <SearchResultsFewerThanExpected resultsCount={listings.length} />

      <SearchResultsBottom
        cityCanonical={cityCanonical}
        countryCode={countryCode}
        cityLocalizedName={cityLocalizedName}
      />
    </React.Fragment>
  );
};

export { SearchResults };
