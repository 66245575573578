import get from 'lodash-es/get';
import { Thunk } from 'ha/myredux/types';
import {
  CanonicalCollections,
  TranslatedCollectionToCanonical,
} from 'ha/constants/SearchPageCollections';

const defaultStats = { averagePrice: 0 };

const getPriceFacet = (city: string, country: string) => ({
  query: '',
  params: {
    distinct: true,
    facets: ['priceEUR'],
    hitsPerPage: 0,
    filters: `city:'${city}' AND country:'${country}' AND isSearchable:true`,
  },
});

const getAveragePriceForCheapAccommodation =
  (
    translatedCollection: string,
    cityCanonical: string,
    countryCanonical: string,
  ): Thunk =>
  (_dispatch, _getState, { algolia }) => {
    if (
      !translatedCollection ||
      TranslatedCollectionToCanonical[translatedCollection] !==
        CanonicalCollections.CheapAccommodation
    ) {
      return Promise.resolve(defaultStats);
    }

    return algolia
      .searchListings([getPriceFacet(cityCanonical, countryCanonical)])
      .then(response => response.results[0])
      .then(price => ({
        averagePrice: Math.round(
          get(price.facets_stats, ['priceEUR', 'avg'], 0) * 100,
        ),
      }))
      .catch(() => defaultStats);
  };

export { getAveragePriceForCheapAccommodation };
