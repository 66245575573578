import reduce from 'lodash-es/reduce';
import findIndex from 'lodash-es/findIndex';

import {
  DistributionValue,
  reformatDistribution,
} from './reformatDistribution';
import { DistributionOptions, getDistribution } from './getDistribution';

const getTrimmedDistribution = ({
  facet,
  min,
  max,
  rate,
  steps,
  trimPercentage,
}: DistributionOptions & { trimPercentage: number }) => {
  const distribution: DistributionValue[] = reformatDistribution(
    getDistribution({
      facet,
      min,
      max,
      rate,
      steps,
    }),
  );

  // Get value for last included range (limit).
  const limitValue =
    (1 - trimPercentage / 100) * reduce(distribution, (a, b) => a + b.value, 0);

  let accumValue = 0;
  // Get first index of value that covers the limit.
  const limitIndex = findIndex(distribution, bucket => {
    accumValue += bucket.value;

    return accumValue >= limitValue;
  });
  const nextIndex =
    limitIndex + 1 < distribution.length ? limitIndex + 1 : limitIndex;

  const trimmedMin = min;
  const trimmedMax = distribution[nextIndex].range;

  return getDistribution({
    facet,
    min: trimmedMin,
    max: trimmedMax,
    rate,
    steps,
  });
};

export { getTrimmedDistribution };
