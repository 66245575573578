import React from 'react';

import { useMediaQuery, useTheme } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import { Typography } from '@hbf/dsl/core';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@hbf/dsl/legacy';

import { useIntl } from 'ha/i18n';

import { RebrandThemeProvider } from 'ha/modules/ThemeProvider/RebrandThemeProvider';
import { CloseIcon } from 'ha/ui/Icons';

import { LoadableSearchForm } from './SearchForm.lazy';
import { InitialValues } from './types';

const useStyles = makeStyles()(theme => ({
  closeButton: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    position: 'static',
  },
  dialog: {
    position: 'absolute',
    top: 0,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(5),
    paddingLeft: theme.spacing(5),
  },
  content: {
    padding: theme.spacing(5, 0),
    overflowY: 'hidden',
  },
}));

export const SearchDialog = ({
  isDialogOpen,
  setIsDialogOpen,
  initialValues,
  initialNumberOfListings,
}: {
  isDialogOpen: boolean;
  setIsDialogOpen: (b: boolean) => void;
  initialValues: InitialValues;
  initialNumberOfListings?: number;
}) => {
  const { classes } = useStyles();
  const { T } = useIntl();
  const theme = useTheme();
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <RebrandThemeProvider>
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        maxWidth="lg"
        fullWidth
        fullScreen={!isLargerThanMd}
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle className={classes.titleContainer}>
          <Typography
            variant={
              isLargerThanMd
                ? 'heading/desktop/h3-semibold'
                : 'heading/mobile/h3-semibold'
            }
          >
            {T('search.popup.header')}
          </Typography>
          <IconButton
            className={classes.closeButton}
            aria-label={T('close')}
            onClick={() => {
              setIsDialogOpen(false);
            }}
            size="large"
            color="secondary"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <LoadableSearchForm
            initialValues={initialValues}
            initialNumberOfListings={initialNumberOfListings}
          />
        </DialogContent>
      </Dialog>
    </RebrandThemeProvider>
  );
};
