import { CanonicalCollections } from 'ha/constants/SearchPageCollections';

export const citiesInfoEnglish = {
  '': {
    Berlin: [
      {
        question: 'Accommodation in Berlin, Germany',
        answer: `
        If you're looking to rent accommodation in Berlin, then you've come to the right place. Due to rising demand, houses for rent in Berlin remain the most searched ones on HousingAnywhere. You can easily find new rental homes in Berlin near you and find more information about their size, price, availability, services and amenities. <br/><br/>
        Search through our website and use our search filters to find all homes for rent in Berlin. If you are on the lookout for housing in Berlin Germany, then you will find a range of affordable options to choose from. Due to the standards of living and increased international mobility, the rental properties in Berlin are always increasing in demand. <br/><br/>
        If you’re wondering how you can find properties to rent in Berlin then look no further, as HousingAnywhere is your go-to source for finding accommodations in Berlin Germany from both professional agencies and private owners. <br/><br/>
        `,
      },
      {
        question: 'What is the Average Rent in Berlin, Germany?',
        answer: `The average rent price in Berlin is 1150 EUR (1245 USD) a month. You will find rental prices to range between 9000 to 20000 EUR (9750 to 21650 USD) per year.`,
      },
      {
        question: 'Why is rent so expensive in Berlin, Germany?',
        answer: `Rent in Berlin is expensive because of the housing shortage. More people are looking for housing in Berlin than the number of houses available for rent.`,
      },
      {
        question: 'How to find an accommodation in Berlin, Germany?',
        answer: `If you are looking for accommodation in Berlin, then look online for accommodation rental sites like HousingAnywhere. It’s an easy and secure way of finding housing in Berlin.`,
      },
      {
        links: [
          {
            label: 'Berlin apartments for rent',
            url: 'https://housinganywhere.com/s/Berlin--Germany/apartment-for-rent',
          },
          {
            label: 'Berlin studio apartments',
            url: 'https://housinganywhere.com/s/Berlin--Germany/studio-for-rent',
          },
          {
            label: 'Student accommodation berlin',
            url: 'https://housinganywhere.com/s/Berlin--Germany/student-accommodation',
          },
          {
            label: 'Cheap accommodation in berlin',
            url: 'https://housinganywhere.com/s/Berlin--Germany/cheap-accommodation',
          },
          {
            label: '1 bedroom apartment in berlin',
            url: 'https://housinganywhere.com/s/Berlin--Germany/one-bedroom-apartments',
          },
          {
            label: '2 bedroom apartment in berlin',
            url: 'https://housinganywhere.com/s/Berlin--Germany/two-bedroom-apartments',
          },
          {
            label: 'Furnished apartments in berlin',
            url: 'https://housinganywhere.com/s/Berlin--Germany/furnished-apartments',
          },
          {
            label: 'Long term rentals in berlin',
            url: 'https://housinganywhere.com/s/Berlin--Germany/long-term-rentals',
          },
          {
            label: 'Private room in berlin',
            url: 'https://housinganywhere.com/s/Berlin--Germany/private-rooms',
          },
          {
            label: 'Shared room in berlin',
            url: 'https://housinganywhere.com/s/Berlin--Germany/shared-rooms',
          },
          {
            label: 'Student room in berlin',
            url: 'https://housinganywhere.com/s/Berlin--Germany/student-accommodation',
          },
        ],
      },
    ],
    Lisbon: [
      {
        question: 'Homes for rent in Lisbon, Portugal',
        answer: `
        Looking for homes for rent in Lisbon? Visit HousingAnywhere to explore our verified listings and rent a home in Lisbon that is a perfect fit for you! Discover available rental properties in Lisbon and filter your search by location, property type and amenities that suit your needs. Being one of the most vibrant and charismatic cities in Europe, Lisbon attracts expats from all around the world. With our great selection of homes and an easy-to-use interface, HousingAnywhere makes it easy and convenient to find rentals in Lisbon, Portugal. <br/><br/>
        It is always a good idea to look into neighbourhoods before you start looking for a house for rent in Lisbon. Knowing the neighbourhoods will help you select the most suitable and convenient location close to nearby amenities. If you are seeking privacy or moving with your partner altogether, then renting a house in Lisbon can definitely be your dream destination. Prices for rent in Lisbon, Portugal, may vary depending on the area you select, as places close to the city center will always have stronger demand from renters. <br/><br/>
        HousingAnywhere will ensure that your experience with your house search remains simple and carefree. If you are abroad but looking for homes for rent in Lisbon, Portugal, before your arrival, with HousingAnywhere, you can do it securely. You can rent a house in Lisbon months in advance and be eligible for a full refund of your booking within 48 hours after moving in if the property does not look as it is listed. <br/><br/>
      `,
        links: [
          {
            label: 'Lisbon apartments for rent',
            url: 'https://housinganywhere.com/s/Lisbon--Portugal/apartment-for-rent',
          },
          {
            label: 'Studios for rent in Lisbon',
            url: 'https://housinganywhere.com/s/Lisbon--Portugal/studio-for-rent',
          },
          {
            label: 'Rooms for rent in Lisbon',
            url: 'https://housinganywhere.com/s/Lisbon--Portugal/private-rooms',
          },
          {
            label: 'Shared rooms for rent Lisbon',
            url: 'https://housinganywhere.com/s/Lisbon--Portugal/shared-rooms',
          },
          {
            label: '1 bedroom apartment in Lisbon',
            url: 'https://housinganywhere.com/s/Lisbon--Portugal/one-bedroom-apartments',
          },
          {
            label: '2 bedroom apartment in Lisbon',
            url: 'https://housinganywhere.com/s/Lisbon--Portugal/two-bedroom-apartments',
          },
          {
            label: 'Furnished apartments in Lisbon',
            url: 'https://housinganywhere.com/s/Lisbon--Portugal/furnished-apartments',
          },
          {
            label: 'Long term rentals in Lisbon',
            url: 'https://housinganywhere.com/s/Lisbon--Portugal/long-term-rentals',
          },
          {
            label: 'Student housing in Lisbon',
            url: 'https://housinganywhere.com/s/Lisbon--Portugal/student-accommodation',
          },
          {
            label: 'Cheap accommodation In Lisbon',
            url: 'https://housinganywhere.com/s/Lisbon--Portugal/cheap-accommodation',
          },
          {
            label: 'Student rooms in Lisbon',
            url: 'https://housinganywhere.com/s/Lisbon--Portugal/student-room',
          },
        ],
      },
    ],
  },
  [CanonicalCollections.ApartmentForRent]: {
    Paris: [
      {
        question: 'Apartments for rent in Paris, France',
        answer: `
        Are you an expat looking to rent an apartment in Paris? With HousingAnywhere, you can find Paris apartments for rent within your budget. The earlier you start, the higher your chances of getting your dream rental that matches your preference. Ideally, you start 3–4 months before your planned trip. With up-to-date listings and user-friendly interface, our platform makes it easy to find apartment <a href="https://housinganywhere.com/s/Paris--France">rentals in Paris</a> without any stress. <br/><br/>
        With HousingAnywhere, you can look for apartments for rent in Paris from abroad. If you already know what you are looking for, you can filter your search based on the property’s size, facilities, and amenities it offers. If you want more information about the property, HousingAnywhere allows you to contact the landlord directly through the platform to answer your queries and questions. <br/><br/>
        If the property you’ve rented differs significantly from the listing, Don’t worry! You are always secured when you rent apartment in Paris, France through HousingAnywhere. If the property does not match with the listing, you can cancel your booking within 48 hours after moving in and be fully refunded. Find and rent flat in Paris in Paris easily and carefree with HousingAnywhere. <br/><br/>
      `,
      },
    ],
    Milan: [
      {
        question: 'Apartments for rent in Milan, Italy',
        answer: `
        Looking for apartments for rent in Milan? Look no further! HousingAnywhere offers a wide range of options for Milan apartments for rent matching your preference. With our user-friendly platform with its seamless interface, you can easily explore wide-range of rental <a href="https://housinganywhere.com/s/Milan--Italy/apartment-for-rent">apartments in Milan</a> matching your budget and renting period. <br/><br/>
        HousingAnywhere is the best website to find apartments for rent in Milan from private owners. Our platform lets you filter apartment <a href="https://housinganywhere.com/s/Milan--Italy">rentals in Milan</a> based on size, price, location, facilities, and amenities. If you’re abroad and want to rent an apartment in Milan without viewing, you can do it simply and stress-free. Every listing on our platform has detailed information and original pictures showcasing what to expect from the property. If you need more information, you can contact the landlord directly and get answers to your queries and questions. <br/><br/>
        If you rent a flat in Milan and notice that it is not as listed as advertised, you are eligible for a full refund of your booking within 48 hours after the move-in date. With HousingAnywhere, you can book your rental fast and securely and have a stress-free rental journey! <br/><br/>
      `,
      },
    ],
    Lisbon: [
      {
        question: 'Apartments for rent in Lisbon, Portugal',
        answer: `
        Searching for apartments for rent in Lisbon? with HousingAnywhere, you can explore verified listings if you’re living abroad and rent an apartment in Lisbon online from the comfort of your home. Our user-friendly portal allows you to discover available apartment <a href="https://housinganywhere.com/s/Lisbon--Portugal">rentals in Lisbon</a> directly from private owners. <br/><br/>
        Before searching for rental apartments in Lisbon, Portugal, we highly recommend planning your budget. When your budget is set, you can start prioritising what facilities and amenities are necessary to have for you. At HousingAnywhere, all available apartments for rent in Lisbon have in-depth descriptions of what’s included and what’s not. If the property does not look as it was listed, you will always be secured for a full refund of your booking within 48 hours after moving in. <br/><br/>
        Apartments for rent in Lisbon are always in demand, so if you wish to book the property then do it at least 3–4 months in advance. At HousingAnywhere, you will find various apartments to rent in Lisbon, making it easy to find listings based on your preferences. Our user-friendly platform will help you rent a flat in Lisbon easily and carefree! <br/><br/>
      `,
      },
    ],
  },
  [CanonicalCollections.FurnishedApartments]: {
    Berlin: [
      {
        question: 'Furnished apartments for rent in Berlin, Germany',
        answer: `
        Searching for furnished apartments in Berlin can be challenging, but HousingAnywhere makes it easy for you to find furnished <a href="https://housinganywhere.com/s/Berlin--Germany">rentals in Berlin</a> online in minutes. Browse through Germany’s best website to find furnished <a href="https://housinganywhere.com/s/Berlin--Germany/apartment-for-rent">apartments in Berlin</a>, and choose from a great selection of fully-furnished listings that perfectly match your needs. <br/><br/>
        HousingAnywhere offers a wide range of furnished flats in Berlin with everything you require to feel at home. Our listings include all the specifics of the properties including the size, number of rooms, facilities, amenities, distance from city centre, pictures, and costs. If you are looking for furnished apartment rentals in Berlin while abroad and cannot view the property, you can secure your desired listing without worrying. With our 48-hour safeguard, you can get a full refund and support if the property differs from what is described. <br/><br/>
        When searching for your new home, you will experience a high demand for furnished apartments for rent in Berlin, which is why the earlier you start, the more options you will have. Ideally, you start looking for your new home 3–4 months in advance. Once you find what you are looking for, you can communicate with the landlord through our platform and ask all the necessary questions before confirming your booking. So if you are looking for a fast and easy way to rent a furnished apartment in Berlin, look no further than HousingAnywhere. <br/><br/>
      `,
      },
    ],
    Munich: [
      {
        question: 'Furnished apartments for rent in Munich, Germany',
        answer: `
        Moving to a new city can be stressful, especially when you can’t find serviced rentals matching your requirements and renting periods. HousingAnywhere lets you find furnished <a href="https://housinganywhere.com/s/Munich--Germany/apartment-for-rent">apartments for rent in Munich</a> that are fully-furnished and ready to move. If you’re moving abroad and cannot view the property. you can simply book a furnished apartment in Munich online from the comfort of your home. HousingAnywhere is the best website to find furnished apartments in Munich that lets you explore wide-range of fully-furnished listings matching your needs. <br/><br/>
        Our platform allows you to customise your search so you will only see furnished flats in Munich that are relevant to your preferences. You can select what price range fits your budget, how many square metres will make you feel comfortable, and what amenities should be in furnished <a href="https://housinganywhere.com/s/Munich--Germany">rentals in Munich</a> to fit your needs. Furnished apartments for rent in Munich and other German cities are in high demand. We suggest you look for your new home 3–4 months in advance. <br/><br/>
        With HousingAnywhere, you can easily rent a furnished apartment in Munich, Germany, while abroad. Our listings include in-depth information about each property, and you also have pictures to look at for a better understanding. If the property differs from what was described, our 48-hour safeguard makes you eligible for a full refund of your booking. <br/><br/>
      `,
      },
    ],
    Vienna: [
      {
        question: 'Furnished apartments for rent in Vienna, Austria',
        answer: `
        Looking for furnished apartments for rent in Vienna? Look no further than HousingAnywhere where you can rent furnished <a href="https://housinganywhere.com/s/Vienna--Austria/apartment-for-rent">apartments in Vienna</a> that you can call your second home. Our user-friendly search filters allow you to book Vienna furnished apartments online with ease and convenience. Browse through our verified listings of fully furnished <a href="https://housinganywhere.com/s/Vienna--Austria">rentals in Vienna</a> that perfectly match your budget. <br/><br/>
        Having a set budget and your preferences while finding furnished flats in Vienna will save you time. With HousingAnywhere, you can filter your search based on the property type, location, amenities, and facilities. You could take your pet with you on this journey; in that case, you can also look specifically for pet-friendly furnished rentals in Vienna. <br/><br/>
        Our rental platform connects you to landlords making your rental experience easy and carefree. After sorting the list of available listings and selecting your favourites, you can contact the landlord through our platform and ask for more details. Our platform ensures that you easily find what you are looking for and book the property without needing a visit. So if you are looking for a hassle-free and convenient way to rent a furnished apartment in Vienna, look no further than HousingAnywhere! <br/><br/>
      `,
      },
    ],
  },
  [CanonicalCollections.StudentAccommodation]: {
    Berlin: [
      {
        question: 'Student accommodation in Berlin',
        answer:
          'Student housing in Berlin consists of WGs (shared flats), student dorms, student apartments, or studios. The most popular form of student housing in Berlin is a flatshare (*Wohngemeinschaft*). It offers international students an easy way to make friends and split responsibilities.',
      },
      {
        question: 'Student Dorms v/s student apartments in Berlin',
        answer:
          'Student <a href="https://housinganywhere.com/s/Berlin--Germany/apartment-for-rent">apartments in Berlin</a> are the most common choice as you can have a private room, rent with friends, split all costs, and live in any location you like. University-provided student residences or student dorms in Berlin are difficult to get as the rent tends to be cheaper. But the rooms are very small, have thin walls, come with shared bathrooms, and are offered only for term-time. So, if you like privacy and don\'t want to be kicked out immediately after term ends, student apartments in Berlin are a better option.',
      },
      {
        question: 'How to find student accommodation in Berlin?',
        answer:
          'HousingAnywhere offers a safe and easy way to book <a href="https://housinganywhere.com/s/Berlin--Germany">accommodation in Berlin</a> for international students. Use filters to view furnished student apartments, rooms, or studios, chat with the landlord, and book your student housing in Berlin from abroad. For your safety, HousingAnywhere will keep your first month\'s rent protected until 48 hours after you move in.',
      },
      {
        question: 'How much is student housing in Berlin?',
        answer:
          'On average, student accommodation in Berlin costs €749 per month in 2023. Student apartments in Berlin have a monthly rent of €1,680, but the rent and bills are easily split between roommates. Use our search alerts and filters to find cheap accommodation in Berlin for international students.',
      },
      {
        question: 'Where do students live in Berlin?',
        answer:
          'The best areas to find student accommodation in Berlin include the <a href="https://housinganywhere.com/Berlin--Germany/neighborhood-guide-Berlin">neighbourhoods</a> of Mitte, Neukölln, Friedrichshain, Kreuzberg, and Charlottenburg. These areas are close to university campuses, international, and safe. For cheap accommodation in Berlin for students, you can even look for student housing in Lichtenberg or Tempelhof-Schöneberg if you don\'t mind living in the central areas.',
      },
    ],
    Munich: [
      {
        question: 'Student Accommodation in Munich, Germany',
        answer: `Looking for student accommodation in Munich? Look no further! HousingAnywhere offers a wide range of options for student <a href="https://housinganywhere.com/s/Munich--Germany/apartment-for-rent">apartments in Munich</a>, catering to the rising demand. Our user-friendly search filters allow you to explore various student housing options, ensuring that you find the ideal fit for your needs and budget. <br/><br/>
         Munich boasts a strong reputation for academic excellence, making it an attractive destination for students seeking quality education. Due to the increasing demand, finding suitable and affordable student accommodation in Munich can therefore be challenging. Fortunately, HousingAnywhere simplifies the process by offering a wide range of options. Browse through various cheap apartments in Munich for students and find your perfect fit. <br/><br/>
         Visit our website to secure your ideal student <a href="https://housinganywhere.com/s/Munich--Germany">housing in Munich</a>. With our platform, you'll have all the necessary information to make an informed decision. Start your search for cheap accommodation in Munich for students now and enjoy a seamless renting experience with HousingAnywhere. Don’t miss out on exceptional student housing options in Munich! <br/><br/>
        `,
      },
    ],
    Rotterdam: [
      {
        question: 'Student accommodation in Rotterdam, Netherlands',
        answer: `Are you about to start your studies in Rotterdam and need a place to stay? Finding student <a href="https://housinganywhere.com/s/Rotterdam--Netherlands">housing in Rotterdam</a> can be a daunting task. We suggest starting to look for student residence in Rotterdam as you receive your acceptance letter from the university. Finding student apartments for rent near erasmus university Rotterdam might be challenging, but your chances are high if you start 3-4 months in advance.  <br/><br/>
        HousingAnywhere will help you find student accommodation in Rotterdam within your budget. Our rental platform enables you to find and book housing in Rotterdam for students easily and carefree. You can filter your search based on your preferences, such as the neighbourhood, the price range, the size of the property, and the amenities that the property comes with. <br/><br/>
        If you have yet to move to Rotterdam but want to book your desired property there, with HousingAnywhere, you can do it securely. Our platform lets you contact the landlord directly for more information or additional property pictures. With our 48-hour safeguard, you will be eligible for a full refund of your booking if the property does not look as it was listed. Your search for a student apartment in Rotterdam will be a great start for the joyful experience that awaits you in the Netherlands. <br/><br/>
      `,
      },
    ],
    Amsterdam: [
      {
        question: 'Student accommodation in Amsterdam, Netherlands',
        answer: `
        Looking for student accommodation in Amsterdam? Look no further than HousingAnywhere to find student housing in Amsterdam close to universities and key locations. Due to the increasing number of students planning to study in Europe, the demand for affordable accommodation in Amsterdam for students is increasing significantly. Our platform simplifies this process by offering cheap accommodation in Amsterdam for international students before they move abroad. <br/><br/>
        Once you receive your acceptance letter from the university, we suggest you start looking for student housing right away. If you start looking for student <a href="https://housinganywhere.com/s/Amsterdam--Netherlands/apartment-for-rent">apartments in Amsterdam</a>, Netherlands at least 3–4 months in advance, then you have higher chances to book your residence for the next year or two. Have you already thought of your budget? It is never too late to set up an amount you want to spend and stick to it. Student residence in Amsterdam can be pretty expensive, but having roommates can help you stick to the budget while being a part of a small community sharing similar interests. <br/><br/>
        Looking for <a href="https://housinganywhere.com/s/Amsterdam--Netherlands">housing in Amsterdam</a> for students can take time, but do not worry if you can not find any available property. With HousingAnywhere, you can set up search alerts to know when apartments in Amsterdam for students become available. Don’t miss out on the opportunity to browse through our available listings to find the housing that best matches your preference. <br/><br/>
      `,
      },
    ],
    Paris: [
      {
        question: 'Student accommodation in Paris, France',
        answer: `
        Are you an international student looking for student accommodation in Paris? No need to look any further; HousingAnywhere will help you find a student <a href="https://housinganywhere.com/s/Paris--France/apartment-for-rent">apartment in Paris</a> within your budget. Finding student <a href="https://housinganywhere.com/s/Paris--France">housing in Paris</a> may be challenging, but your chances are high if you start early. Ideally, we suggest looking for a student residence in Paris 3–4 months in advance. <br/><br/>
        Before you start looking for accommodation in Paris for international students, we suggest setting up your budget to save time while searching and avoid overspending. You can filter by price and location and find the most affordable and cheap accommodation in Paris for students. Our platform allows you to filter your search based on the property type, renting period, services and amenities. <br/><br/>
      `,
      },
    ],
    Milan: [
      {
        question: 'Student accommodation in Milan, Italy',
        answer: `
        Are you a student searching for student accommodation in Milan, Italy? Then HousingAnywhere has got you covered! We specialise in providing top-notch options for student <a href="https://housinganywhere.com/s/Milan--Italy">housing in Milan</a>, ensuring a hassle-free experience for you. Whether you seek a student residence or student <a href="https://housinganywhere.com/s/Milan--Italy/apartment-for-rent">apartments in Milan</a>, we have a wide range of choices to fit your needs and budget perfectly. <br/><br/>
        With HousingAnywhere you can kickstart your search for student residence in Milan well in advance. Our platform boasts up-to-date listings and an easy-to-use interface, guaranteeing a stress-free and efficient browsing experience. <br/><br/>
        Finding the right student accommodation in Milan has never been easier with our advanced search filters. You can refine your search based on various factors, such as property size and offered facilities. For any inquiries or additional information about a specific property, our property enables direct communication with the landlord, helping to clarify any doubts before making a decision. <br/><br/>
        At HousingAnywhere, we ensure you remain protected throughout the rental process. Should the student accommodation in Milan deviate from the listing, rest assured, you can count on us. Our 48-hour cancellation policy allows you to request a full refund if the property fails to meet your expectations. Start your search for student housing in Milan today and unlock the door to an unforgettable student experience in this vibrant city! <br/><br/>
      `,
      },
    ],
  },
};

export const citiesInfoGerman = {
  '': {
    Berlin: [
      {
        question: 'Finde dein Haus zur Miete in Berlin, Deutschland',
        answer: `
        Du willst in Berlin ein Haus mieten? Kein Problem! HousingAnywhere bietet eine umfangreiche Auswahl an Mietobjekten, darunter Häuser, die deinen Anforderungen und Vorstellungen entsprechen. Egal ob du ein gemütliches Zuhause in der Nähe des Stadtzentrums suchst oder ein Haus in der Nähe von Berlin mieten willst, wir haben die passenden Angebote für dich! <br/><br/>
        Unsere Plattform bietet dir eine bequeme Möglichkeit, ein Haus in Berlin zu mieten. Mit nur wenigen Klicks hast du Zugriff auf hunderte von Angeboten, die alle wichtigen Details wie Größe, Ausstattung, Lage und Mietkosten enthalten. Wenn du ein Haus zu mieten in Berlin suchst, aber noch nicht ganz sicher bist, welche Gegend der Stadt am besten zu dir passt, bieten wir genügend Informationen über die verschiedenen Stadtteile und deren Vorzüge. <br/><br/>
        Wenn du ein Haus zur Miete in Berlin findest, das dein Interesse weckt, kannst du über HousingAnywhere direkt Kontakt zum Vermieter aufnehmen. Stelle Fragen und kläre Details, um sicherzugehen, dass das Mietobjekt deinen Wünschen entspricht. Unsere Plattform ermöglicht es dir, auch ein möbliertes Haus zur Miete in Berlin zu finden, das möglicherweise genau das bietet, wonach du suchst. <br/><br/>
        Da viele Leute ein Haus in Berlin mieten wollen, empfehlen wir dir, frühzeitig mit der Suche zu beginnen. Je früher du mit der Suche nach deinem neuen Zuhause startest, desto höher sind deine Chancen, das perfekte Haus zur Miete in Berlin zu finden. Starte jetzt deine Suche bei HousingAnywhere und sichere dir dein ideales Haus in Berlin! <br/><br/>
        `,
        links: [
          {
            label: 'wohnung mieten in berlin',
            url: 'https://housinganywhere.com/de/s/Berlin--Deutschland/wohnung-mieten',
          },
          {
            label: 'möblierte wohnung in berlin',
            url: 'https://housinganywhere.com/de/s/Berlin--Deutschland/moeblierte-apartments',
          },
          {
            label: '1 zimmer wohnung in berlin',
            url: 'https://housinganywhere.com/de/s/Berlin--Deutschland/1-zimmer-wohnungen',
          },
          {
            label: '2 zimmer wohnung in berlin',
            url: 'https://housinganywhere.com/de/s/Berlin--Deutschland/2-zimmer-wohnungen',
          },
          {
            label: 'günstige wohnungen in berlin',
            url: 'https://housinganywhere.com/de/s/Berlin--Deutschland/g%C3%BCnstige-unterkunft',
          },
          {
            label: 'wg zimmer in berlin',
            url: 'https://housinganywhere.com/de/s/Berlin--Deutschland/wg-zimmer',
          },
          {
            label: 'studio miete in berlin',
            url: 'https://housinganywhere.com/de/s/Berlin--Deutschland/studios',
          },
          {
            label: 'langzeitmiete in berlin',
            url: 'https://housinganywhere.com/de/s/Berlin--Deutschland/langzeitmiete',
          },
          {
            label: 'studentenwohnheim in berlin',
            url: 'https://housinganywhere.com/de/s/Berlin--Deutschland/studentenunterkunft',
          },
          {
            label: 'studenten wg in berlin',
            url: 'https://housinganywhere.com/de/s/Berlin--Deutschland/studentenzimmer',
          },
        ],
      },
    ],
  },
  [CanonicalCollections.ApartmentForRent]: {
    Berlin: [
      {
        question: 'Finde eine Wohnung zur Miete in Berlin, Deutschland',
        answer: `
          Du willst in Berlin eine Wohnung mieten? Dann bist du bei uns genau richtig. HousingAnywhere präsentiert dir eine exklusive Auswahl an erstklassigen Mietwohnungen in Berlin, die eine Vielfalt an Bedürfnissen abdecken. Ganz gleich, ob du ein kleines Apartment in Berlin mieten willst oder dich eine größere Wohnung in den Vororten anspricht - wir haben das ideale Angebot für dich. <br/><br/>
          Unsere Plattform bietet dir die Möglichkeit, schnell und einfach Mietwohnungen in Berlin zu finden. HousingAnywhere stellt dir zahlreiche attraktive Angebote zur Verfügung, die alle wichtigen Informationen enthalten. Dank unserer praktischen Suchfilter kannst du gezielt nach deinen Wünschen filtern. Egal ob du eine <a href="https://housinganywhere.com/de/s/Berlin--Deutschland/moeblierte-apartments">möblierte wohnung in berlin mieten</a> willst, oder eine bestimmte Größe bevorzugst, mit unseren Suchfiltern kannst du ganz einfach eine Mietwohnung in Berlin finden, die deinen Bedürfnissen entspricht. <br/><br/>
          Bei HousingAnywhere steht nicht nur die Wohnung im Fokus, sondern auch die Umgebung. Wir wollen, dass du dich an deinem neuen Wohnort und in deiner neuen Berliner Wohnung wohlfühlst. Deshalb bieten wir Informationen über die verschiedenen Stadtteile Berlins. So kannst du eine fundierte Entscheidung treffen und sicher sein, dass deine neue Wohnung in Berlin in einer Umgebung liegt, die dir gefällt. <br/><br/>
          HousingAnywhere bietet dir die Möglichkeit, direkt über die Plattform mit Vermietern zu kommunizieren, um alle deine Fragen vor der Buchung zu klären. Du willst heute deine Wohnung in Berlin finden? Verpasse nicht deine Chance! Beginne jetzt deine Wohnung in Berlin zu suchen und finde deine Traumwohnung in der deutschen Hauptstadt. <br/><br/>
        `,
      },
    ],
  },
  [CanonicalCollections.FurnishedApartments]: {
    Berlin: [
      {
        question:
          'Finde deine möblierte Wohnung zur Miete in Berlin, Deutschland',
        answer: `
          Du wünschst dir in Berlin eine möblierte Wohnung? Bei HousingAnywhere findest du eine große Auswahl an möblierten <a href="https://housinganywhere.com/de/s/Berlin--Deutschland/wohnung-mieten">Wohnungen in Berlin</a>. Sei es ein Studio, ein kleines Apartment oder eine geräumige Wohnung, bei uns findest du dein neues Zuhause. <br/><br/>
          Du willst eine möblierte Wohnung mieten, um Berlin ohne Möbeltransporter aufzusuchen? Egal, ob du nur einige Monate in der Stadt bist oder einen langfristigen Aufenthalt planst, wir haben die perfekte möblierte Wohnung in Berlin für dich. <br/><br/>
          Mit unseren benutzerfreundlichen Filtern ist die Suche nach deiner Traumwohnung so einfach wie nie. Gib einfach die gewünschte Größe, Zimmeranzahl und die gewünschte Ausstattung an, und schon präsentieren wir dir eine handverlesene Auswahl an möblierten Wohnungen in Berlin, die genau deinen Vorstellungen entsprechen. <br/><br/>
          Bei HousingAnywhere kannst du deine möblierte Wohnung in Berlin direkt über unsere Plattform buchen. Mit nur wenigen Klicks sicherst du dir deine Traumwohnung und kannst dich auf einen reibungslosen Umzug freuen. Entdecke unsere Inserate und finde schon bald die ideale möblierte Wohnung in Berlin. <br/><br/>
        `,
      },
    ],
  },
  [CanonicalCollections.StudioForRent]: {
    Berlin: [
      {
        question: 'Finde dein Studio zur Miete in Berlin, Deutschland',
        answer: `
          Du willst ein gemütliches Studio in Berlin mieten? Dann bist du bei uns richtig. HousingAnywhere bietet eine breite Auswahl an Studios zur Miete - möbliert oder unmöbliert. Wir verstehen, dass jeder Mieter unterschiedliche Bedürfnisse hat, und deshalb bieten wir dir die Flexibilität, das Studio zu wählen, das perfekt zu dir passt. <br/><br/>
          Unsere Plattform ermöglicht es dir, gezielt nach einer Studio Wohnung in Berlin zu suchen, das deinen Anforderungen entspricht. Nutze unsere praktischen Suchfilter, um die Studios in Berlin zu finden, die deinen Wünschen entsprechen. Ob du nach einer bestimmten Größe suchst oder dir eine besondere Ausstattung wünschst, mit unseren Suchfiltern findest du schnell und einfach eine Studio <a href="https://housinganywhere.com/de/s/Berlin--Deutschland/wohnung-mieten">Wohnung in Berlin</a>. <br/><br/>
          Willst du eine Studio Wohnung in Berlin mieten und dich um keinen Möbeltransport kümmern müssen? Dann sind unsere möblierten Studios gerade richtig für dich. Egal ob du in der Nähe der Universität wohnen möchtest, oder ein beruflicher Neuankömmling, der ein komfortables Zuhause sucht - bei HousingAnywhere kannst du dein perfektes Studio in Berlin mieten. Für diejenigen, die ihren persönlichen Stil einbringen möchten, bieten wir auch unmöblierte Studios an. Diese ermöglichen dir, deine eigenen Möbel und Dekoration zu wählen, um eine individuelle Wohnatmosphäre zu schaffen. <br/><br/>
          Du willst noch heute in Berlin dein Studio mieten? Verpasse nicht die Gelegenheit, dein perfektes Studio in Berlin zu mieten! Erkunde unsere Inserate und kontaktiere den Vermieter direkt auf der Plattform, um Fragen abzuklären. Bei HousingAnywhere findest du garantiert dein neues Studio zur Miete in Berlin, das deine Bedürfnisse und deinen Lebensstil widerspiegelt. <br/><br/>
        `,
      },
    ],
  },
  [CanonicalCollections.CheapAccommodation]: {
    Berlin: [
      {
        question:
          'Finde eine günstige Wohnung zur Miete in Berlin, Deutschland',
        answer: `
          Du suchst günstige Wohnungen in Berlin? Dann bist du bei HousingAnywhere richtig. Wir bieten eine große Auswahl an günstigen Mietwohnungen in Berlin, die deinen Ansprüchen gerecht werden. Von kleinen Wohnungen, gemütlichen Studios bis hin zu geräumigen Wohnungen - wir haben das passende Angebot. <br/><br/>
          Finde dank unserer Suchfilter günstige <a href="https://housinganywhere.com/de/s/Berlin--Deutschland/wohnung-mieten">Mietwohnungen in Berlin</a>, die genau deinen Vorstellungen entsprechen. Du kannst nicht nur die gewünschte Größe der Wohnung angeben, sondern auch die Anzahl der Zimmer, den Preis und die gewünschte Ausstattung. <br/><br/>
          Hast du deine Traumwohnung gefunden, kannst du sie direkt über unsere Plattform buchen und sparst dir somit zeitaufwändige Besichtigungen. Wir machen den Buchungsprozess so einfach wie möglich, damit du schnell in deine günstige Mietwohnung in Berlin einziehen kannst und das Leben in der Hauptstadt genießen kannst. Du hast Fragen zum Inserat? Kein Problem! Du kannst auf der Plattform mit dem Vermieter in Kontakt treten und Einzelheiten klären, bevor du verbindlich buchst. <br/><br/>
          Wir wissen, dass es schwierig sein kann, billige Wohnungen in Berlin zu finden, deshalb solltest du so früh wie möglich mit der Suche beginnen. Entdecke unser breites Angebot an günstigen Wohnungen in Berlin und finde das perfekte Zuhause, das zu dir passt. Ergreife die Chance und miete dein Traumzuhause über HousingAnywhere. <br/><br/>
        `,
      },
    ],
  },
  [CanonicalCollections.LongTermRentals]: {
    Berlin: [
      {
        question:
          'Finde deine Wohnung zur Langzeitmiete in Berlin, Deutschland',
        answer: `
          Du bist auf der Suche nach einer Wohnung für eine Langzeitmiete in Berlin? Bei HousingAnywhere findest du die perfekte Lösung für deine Langzeitmiete in der Hauptstadt! Ganz egal ob du eine Wohnung für mehrere Monate, ein Zimmer oder eine gemütliche Unterkunft suchst - bei uns findest du bestimmt etwas Passendes! <br/><br/>
          Unser Angebot an Inseraten für Langzeitmieten in Berlin ist vielfältig und umfasst unterschiedliche Arten von Unterkünften. Du kannst beispielsweise zwischen Studios, Mehrbettzimmern oder Wohnungen wählen - alles ganz nach deinen Wünschen und deinem Budget. Unsere Wohnungen bieten dir den Komfort, den du für dein langfristiges Zuhause benötigst. <br/><br/>
          Um dir deine Suche nach einer Wohnung zur Langzeitmiete in Berlin so einfach wie möglich zu gestalten, haben wir praktische Suchfilter auf unserer Plattform integriert. Du kannst gezielt nach deinen Wünschen filtern, sei es nach Größe der Unterkunft oder der Ausstattung. So findest du schnell und bequem die perfekte Langzeitunterkunft in Berlin für dich. <br/><br/>
          Die Buchung deiner Wohnung für die Langzeitmiete in Berlin bei HousingAnywhere ist stressfrei und bequem. Du kannst die Unterkunft ganz einfach von zuhause aus buchen und somit Zeit sparen. Solltest du Fragen zu einem Inserat haben, kannst du direkt über die Plattform mit dem Vermieter in Kontakt treten und alle Details klären. Wir stehen dir bei jedem Schritt zur Seite, damit du deine Traumwohnung für die Langzeitmiete findest. <br/><br/>
          Mit HousingAnywhere findest du deine ideale Wohnung zur Langzeitmiete in Berlin - einfach, schnell und sicher. Entdecke jetzt unsere Inserate und finde deine langfristige Unterkunft in dieser Metropole. Starte deine Suche und sichere dir dein neues Zuhause bei HousingAnywhere. <br/><br/>
        `,
      },
    ],
  },
  [CanonicalCollections.StudentAccommodation]: {
    Berlin: [
      {
        question: 'Finde deine Studentenunterkunft in Berlin, Deutschland',
        answer: `
          Du bist auf der Suche nach der perfekten Studentenwohnung in Berlin? Dabei können wir dir helfen! Wir bieten eine vielfältige Auswahl an hochwertigen Studentenappartements in Berlin  oder Studios, die speziell auf die Bedürfnisse von Studierenden zugeschnitten sind. Günstige Studentenwohnungen in Berlin sind oft schwer zu finden, doch wir erleichtern dir die Suche. <br/><br/>
          Benutze unsere Suchfilter, um spielend leicht die perfekte Unterkunft zu finden, sei es eine möblierte Studenten <a href="https://housinganywhere.com/de/s/Berlin--Deutschland/wohnung-mieten">wohnung in Berlin</a> oder ein Studio, das für Studenten verfügbar ist - bei uns findest du bestimmt etwas Passendes! Auf HousingAnywhere kannst du deine Suchergebnisse nach Preis, Größe, Ausstattung und Anzahl der Zimmer filtern, um deine perfekte Studentenunterkunft in Berlin im Handumdrehen zu entdecken. <br/><br/>
          HousingAnywhere stellt sicher, dass du den Vermieter auf transparente und geschützte Weise kontaktieren kannst. Deswegen hast du die Möglichkeit direkt über die Plattform mit dem Vermieter zu kommunizieren, um Details abzuklären, bevor du in Berlin deine Studentenwohnung buchst. <br/><br/>
          Es ist ratsam, frühzeitig günstige Wohnungen für Studenten in Berlin zu suchen, da die Stadt bei Studierenden besonders beliebt ist und daher die Nachfrage nach Wohnungen für Studenten in Berlin dementsprechend hoch ist. Starte noch heute mit deiner Suche nach Studentenwohnungen in Berlin und finde schon bald dein neues Zuhause! <br/><br/>
        `,
      },
    ],
  },
  [CanonicalCollections.StudentRoom]: {
    Berlin: [
      {
        question: 'Finde eine Studenten wg in Berlin, Deutschland',
        answer: `
          Du bist auf der Suche nach einem gemütlichen Studentenzimmer in Berlin, um während deines Studiums eine angenehme Zeit zu verbringen? Wir bei HousingAnywhere haben die passenden Inserate für dich! Die Suche nach einem passenden Studentenzimmer oder einer  Studenten-WG in Berlin kann oft eine Herausforderung sein, aber mit HousingAnywhere wird sie zum Kinderspiel. Unsere Plattform bietet eine Vielzahl von Optionen für Studenten zimmer in Berlin. Sobald du eine Unterkunft gefunden hast, kannst du sie direkt online buchen. <br/><br/>
          Alle Inserate auf HousingAnywhere enthalten detaillierte Beschreibungen, damit du genau weißt, was dich erwartet. Hast du trotzdem noch Fragen zur Unterkunft, kannst du über die Plattform direkt mit dem Vermieter kommunizieren. Unsere praktischen Suchfilter machen die Suche nach einem Zimmer in einer Studenten-WG in Berlin noch einfacher. Du kannst nach deinem bevorzugten Preisrahmen filtern, die gewünschte Ausstattung angeben und die Zimmergröße festlegen. <br/><br/>
          Du willst so schnell wie möglich in Berlin eine Studenten WG finden? Mit HousingAnywhere sparst du Zeit und Mühe bei der Wohnungssuche und kannst dich ganz auf dein Studium konzentrieren. Unsere Plattform bietet dir eine sichere und zuverlässige Möglichkeit, in Berlin ein Studentenzimmer zu finden. Starte jetzt deine Suche nach deinem Zimmer in einer Studenten <a href="https://housinganywhere.com/de/s/Berlin--Deutschland/wg-zimmer">Wg in Berlin</a>! <br/><br/>
        `,
      },
    ],
  },
  [CanonicalCollections.PrivateRoomForRent]: {
    Berlin: [
      {
        question: 'Finde dein WG Zimmer in Berlin, Deutschland',
        answer: `
          Du suchst nach einem passenden WG Zimmer in Berlin? Bei HousingAnywhere findest du zahlreiche Inserate, die dir die WG Suche in Berlin vereinfachen. Wir bieten dir eine umfangreiche Auswahl an gemütlichen und komfortablen WG Zimmern, aus denen du ganz bequem dein ideales Zuhause auswählen kannst. <br/><br/>
          Egal ob du eine preiswerte Unterkunft sucht oder ein beruflicher Neuankömmling bist, der gerne neue Leute kennenlernen will, wir haben das perfekte WG Zimmer in Berlin für dich. Das Zusammenleben in einer WG in Berlin bietet nicht nur eine ideale Wohnmöglichkeit, sondern auch die Möglichkeit, neue Freundschaften zu knüpfen und eine einzigartige Gemeinschaft zu erleben. <br/><br/>
          Jedes WG Zimmer wird mit detaillierten Informationen und hochwertigen Bildern präsentiert, damit du bereits im Voraus einen realistischen Eindruck gewinnen kannst. Du willst schnell und einfach eine WG suchen in Berlin? Unsere Plattform ermöglicht es dir, gezielt nach WG Zimmern zu suchen, die deinen Wünschen und deinem Budget entsprechen. Nutze praktische Suchfilter, um die Ergebnisse deinen Bedürfnissen anzupassen. Du kannst nicht nur nach der Größe des Zimmers filtern, sondern auch nach der Ausstattung, um das perfekte Zimmer in einer WG in Berlin zu finden. <br/><br/>
          HousingAnywhere bietet dir die Möglichkeit, schon vorab in direkten Austausch mit den Vermietern des WG Zimmers zu treten. So kannst du etwaige Fragen klären und möglicherweise deine potenziellen Mitbewohner etwas näher kennenlernen. Beginne noch heute in Berlin eine WG zu suchen und finde dein zukünftiges Zuhause! <br/><br/>
        `,
      },
    ],
  },
  [CanonicalCollections.OneBedroomApartments]: {
    Berlin: [
      {
        question:
          'Finde deine 1-Zimmer-Wohnung zur Miete in Berlin, Deutschland',
        answer: `
            Du möchtest in Berlin eine 1-Zimmer-Wohnung mieten? Bei HousingAnywhere findest du eine vielfältige Auswahl an 1-Zimmer-Wohnungen in verschiedenen Stadtteilen der deutschen Hauptstadt, damit du dein ideales Zuhause finden kannst. Egal ob du auf der Suche nach einer kompakten und dennoch komfortablen Wohnung bist - unsere Plattform hält die besten Inserate in Berlin für dich bereit. <br/><br/>
            HousingAnywhere bietet die Möglichkeit, eine möblierte 1-Zimmer-Wohnung in Berlin zu mieten, um dir den Stress eines Möbeltransports zu ersparen. Mit nur wenigen Klicks kannst du dank praktischer Suchfilter die Inserate auswählen, die deinen Ansprüchen und deinem Budget am besten entsprechen und eine 1-Zimmer <a href="https://housinganywhere.com/de/s/Berlin--Deutschland/wohnung-mieten">Wohnung in Berlin mieten</a>. <br/><br/>
            Mit unserer benutzerfreundlichen Plattform kannst du mühelos die perfekte Einzimmerwohnung in Berlin finden. Vergiss lange Wartezeiten und zeitraubende Besichtigungstermine. Mit HousingAnywhere kannst du von zu Hause aus stöbern, Inserate vergleichen und deine Traumwohnung in Berlin finden. <br/><br/>
            HousingAnywhere gibt dir die Möglichkeit, mit dem Vermieter direkt über die Plattform Kontakt aufzunehmen, um alle deine Fragen vor der Buchung zu beantworten. Starte jetzt deine Suche nach einer 1-Zimmer-Wohnung in Berlin und entdecke die vielen Optionen, die diese aufregende Stadt zu bieten hat. <br/><br/>
        `,
      },
    ],
  },
  [CanonicalCollections.TwoBedroomApartments]: {
    Berlin: [
      {
        question:
          'Finde deine 2-Zimmer-Wohnung zur Miete in Berlin, Deutschland',
        answer: `
          Willst du eine 2-Zimmer-Wohnung in Berlin mieten? Dann bist du bei uns richtig. Bei HousingAnywhere findest du deine ideale Zweizimmerwohnung in Berlin! Unser Angebot umfasst traumhafte Wohnungen in verschiedenen Stadtteilen der Stadt, die du direkt über unsere Plattform mieten kannst. Egal ob du eine zentral gelegene Zweizimmerwohnung in Berlin suchst oder ein ruhiges Apartment am Stadtrand, wir haben die perfekte Lösung für dich. Mit HousingAnywhere wird die Wohnungssuche zum Kinderspiel. Dank hochwertiger Fotos der Wohnungen und verschiedener Suchfilter findest du schnell die passende 2-Zimmer-Wohnung in Berlin, die deinen Wünschen entspricht. <br/><br/>
          Du hast keine Zeit für eine Besichtigung? Kein Problem! Bei HousingAnywhere kannst du dich auf einen reibungslosen Ablauf verlassen. Keine lästigen Besichtigungstermine mehr, denn du kannst deine 2-Zimmer-Wohnung in Berlin mieten indem du sie direkt online auf unserer Plattform buchst.. <br/><br/>
          Unsere Plattform bietet dir nicht nur eine große Auswahl an Wohnungen, sondern auch die Möglichkeit direkt in Kontakt mit dem Vermieter zu treten, um offene Fragen zu klären und Details der Wohnung zu besprechen. Starte dein neues Kapitel mit einer traumhaften 2-Zimmer <a href="https://housinganywhere.com/de/s/Berlin--Deutschland/wohnung-mieten">Wohnung in Berlin</a>. Besuche unsere Website und entdecke unsere Inserate noch heute. <br/><br/>
        `,
      },
    ],
  },
};
