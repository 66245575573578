import React from 'react';
import { alpha } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import { useIntl } from 'ha/i18n';
import { Link, Typography, Grid, Button, units } from '@hbf/dsl/legacy';
import { Kamernet } from '@hbf/icons/custom';
import {
  BuildingNature,
  InterfaceArrowsRight,
} from '@hbf/icons/streamline-bold';
import { SmartTrack as Track } from 'ha/components/track/SmartTrack';
import { sharpSansBold } from 'ha/constants/fonts/sourceSansHeaders';
import { useMediaQuery } from 'ha/helpers/MediaQuery';

import { kamernetCitiesRedirect } from './constants';

const useStyles = makeStyles()(theme => ({
  card: {
    position: 'relative',
    padding: units.rem(0.75),
    background: '#04163C',
    borderRadius: '4px',
  },
  text: {
    fontFamily: sharpSansBold.fontFamily,
    fontSize: units.rem(1.25),
    marginLeft: units.rem(4.3),

    [theme.breakpoints.down('lg')]: {
      marginBottom: units.rem(0.8),
      marginLeft: 0,
    },
  },
  button: {
    backgroundColor: '#59AC81',
    color: theme.palette.common.white,
    fontSize: units.rem(1.125),
    textTransform: 'none',
    borderRadius: '0 54px 54px 40px',
    padding: units.trbl(0.375, 1, 0.375, 1.3125),

    [theme.breakpoints.down('lg')]: {
      fontSize: units.rem(1),
      padding: units.yx(0.3, 1),
    },
  },
  kamernetLogo: {
    marginLeft: units.rem(0.45),
    width: '83px',

    [theme.breakpoints.down('lg')]: {
      width: '72px',
    },
  },
  buildingIcon: {
    position: 'absolute',
    bottom: 0,
    left: units.rem(0.8),
    color: alpha('#6ACF9E', 0.4),
    fontSize: units.rem(3.06),

    [theme.breakpoints.down('lg')]: {
      fontSize: units.rem(3.625),
      right: units.rem(0.75),
      left: 'initial',
    },
  },
  arrowIcon: {
    fontSize: units.rem(1.125),
    marginLeft: units.rem(0.75),
  },
}));

interface Props {
  cityCanonical: string;
  cityLocalized: string;
}

const KamernetBanner: React.FC<Props> = ({ cityCanonical, cityLocalized }) => {
  const { T, urlResolver } = useIntl();
  const { classes } = useStyles();
  const { lg } = useMediaQuery();

  if (!kamernetCitiesRedirect.includes(cityCanonical.toLowerCase())) {
    return null;
  }

  return (
    <Track
      name="Kamernet banner clicked"
      cityCanonical={cityCanonical}
      type="link"
    >
      <Link
        to={urlResolver.getKamernetCitySearchURL(cityCanonical.toLowerCase())}
        target="_blank"
        rel="nofollow"
      >
        <Grid
          container
          direction={lg ? 'row' : 'column'}
          justifyContent="space-between"
          alignItems={lg ? 'center' : 'flex-start'}
          className={classes.card}
        >
          <BuildingNature className={classes.buildingIcon} />
          <Grid item>
            <Typography.H2 color="white" className={classes.text}>
              {T('kamernet.banner.see_properties', cityLocalized)}
            </Typography.H2>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              className={classes.button}
              href={urlResolver.getKamernetCitySearchURL(
                cityCanonical.toLowerCase(),
              )}
              target="_blank"
              rel="nofollow"
              data-test-locator="Go To Kamernet Button"
            >
              {T('cta.go_to')}
              <Kamernet className={classes.kamernetLogo} />
              <InterfaceArrowsRight className={classes.arrowIcon} />
            </Button>
          </Grid>
        </Grid>
      </Link>
    </Track>
  );
};

export { KamernetBanner };
