import React from 'react';

import { PromotedBlogData } from '@ha/contentful';

import { useTrackEvent } from 'ha/modules/Analytics/helpers/TrackEvent';
import { useConfig } from 'ha/helpers/config';

import { BannerCardBase } from './BannerCardBase';
import { BANNERS_LIST, SEARCH_IMGIX_PATH } from '../ListingCard/constants';

interface BannerCardPlaceholderProps {
  buttonLabel: string;
  title: PromotedBlogData['title'];
  pathname: PromotedBlogData['pathname'];
  lazyLoadImage?: boolean;
}

export const BannerCardPlaceholder = ({
  buttonLabel,
  pathname,
  title,
  lazyLoadImage,
}: BannerCardPlaceholderProps) => {
  const trackEvent = useTrackEvent();
  const { imgix } = useConfig();

  React.useEffect(() => {
    trackEvent('Content card loaded', {
      title,
      topicCluster: 'placeholder',
    });
  }, [title, trackEvent]);

  const handleClick = React.useCallback(() => {
    trackEvent('Content card clicked', {
      title,
      topicCluster: 'placeholder',
    });
  }, [title, trackEvent]);

  const bannerImage = `${BANNERS_LIST.placeholderCard}.png`;

  const imgSrc = `${imgix.sourceURL}/${SEARCH_IMGIX_PATH}/banners/${bannerImage}`;

  return (
    <BannerCardBase pathname={pathname} onClick={handleClick}>
      <BannerCardBase.Content>
        <BannerCardBase.Title>{title}</BannerCardBase.Title>
        <BannerCardBase.Button>{buttonLabel}</BannerCardBase.Button>
      </BannerCardBase.Content>
      <BannerCardBase.Image imgSrc={imgSrc} isLazy={lazyLoadImage} />
    </BannerCardBase>
  );
};
