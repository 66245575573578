import { SortingValue } from 'ha/types/SearchFilters';

import { handleActions } from 'redux-actions';

import { Actions } from '../constants';

type IndexExperiment = 'indexExperiment';
export interface SortingState {
  currentSorting: SortingValue;
  indexes?: { [key in SortingValue | IndexExperiment]: string };
}

const sortingReducer = handleActions<SortingState, { sorting?: SortingValue }>(
  {
    [Actions.UPDATE_SEARCH_PARAMS]: (state, { payload: { sorting } }) => {
      if (!state.indexes || sorting === state.currentSorting) {
        return state;
      }

      return {
        ...state,
        currentSorting: sorting || SortingValue.BestMatch,
      };
    },
  },
  {
    currentSorting: SortingValue.BestMatch,
  },
);

export { sortingReducer };
