import React from 'react';
import { Waypoint } from 'react-waypoint';
import { useMediaQuery } from 'ha/helpers/MediaQuery';

interface Props {
  render?: (props: { hasEntered: boolean }) => React.ReactNode;
  children?: React.ReactNode;
  placeholder?: React.ReactNode;
  scrollableAncestor?: HTMLElement | string;
}

export const LazyComponent: React.FC<Props & Waypoint.WaypointProps> = ({
  children,
  placeholder = null,
  render,
  ...rest
}) => {
  const [hasEntered, setHasEntered] = React.useState(false);
  const { lg } = useMediaQuery();

  const handleEnter: Waypoint.WaypointProps['onEnter'] = () => {
    setHasEntered(true);
  };

  const renderChildren = React.useCallback(() => {
    if (render) {
      return render({ hasEntered });
    }
    return hasEntered ? children : placeholder;
  }, [hasEntered, children, placeholder, render]);

  return (
    <Waypoint
      {...(lg ? {} : { scrollableAncestor: 'window' })}
      {...rest}
      onEnter={handleEnter}
    >
      {renderChildren()}
    </Waypoint>
  );
};
