import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';
import { Language } from '@ha/intl';
import { useIntl } from 'ha/i18n';
import { checkSearchQueryEmpty } from 'ha/utils/checkSearchQueryEmpty';
import { LocaleToCanonicalCollections } from 'ha/constants/SearchPageCollections';

import {
  getlangURLListState,
  getNumberOfListings,
  getAdvertiserName,
  getCountryCanonical,
  getCachedUpdateSearch,
} from '../selectors';
import { getCollection } from '../selectors/filterSelectors';
import { shouldCountryBeIndexed } from '../utils';

const MinNumOfListingsForIndex = 5;

const getCollectionPath = (key: Language, canonicalCollection?: string) =>
  canonicalCollection
    ? `/${LocaleToCanonicalCollections[key][canonicalCollection]}`
    : '';

const SearchPageCrawl: React.FC = () => {
  const { lang, urlResolver } = useIntl();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');

  const langUrlList: { [key in Language]?: string } =
    useSelector(getlangURLListState);
  const numberOfListings = useSelector(getNumberOfListings);
  const canonicalCollection = useSelector(getCollection);
  const advertiserName = useSelector(getAdvertiserName);
  const canonicalCountryName = useSelector(getCountryCanonical);
  const cachedUpdateSearch = useSelector(getCachedUpdateSearch);

  const isQueryEmpty = checkSearchQueryEmpty(searchParams);
  const localeUrl = langUrlList[lang];
  const translatedPathname = `${localeUrl}${getCollectionPath(
    lang,
    canonicalCollection,
  )}`;
  const translatedPathnameIsDifferent =
    localeUrl &&
    decodeURIComponent(pathname) !== decodeURIComponent(translatedPathname);

  const pageQuery = page && page !== '1' ? `?page=${page}` : '';
  const isCountryWhitelisted = shouldCountryBeIndexed(canonicalCountryName);

  const setNoIndex =
    // index only first page
    pageQuery ||
    numberOfListings < MinNumOfListingsForIndex ||
    !isQueryEmpty ||
    translatedPathnameIsDifferent ||
    advertiserName ||
    !isCountryWhitelisted ||
    cachedUpdateSearch.geonameId === 0;

  return (
    <Helmet>
      {setNoIndex && <meta key="meta robots" name="robots" content="noindex" />}

      {!setNoIndex && (
        <link
          key="link canonical"
          rel="canonical"
          href={urlResolver.absolutizePath(
            `${translatedPathname}${pageQuery}`,
            {
              skipAddLanguage: true,
            },
          )}
        />
      )}

      {langUrlList.en && (
        <link
          key="link alternate x-default"
          rel="alternate"
          hrefLang="x-default"
          href={urlResolver.absolutizePath(
            `${langUrlList.en}${getCollectionPath(
              Language.en,
              canonicalCollection,
            )}${pageQuery}`,
            { skipAddLanguage: true },
          )}
        />
      )}
      {Object.keys(langUrlList).map(
        (key: Language) =>
          langUrlList[key] && (
            <link
              key={`link alternate ${key}`}
              rel="alternate"
              hrefLang={key}
              href={urlResolver.absolutizePath(
                `${langUrlList[key]}${getCollectionPath(
                  key,
                  canonicalCollection,
                )}${pageQuery}`,
                { skipAddLanguage: true },
              )}
            />
          ),
      )}
    </Helmet>
  );
};

export { SearchPageCrawl };
