import React from 'react';

import { useLocation } from 'react-router-dom';

import { ThemeProvider } from '@mui/system';
import { useSelector } from 'react-redux';

import { makeStyles } from 'tss-react/mui';

import { Grid, Paper, theme as legacyTheme, units } from '@hbf/dsl/legacy';

import { SEARCH_PAGE_MAX_WIDTH } from 'ha/constants/search';

import { useMediaQuery } from 'ha/helpers/MediaQuery';
import { useIntl } from 'ha/i18n';
import { useFeatureFlags } from 'ha/modules/FeatureFlags';

import { LoadableSearchMap as SearchMap } from '../../components/SearchMap.lazy';
import { SearchPageCrawl } from '../../components/SearchPageCrawl';
import { SearchCityAbout, SearchCityLinks } from '../../components/SearchSEO';
import { NEW_ABOUT_SEO_CONFIG, sidebarWidth } from '../../constants';
import { MobileMapContainer as MobileMap } from '../../containers/MobileMap';
import { SearchMetaContainer as SearchMeta } from '../../containers/SearchMeta';
import { getCountryCode, getMapViewState } from '../../selectors';
import { MapViewState } from '../../types';

import { SearchResults } from './SearchResults';

const useStyles = makeStyles()(theme => ({
  container: {
    maxWidth: SEARCH_PAGE_MAX_WIDTH,
    margin: 'auto',

    '--x-inline-padding': '20px',
    padding: '0px 20px 0 20px',

    [theme.breakpoints.up('md')]: {
      '--x-inline-padding': '40px',
      padding: '20px 40px 20px 40px',
    },

    [theme.breakpoints.up('lg')]: {
      '--x-inline-padding': '80px',
      padding: '20px 80px 20px 80px',
    },
  },
  containerFullScreen: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    '&&': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  suggestionBar: {
    position: 'sticky',
    width: '100%',
    bottom: 0,
    zIndex: 4,
    fallbacks: {
      position: 'fixed',
    },
  },
  mapContainer: {
    marginLeft: 'calc(var(--x-inline-padding) * -1)',
    width: 'calc(100% + (var(--x-inline-padding) * 2))',
    height: '100%',
  },
  mapPaper: {
    position: 'sticky',
    zIndex: 3,
    overflow: 'hidden',
    boxShadow: 'none',
    boxSizing: 'border-box',
    border: units.border(1, 'solid', theme.palette.grey[300]),
    borderRadius: '4px',
    height: '100%',
  },
  filters: {
    width: units.pxToRem(sidebarWidth),
    marginRight: '1.5rem',
  },
  width0: {
    maxWidth: 0,
    height: 0,
    overflow: 'hidden',
  },
  listingsContainer: {
    position: 'relative',
  },
  quickFilters: {
    padding: units.yx(1, 0),
  },
}));

export const SearchLayout: React.FC = () => {
  const { classes, cx } = useStyles();

  const location = useLocation();
  const { md } = useMediaQuery();
  const { showNewAboutSEO } = useFeatureFlags();
  const { urlResolver } = useIntl();

  const mapViewState = useSelector(getMapViewState);
  const countryCode = useSelector(getCountryCode);
  const isSearchCityPage = urlResolver.isSearchCityPage(location.pathname);

  const showSEOContent =
    showNewAboutSEO === 'on' &&
    isSearchCityPage &&
    NEW_ABOUT_SEO_CONFIG.countryCodes.includes(countryCode.toLowerCase()) &&
    mapViewState !== MapViewState.fullMap;

  return (
    <React.Fragment>
      <div
        className={cx(classes.container, {
          [classes.containerFullScreen]:
            mapViewState === MapViewState.fullMap && md,
        })}
      >
        <Grid
          container
          wrap="nowrap"
          className={cx({
            [classes.mapContainer]: mapViewState === MapViewState.fullMap && md,
          })}
        >
          <SearchMeta />
          <SearchPageCrawl />

          <Grid container item xs>
            {!(md && mapViewState === MapViewState.fullMap) && (
              <Grid
                container
                direction="column"
                wrap="nowrap"
                item
                md={12}
                className={cx(
                  classes.listingsContainer,
                  mapViewState === MapViewState.fullMap && md && classes.width0,
                )}
                data-test-locator="Search Listings Grid"
              >
                <SearchResults />
              </Grid>
            )}

            {!md && <MobileMap />}

            {md && mapViewState !== MapViewState.noMap && (
              <Grid item md={12} data-test-locator="Search Listings Map">
                <Paper className={classes.mapPaper}>
                  <ThemeProvider theme={legacyTheme}>
                    <SearchMap />
                  </ThemeProvider>
                </Paper>
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>

      {showSEOContent && (
        <React.Fragment>
          <SearchCityAbout />
          <SearchCityLinks />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
