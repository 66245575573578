import React from 'react';

import { ChevronDown } from '@hbf/icons/brand-regular';
import { Button, Chip, Dropdown } from '@hbf/dsl/core';
import { createRequiredContext } from 'ha/core/RequiredContext';
import { makeStyles } from 'tss-react/mui';
import { Divider } from '@mui/material';
import { useIntl } from 'ha/i18n';

const useStyles = makeStyles()(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.utils.spacing('ref/spacing/2'),
  },
  content: {
    minWidth: '320px',
    padding: theme.utils.spacing('ref/spacing/5'),
  },
  footerContent: {
    display: 'flex',
    padding: theme.utils.spacing('ref/spacing/5'),
    gap: theme.utils.spacing('ref/spacing/3'),
  },
  divider: {
    borderColor: theme.palette.mono.main,
  },
}));

interface PageHighlightedFilterContextValues {
  onToggle: () => void;
  open: boolean;
}

const PageHighlightedFilterContext =
  createRequiredContext<PageHighlightedFilterContextValues>(
    'PageHighlightedFilterContext',
  );

const PageHighlightedFilterChip = React.forwardRef(
  (
    {
      children,
      highlighted,
    }: React.PropsWithChildren<{ highlighted: boolean }>,
    ref: React.ForwardedRef<React.ComponentRef<typeof Chip>>,
  ) => {
    const { onToggle, open } = PageHighlightedFilterContext.useContext();

    return (
      <Chip
        ref={ref}
        variant="outlined"
        color="neutral"
        size="lg"
        hovered={highlighted}
        active={open}
        endIcon={<ChevronDown />}
        onClick={onToggle}
      >
        {children}
      </Chip>
    );
  },
);

interface PageHighlightedFilterContentProps {
  children: React.ReactNode;
}

const PageHighlightedFilterContent = ({
  children,
}: PageHighlightedFilterContentProps) => {
  const { classes } = useStyles();

  return <div className={classes.content}>{children}</div>;
};

interface PageHighlightedFilterFooterProps {
  onReset: () => void;
}

const PageHighlightedFilterFooter = ({
  onReset,
}: PageHighlightedFilterFooterProps) => {
  const { T } = useIntl();

  const { classes } = useStyles();

  const { onToggle } = PageHighlightedFilterContext.useContext();

  return (
    <div>
      <Divider className={classes.divider} />
      <div className={classes.footerContent}>
        <Button
          variant="outlined"
          color="neutral"
          onClick={() => {
            onReset();
            onToggle();
          }}
        >
          {T('search.page.chip.cta.reset')}
        </Button>
      </div>
    </div>
  );
};

interface PageHighlightedFilterProps {
  children: React.ReactNode;
  slots: { chip: React.ReactElement };
}

export const PageHighlightedFilter = ({
  children,
  slots,
}: PageHighlightedFilterProps) => {
  const firstTime = React.useRef(true);

  const { classes } = useStyles();

  const [open, setOpen] = React.useState(false);

  if (open) {
    firstTime.current = false;
  }

  const value = React.useMemo(
    () => ({
      onToggle: () => setOpen(previous => !previous),
      open,
    }),
    [open],
  );

  return (
    <PageHighlightedFilterContext.Provider value={value}>
      <Dropdown
        variant="rounded"
        elevation="3"
        open={open}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        anchor={slots.chip}
      >
        <div className={classes.wrapper}>
          {(open || !firstTime.current) && children}
        </div>
      </Dropdown>
    </PageHighlightedFilterContext.Provider>
  );
};

PageHighlightedFilter.Chip = PageHighlightedFilterChip;
PageHighlightedFilter.Content = PageHighlightedFilterContent;
PageHighlightedFilter.Footer = PageHighlightedFilterFooter;
