import { ReqParams, CurrencyEuroRates } from './types';

const getFilterParams = (
  reqParams: ReqParams,
  currencyEuroRates: CurrencyEuroRates,
) => {
  const currencyRate = currencyEuroRates[reqParams.currency || 'EUR'];
  const { priceMin, priceMax } = reqParams;

  return {
    ...reqParams,
    priceMax: priceMax && priceMax / currencyRate,
    priceMin: priceMin && priceMin / currencyRate,
  };
};
export { getFilterParams };
