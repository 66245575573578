import { handleActions } from 'redux-actions';

import { FurnitureValue } from 'ha/types/SearchFilters';

import { Actions } from '../constants';

import { FurnitureFilterState } from './types';

const initialState = {
  value: [],
};

interface Payload {
  furniture: FurnitureValue;
}

const furnitureFilter = handleActions<FurnitureFilterState, Payload>(
  {
    [Actions.UPDATE_SEARCH_PARAMS]: (
      state,
      { payload: { furniture = [] } },
    ) => ({
      ...state,
      value: Array.isArray(furniture) ? furniture : [furniture],
    }),
  },
  initialState,
);

export const getFurnitureFilter = (state: FurnitureFilterState) => state.value;
export { furnitureFilter };
