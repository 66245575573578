import React from 'react';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';

import { Chip } from '@hbf/dsl/core';
import { ListAlt, Place } from '@hbf/icons/brand-regular';

import { useIntl } from 'ha/i18n';
import { useTrackEvent } from 'ha/modules/Analytics/helpers/TrackEvent';

import { getCityCanonical } from 'ha/pages/Cities/selectors';
import { toggleMobileMap, updateMapView } from 'ha/pages/Search/actions';
import { getMapViewState } from 'ha/pages/Search/selectors';
import { MapViewState } from 'ha/pages/Search/types';

export const SearchMapChipToggle = () => {
  const { T } = useIntl();

  const currentView = useSelector(getMapViewState);
  const cityCanonical = useSelector(getCityCanonical);

  const dispatch = useDispatch();
  const track = useTrackEvent();

  const theme = useTheme();
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  const handleMapViewChange = () => {
    const next =
      currentView === MapViewState.fullMap
        ? MapViewState.noMap
        : MapViewState.fullMap;

    if (isLargerThanMd) {
      track('Changed map view on search ', {
        mapType: next,
        listingcity: cityCanonical,
      });
      dispatch(updateMapView(next));
    } else {
      dispatch(toggleMobileMap());
    }
  };

  return (
    <Chip
      variant="outlined"
      color="neutral"
      size="lg"
      startIcon={currentView === MapViewState.noMap ? <Place /> : <ListAlt />}
      onClick={handleMapViewChange}
    >
      {currentView === MapViewState.noMap
        ? T('search.page.corner_image.switch_to_map')
        : T('search.page.corner_image.switch_to_list')}
    </Chip>
  );
};
