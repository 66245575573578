import { handleActions } from 'redux-actions';
import { Actions } from '../constants';

const initialState = {
  limit: 10,
  minPopulation: 100000,
  minRooms: 4,
};

const citiesNearbyReducer = handleActions(
  {
    [Actions.LOAD_CITIES_NEARBY_DONE]: (state, { payload: citiesNearby }) => ({
      ...state,
      citiesNearby, // @todo filter current search
    }),
  },
  initialState,
);
export { citiesNearbyReducer };
