import isNil from 'lodash-es/isNil';
import pick from 'lodash-es/pick';
import { handleActions } from 'redux-actions';

import { Actions } from '../constants';

const payloadKeys = [
  'currency',
  'defaultCurrency',
  'priceDistribution',
  'priceMax',
  'priceMin',
  'trimmedPriceDistribution',
  'trimmedPriceMax',
  'trimmedPriceMin',
];

const initialState = {
  currency: '',
  defaultCurrency: '', // initialized using country's currency

  priceDistribution: [],
  priceMax: 0,
  priceMin: 0,

  trimmedPriceDistribution: [],
  trimmedPriceMax: 0,
  trimmedPriceMin: 0,

  value: {
    max: null,
    min: null,
  },
};

const priceFilter = handleActions(
  {
    [Actions.LOAD_DONE]: (state, { payload }) => ({
      ...state,
      ...pick(payload, payloadKeys),
    }),

    [Actions.UPDATE_SEARCH_PARAMS]: (
      state,
      { payload: { priceMax, priceMin, currency } },
    ) => ({
      ...state,
      currency: currency || state.defaultCurrency,
      value: {
        max: isNil(priceMax) ? null : parseInt(priceMax, 10),
        min: isNil(priceMin) ? null : parseInt(priceMin, 10),
      },
    }),
  },
  initialState,
);
export { priceFilter };
