interface DistributionFacet {
  [key: number]: number;
}

export interface DistributionOptions {
  facet: DistributionFacet;
  min: number;
  max: number;
  rate: number;
  steps: number;
}

const getDistribution = ({
  facet,
  min,
  max,
  rate,
  steps,
}: DistributionOptions) => {
  const distribution = {};
  const step = Math.ceil((max - min) / steps);

  const prices = Object.keys(facet)
    .map(Number)
    .filter(price => price * rate >= min && price * rate <= max)
    .sort((a, b) => a - b);

  let j = 0;

  for (let i = 0; i < steps; i += 1) {
    const bar = min + i * step;
    const nextBar = min + (i + 1) * step;

    distribution[bar] = 0;
    while (prices[j] * rate < nextBar && prices[j] * rate >= bar) {
      distribution[bar] += facet[prices[j]];
      j += 1;
    }
  }

  return distribution;
};
export { getDistribution };
