import { reportError } from 'ha/helpers/bugReporter/reportError';
import { getUserId } from 'ha/modules/AuthLogic/selectors';
import { Thunk } from 'ha/myredux/types';

import { getCountryCode, getCityCanonical } from '../selectors';
import { GetActions } from './searchAlertActions';

const getSearchAlert = (): Thunk => (dispatch, getState, services) => {
  const state = getState();

  const userId = getUserId(state);
  const city = getCityCanonical(state);
  const countryCode = getCountryCode(state);

  if (!userId) {
    // If there is no user, do not request search alerts.
    return Promise.resolve();
  }

  dispatch(GetActions.start());

  return services.bell
    .getSearchAlerts({ city, countryCode, status: 'enabled' })
    .then(bellResponse => {
      const searchAlerts = bellResponse ?? [];

      dispatch(
        GetActions.done({
          searchAlert: searchAlerts.length > 0 ? searchAlerts[0] : null,
        }),
      );
    })
    .catch(error => {
      reportError('Failed to get search alerts', { metaData: { error } });
      dispatch(GetActions.error());
    });
};

export { getSearchAlert };
