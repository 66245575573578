import React from 'react';

import { PromotedBlogData, getReadingTime } from '@ha/contentful';

import { useIntl } from 'ha/i18n';
import { TOPIC_CLUSTER_LABEL } from 'ha/modules/Blog/constants';
import { useTrackEvent } from 'ha/modules/Analytics/helpers/TrackEvent';
import { useConfig } from 'ha/helpers/config';

import { BannerCardBase } from './BannerCardBase';
import { BANNERS_LIST, SEARCH_IMGIX_PATH } from '../ListingCard/constants';

interface BannerCardProps extends PromotedBlogData {
  lazyLoadImage?: boolean;
}

export const BannerCard = ({
  content,
  pathname,
  title,
  topicCluster,
  lazyLoadImage,
}: BannerCardProps) => {
  const { T } = useIntl();
  const { imgix } = useConfig();

  const trackEvent = useTrackEvent();

  React.useEffect(() => {
    trackEvent('Content card loaded', {
      title,
      topicCluster,
    });
  }, [title, topicCluster, trackEvent]);

  const handleClick = React.useCallback(() => {
    trackEvent('Content card clicked', {
      title,
      topicCluster,
    });
  }, [title, topicCluster, trackEvent]);

  const buttonText = React.useMemo(() => {
    const time = getReadingTime(false, [content]);

    return T('%s min read', time.timeLength);
  }, [T, content]);

  const bannerImage = `${
    (topicCluster && BANNERS_LIST[topicCluster]) ?? BANNERS_LIST.default
  }.png`;

  const imgSrc = `${imgix.sourceURL}/${SEARCH_IMGIX_PATH}/banners/${bannerImage}`;

  return (
    <BannerCardBase pathname={pathname} onClick={handleClick}>
      <BannerCardBase.Content>
        {topicCluster && (
          <BannerCardBase.HighlightedText>
            {TOPIC_CLUSTER_LABEL[topicCluster]}
          </BannerCardBase.HighlightedText>
        )}
        <BannerCardBase.Title>{title}</BannerCardBase.Title>
        <BannerCardBase.Button>{buttonText}</BannerCardBase.Button>
      </BannerCardBase.Content>
      <BannerCardBase.Image imgSrc={imgSrc} isLazy={lazyLoadImage} />
    </BannerCardBase>
  );
};
