import { createSelector } from 'reselect';

import { AvailabilityFilterValue, dateRangeToAvailability } from '@ha/date';

import { getFeatureFlags } from 'ha/modules/FeatureFlags';
import {
  DateFilterValue,
  TypeFilterValue,
  SearchAreaFilterValue,
  SearchAreaValue,
  AdvertiserRatingFilterValue,
} from 'ha/types/SearchFilters';
import { GlobalState } from 'ha/types/store';
import { getFromStorage } from 'ha/utils/storage';

import { getUseMapAsAFilter, isInitialState, getBounds } from './mapSelectors';

export const getDateFilter = (state: GlobalState) => state.search.dateFilter;

export const getIsSearchAreaFilterHidden = createSelector(
  [isInitialState, getBounds, getFeatureFlags],
  (initialState, bounds, featureFlag) =>
    featureFlag.searchLocationFilters !== 'on' &&
    (!bounds || (bounds && initialState)),
);

export const getIsWithRelevantAvailabilityEnabled = createSelector(
  getDateFilter,
  dateFilter => dateFilter?.withRelevantAvailability,
);

export const getAvailability = createSelector(
  getDateFilter,
  (dates): AvailabilityFilterValue | undefined => {
    if (!dates?.startDate) {
      return;
    }

    const { startDate, endDate } = dates;

    const availability = dateRangeToAvailability({ startDate, endDate });
    if (!availability) return;

    /*
    NOTE:
      It is not s good practice to use localStorage here, we need to refactor the whole selector to be in the flexible search module and here we should have it referenced.
      The initial values should be injected to the module and we should not use localStorage directly, in order to do so we need to add another layer for initialization so it
      effects the rest of the application, to avoid that we will keep it like the following so if we decided keeping the feature we will create the suitable architecture.
      otherwise it is easier to remove this way.
    */
    availability.moveIn.type =
      (getFromStorage('selectedDateType') as 'date' | 'month') ||
      availability.moveIn.type;

    return availability;
  },
);

export const getDateFilterValue = createSelector(
  getDateFilter,
  (dateFilter): DateFilterValue => ({
    startDate: dateFilter.startDate || null,
    endDate: dateFilter.endDate || null,
    flexDays: dateFilter.flexDays,
  }),
);

export const getCollection = (state: GlobalState) =>
  state.search.collectionFilter.collection;

export const getTypeFilterValue = (state: GlobalState): TypeFilterValue =>
  state.search.collectionFilter.categories;

export const getAdvertiserRatingFilterValue = (
  state: GlobalState,
): AdvertiserRatingFilterValue => state.search.advertiserRatingFilter.value;

export const getFurnitureFilter = (state: GlobalState) =>
  state.search.furnitureFilter.value;

export const getBillsFilter = (state: GlobalState) =>
  state.search.billsFilter.value;

const getRoomsFilterBedroomCount = (state: GlobalState) =>
  state.search.roomsFilter.bedroomCount;
export const getRoomsFilterValue = createSelector(
  getRoomsFilterBedroomCount,
  bedroomCount => ({
    bedroomCount,
  }),
);

export const getRulesFilter = (state: GlobalState) =>
  state.search.rulesFilter.value;

export const getFacilitiesFilter = (state: GlobalState) =>
  state.search.filters.facilities.value;

export const getAmenitiesFilter = (state: GlobalState) =>
  state.search.filters.amenities.value;

export const getPriceFilterValue = (state: GlobalState) =>
  state.search.priceFilter.value;

export const getCurrencyFilterValue = (state: GlobalState) =>
  state.search.priceFilter.currency;

export const getRegistrationFilter = (state: GlobalState) =>
  state.search.registrationFilter.value;

export const getRecentlyAddedFilter = (state: GlobalState) =>
  state.search.recentlyAddedFilter;

export const getGenderFilter = (state: GlobalState) =>
  state.search.genderFilter.value;

export const getSuitableForFilter = (state: GlobalState) =>
  state.search.suitableForFilter.value;

export const getContractTypeFilter = (state: GlobalState) =>
  state.search.contractTypeFilter.value;

export const getSearchAreaFilterValue = createSelector(
  getUseMapAsAFilter,
  (useMapAsAFilter): SearchAreaFilterValue => [
    useMapAsAFilter ? SearchAreaValue.MapBounds : SearchAreaValue.CityBounds,
  ],
);
export const getPropertySizeFilter = (state: GlobalState) =>
  state.search.filters.propertySize.value;
export const getPropertySizeStats = (state: GlobalState) =>
  state.search.filters.propertySize.stats;

export const getRadiusFilter = (state: GlobalState) =>
  state.search.filters.radius.value;

export const getSortingFilter = (state: GlobalState) =>
  state.search.sorting.currentSorting;

// typings for createSelector only allow a limited number of selectors
const getFilterValuesTemp = createSelector(
  [
    getDateFilterValue,
    getTypeFilterValue,
    getAdvertiserRatingFilterValue,
    getFurnitureFilter,
    getBillsFilter,
    getRoomsFilterValue,
    getRulesFilter,
    getPriceFilterValue,
    getRegistrationFilter,
    getRecentlyAddedFilter,
    getGenderFilter,
    getSuitableForFilter,
  ],
  (
    dates,
    types,
    advRating,
    furniture,
    bills,
    rooms,
    rules,
    price,
    registration,
    recentlyAdded,
    gender,
    suitableFor,
    // eslint-disable-next-line max-params
  ) => ({
    dates,
    types,
    advRating,
    furniture,
    bills,
    rooms,
    rules,
    price,
    registration,
    recentlyAdded,
    gender,
    suitableFor,
  }),
);

export const getFilterValues = createSelector(
  [
    getFilterValuesTemp,
    getContractTypeFilter,
    getSearchAreaFilterValue,
    getPropertySizeFilter,
    getFacilitiesFilter,
    getAmenitiesFilter,
    getRadiusFilter,
    getCurrencyFilterValue,
  ],
  (
    filterValues,
    contractType,
    searchArea,
    propertySize,
    facilities,
    amenities,
    radius,
    currency,
    // eslint-disable-next-line max-params
  ) => ({
    ...filterValues,
    contractType,
    searchArea,
    propertySize,
    facilities,
    amenities,
    radius,
    currency,
  }),
);
