import { handleActions } from '@housinganywhere/safe-redux';
import { SearchAlert } from 'ha/services/bell';

import {
  GetActionsType,
  GetActionTypes,
  UpsertActionsType,
  UpsertActionTypes,
} from '../actions/searchAlertActions';

export interface State {
  loading: boolean;
  error: boolean;
  searchAlert: SearchAlert | null;
}

const initialState: State = {
  loading: false,
  error: false,
  searchAlert: null,
};

const searchAlertReducer = handleActions<
  State,
  GetActionTypes | UpsertActionTypes,
  GetActionsType | UpsertActionsType
>(
  {
    [UpsertActionTypes.START]: state => ({
      ...state,
      loading: true,
      error: false,
    }),
    [UpsertActionTypes.DONE]: (state, { payload: { searchAlert } }) => ({
      ...state,
      loading: false,
      error: false,

      searchAlert,
    }),
    [UpsertActionTypes.ERROR]: state => ({
      ...state,
      loading: false,
      error: true,
    }),

    // Fetch user's search alert for current city / country
    [GetActionTypes.START]: state => ({
      ...state,
      error: false,
      loading: true,
    }),
    [GetActionTypes.DONE]: (state, { payload: { searchAlert } }) => ({
      ...state,
      loading: false,

      searchAlert,
    }),
    [GetActionTypes.ERROR]: state => ({
      ...state,
      loading: false,
      error: true,
    }),
  },
  initialState,
);
export { searchAlertReducer };
