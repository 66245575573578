import { handleActions } from 'redux-actions';

import { RulesValue } from 'ha/types/SearchFilters';

import { RulesFilterState } from './types';
import { Actions } from '../constants';

const initialState = {
  value: [],
};

interface Payload {
  rules: RulesValue;
}

const rulesFilter = handleActions<RulesFilterState, Payload>(
  {
    [Actions.UPDATE_SEARCH_PARAMS]: (state, { payload: { rules = [] } }) => ({
      ...state,
      value: Array.isArray(rules) ? rules : [rules],
    }),
  },
  initialState,
);

export const getRulesFilter = (state: RulesFilterState) => state.value;
export { rulesFilter };
