import React from 'react';

import { Grid2, TextField, Typography } from '@hbf/dsl/legacy';
import { useIntl } from 'ha/i18n';

import { CountrySelect as CountryCodeSelector } from './CountryCodeSelector';
import { PhoneNumber, Country } from './types';

export interface Props {
  value?: PhoneNumber;
  id: string;
  className?: string;
  onChange?: (phoneNumber: PhoneNumber) => void;
  onFocus?: (phoneNumber: PhoneNumber) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  inputLabel?: string;
  showCountryCode?: boolean;
  selectPlaceholder?: string;
  countries: Country[];
  error?: string;
  fullWidth?: boolean;
  sameSizeFields?: boolean;
  appearance?: 'shrinkablePlaceholder' | 'helperTextWithPlaceholder';
}

const PhoneInput: React.FC<Props> = ({
  value = { code: '', phone: '' },
  id,
  inputLabel = '',
  className = '',
  disabled = false,
  onChange,
  onFocus,
  onBlur,
  countries,
  selectPlaceholder = '',
  fullWidth = false,
  sameSizeFields = false,
  appearance = 'shrinkablePlaceholder',
}) => {
  const { T } = useIntl();

  const handlePhoneChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange({
          code: value.code,
          phone: String(event.target.value.replace(/\D/g, '')),
        });
      }
    },
    [value],
  );

  const handleCodeChange = React.useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, values: Country) => {
      if (onChange) {
        onChange({
          code: values ? values.code : '',
          phone: value.phone,
        });
      }
    },
    [value],
  );

  const handleFocus = React.useCallback(() => {
    if (onFocus) {
      onFocus({
        code: value.code,
        phone: value.phone,
      });
    }
  }, [value]);

  return (
    <Grid2 container spacing={2} alignItems="flex-end">
      <Grid2 xs={6} sm={sameSizeFields ? 6 : 4}>
        <React.Fragment>
          {appearance === 'helperTextWithPlaceholder' && (
            <Typography.HelperText color="muted">
              {T('Country code')}
            </Typography.HelperText>
          )}
          <CountryCodeSelector
            countryCode={value.code}
            onCodeChange={handleCodeChange}
            countries={countries}
            selectPlaceholder={
              appearance === 'helperTextWithPlaceholder'
                ? ''
                : selectPlaceholder
            }
            inputProps={{ 'data-test-locator': 'Phone Input Prefix' }}
            placeholder={
              appearance === 'helperTextWithPlaceholder' ? 'NL +00' : undefined
            }
          />
        </React.Fragment>
      </Grid2>
      <Grid2 xs={6} sm={sameSizeFields ? 6 : 8}>
        <React.Fragment>
          {appearance === 'helperTextWithPlaceholder' && (
            <Typography.HelperText color="muted">
              {T('mobile_number.label')}
            </Typography.HelperText>
          )}
          <TextField
            type="tel"
            inputProps={{
              onBlur,
              'data-test-locator': 'Phone Number Input',
            }}
            autoComplete="tel"
            id={`${id}-phone`}
            label={
              appearance === 'helperTextWithPlaceholder'
                ? undefined
                : inputLabel
            }
            className={className}
            onChange={handlePhoneChange}
            onFocus={handleFocus}
            value={value.phone}
            fullWidth={fullWidth}
            disabled={disabled}
          />
        </React.Fragment>
      </Grid2>
    </Grid2>
  );
};

export { PhoneInput };
