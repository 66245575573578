import React from 'react';

import { useSelector } from 'react-redux';

import { makeStyles } from 'tss-react/mui';

import { Typography, Container, Grid, units } from '@hbf/dsl/legacy';

import { useMediaQuery } from 'ha/helpers/MediaQuery';
import { useIntl } from 'ha/i18n';

import { sidebarWidth } from '../../constants';
import { getCityCanonical, getNumberOfListings } from '../../selectors';

export const useStyles = makeStyles()(theme => ({
  container: {
    paddingTop: units.pxToRem(21),
    paddingBottom: units.pxToRem(21),
    backgroundColor: theme.palette.mono.main,
    marginBottom: units.pxToRem(32),
    marginTop: units.pxToRem(15),
  },
  sidePadding: {
    width: units.pxToRem(sidebarWidth),
    marginRight: units.rem(1.5),
  },
  title: {
    fontSize: units.rem(2),
    paddingBottom: units.rem(0.75),
  },
  secondParagraph: {
    marginTop: units.pxToRem(21),
    paddingRight: units.rem(15),
    [theme.breakpoints.down('md')]: {
      paddingRight: 0,
    },
  },
  firstParagraph: {
    paddingRight: units.rem(15),
    [theme.breakpoints.down('md')]: {
      paddingRight: 0,
    },
  },
}));

export const SearchCityAbout: React.FC = () => {
  const city = useSelector(getCityCanonical);
  const total = useSelector(getNumberOfListings);
  const { md } = useMediaQuery();

  const { classes } = useStyles();
  const { T } = useIntl();

  return (
    <div className={classes.container}>
      <Container>
        <Grid container wrap="nowrap">
          {md && <div className={classes.sidePadding} />}

          <Grid container item xs>
            <Typography.H2 className={classes.title}>
              {T('seo.city_name', city)}
            </Typography.H2>
            <Typography.Paragraph className={classes.firstParagraph}>
              {T('seo.intro.paragraph1', city, city, city)}
            </Typography.Paragraph>
            <Typography.Paragraph className={classes.secondParagraph}>
              {T('seo.intro.paragraph2', total, city, city, city, city)}
            </Typography.Paragraph>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
