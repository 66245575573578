import { parse } from 'query-string';

// transform searchParams to the object closely resembling query from react-router-v3
// for backwards-compatibility
export const searchParamsToObject = (searchParams: URLSearchParams) => {
  return parse(searchParams.toString(), {
    arrayFormat: 'comma',
    parseBooleans: true,
    parseNumbers: true,
  });
};
